import React from "react";
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import "./OneStepBack.css"

class OneStepBack extends React.Component {

    constructor(props) {
        super(props);
        this._back = this._back.bind(this);
    }

    _back = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <Grid container direction="row" alignItems="center">
                <Grid item xs={1}>
                    <Button id="backOneStepBack" disabled={this.props.disabled} variant="text" onClick={this._back}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={11} style={{ textAlign: "center" }}>
                    {this.props.pageTitle}
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(OneStepBack) 