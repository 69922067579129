import React from "react";
import "./CourseMasterAttributes.css";
import Button from "@material-ui/core/Button";
import { Clear as ClearIcon } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";

import API from "./../../Components/api";
import CourseMasterCLOWeeks from "./CourseMasterCLOWeeks";
import CourseMasterCloGai from "./CourseMasterCloGai";
import Report from "./../../BCITReports/Report";
import WindowPortal from "./../../WindowPortal";
import GAIOccurances from "./../../GAIOccurances";
import CourseMasterStatus from "./CourseMasterStatus";
import "./../../Libellum.css";
import HelpIcon from "./../../../img/help.png";
import InfoDialog from "../../InfoDialog";
import {
    capitalizeFirstLetter,
    validateDecimal100Max,
    deepCloneObject,
    cancelHttpRequests,
} from "./../../Helpers/HelperFunctions";

//TODO: refactor and break this up
class CourseMasterAttributes extends React.Component {
    cancelToken = API.getCancelactionToken();
    source = this.cancelToken.source();

    constructor(props) {
        super(props);

        this.updateQueue = [];

        this.state = {
            courseDetails: this.props.courseMasterDetail,
            CLOGaiDataCaptures: [],
            showReport: false,
            showOtherHourDialog: false,
        };

        this.closeWindowPortal = this.closeWindowPortal.bind(this);
        this._showReport = this._showReport.bind(this);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", () => {
            this.closeWindowPortal();
        });
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    _showReport() {
        this.setState({ showReport: false }, function () {
            this.setState(
                {
                    showReport: true,
                },
                function () {
                    this.setState(this.state);
                }
            );
        });
    }

    _showOtherHourDialog = (show) => {
        this.setState({ showOtherHourDialog: show });
    };

    closeWindowPortal() {
        this.setState({ showReport: false });
    }

    _adminEditOnClick = (propName) => {
        let courseDetails = { ...this.state.courseDetails };
        courseDetails[propName + "edit"] = true;
        this.setState({ courseDetails });
    };

    _clearAdminEdit = (propName) => {
        this.updateQueue.push({
            coursePlanId: this.state.courseDetails.Id,
            courseCatalogueId: this.state.courseDetails.CourseCatalogueId,
            newValue: null,
            oldValue: null,
            paramName: propName,
        });

        let courseDetails = { ...this.state.courseDetails };
        courseDetails[propName + "edit"] = false;
        this.setState({ courseDetails });

        this._processChangeQueue();
    };

    _updateAUDistribution = (id, e) => {
        validateDecimal100Max(e);
        let dataToWorkWith = deepCloneObject(this.state.courseDetails);
        dataToWorkWith.Components[id].AcademicUnitPercentage = e.target.value;
        this.setState({
            courseDetails: dataToWorkWith,
        });
    };

    _addToChangeQueueCoursePlan = (e, fullObj, paramName) => {
        // get values
        var newVal = parseFloat(e.target.value);
        var oldVal = parseFloat(fullObj[paramName]);

        // if they haven't changed, don't do anything
        if (newVal === oldVal) return;

        this.updateQueue.push({
            coursePlanId: fullObj.Id,
            courseCatalogueId: fullObj.CourseCatalogueId,
            newValue: newVal,
            oldValue: oldVal,
            paramName: paramName,
        });

        this._processChangeQueue();
    };

    _addToChangeQueue = (e, fullObj, paramName) => {
        // get values
        var newVal = parseFloat(e.target.value);
        var oldVal = parseFloat(fullObj.AcademicUnitPercentage);

        this.updateQueue.push({
            curriculumComponentId: fullObj.CurriculumComponentId,
            newValue: newVal,
            oldValue: oldVal,
            paramName: paramName,
        });

        this._processChangeQueue();
    };

    _getCourseDetails = () => {
        var that = this;
        API.get(`CoursePlan/GetById(coursePlanId=${this.props.courseMasterDetail.Id})`,
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                let courseDetails = res.data.value[0];
                that.setState({
                    courseDetails: courseDetails,
                });
                that.props.onStateUpdate(
                    this.props.courseMasterDetail.Id,
                    courseDetails.RequiresSetup,
                    courseDetails.IsAdminVerified
                );
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    };

    _updateStateCoursePlan = (attrName, newValue) => {
        let dataToWorkWith = deepCloneObject(this.state.courseDetails);
        dataToWorkWith[attrName] = newValue;

        for (var idx in dataToWorkWith.Components) {
            const totalAccredUnits = dataToWorkWith.AccreditationUnits;
            const totalDiscount = (100 - newValue) / 100;
            const percentWeight =
                dataToWorkWith.Components[idx].AcademicUnitPercentage / 100;

            dataToWorkWith.Components[idx].AcademicUnitValue = parseFloat(
                totalAccredUnits * percentWeight * totalDiscount
            ).toFixed(2);
        }

        this.setState((prevState) => ({
            courseDetails: dataToWorkWith,
        }));
    };

    _updateState = (curriculumComponentId, newValue) => {
        for (let idx in this.state.courseDetails.Components) {
            // find the id that we are working with
            if (
                this.state.courseDetails.Components[idx].CurriculumComponentId ===
                curriculumComponentId
            ) {
                // make a new object
                let newSourceData = Object.assign(
                    {},
                    this.state.courseDetails.Components
                );
                // update
                newSourceData[idx].academicUnitPercentage = newValue;
                var x = this.state.courseDetails.AccreditationUnits;
                var y = (100 - this.state.courseDetails.AUDiscount) / 100;
                newSourceData[idx].AcademicUnitValue = (
                    x *
                    (newValue / 100) *
                    y
                ).toFixed(2);
                // update state
                this.setState((prevState) => ({
                    ...prevState.courseDetails.Components,
                    [this.state.courseDetails.Components]: newSourceData,
                }));

                break;
            }
        }
    };

    _updateCurriculumComponents = (objToProcess) => {
        const coursePlanId = this.state.courseDetails.Id;
        API.patch(`CoursePlanCurriculumComponent(CoursePlanId=${coursePlanId},CurriculumComponentId=${objToProcess.curriculumComponentId})`,
            {
                [objToProcess.paramName]: objToProcess.newValue,
            },
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    this._updateState(
                        objToProcess.curriculumComponentId,
                        objToProcess.newValue
                    );
                } else {
                    alert("Unable to update AU Distribution.");

                    let courseDetails = { ...this.state.courseDetails };
                    courseDetails.Components.find(
                        (x) =>
                            x.CurriculumComponentId === objToProcess.curriculumComponentId
                    ).AcademicUnitPercentage = objToProcess.oldValue;
                    this.setState({ courseDetails });
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;

                alert("Error: Unable to update AU Distribution.");
                let courseDetails = { ...this.state.courseDetails };
                courseDetails.Components.find(
                    (x) => x.CurriculumComponentId === objToProcess.curriculumComponentId
                ).AcademicUnitPercentage = objToProcess.oldValue;
                this.setState({ courseDetails });
            })
            .finally(() => {
                this.updateQueue.shift();
            });
    };

    _updateCoursePlan = (paramNameToUpdate, objToProcess) => {
        API.patch(`CoursePlan(${objToProcess.coursePlanId})`,
            {
                [paramNameToUpdate]: objToProcess.newValue,
            },
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    let courseDetails = { ...this.state.courseDetails };
                    courseDetails[objToProcess.paramName] = objToProcess.newValue;
                    this.setState({ courseDetails }, () => {
                        this._getCourseDetails();
                    });
                } else {
                    alert("Unable to update Course Outline.");
                    let courseDetails = { ...this.state.courseDetails };
                    courseDetails[objToProcess.paramName] = objToProcess.oldValue;
                    this.setState({ courseDetails });
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;

                alert("Unable to update Course Outline.");
                let courseDetails = { ...this.state.courseDetails };
                courseDetails[objToProcess.paramName] = objToProcess.oldValue;
                this.setState({ courseDetails });
            })
            .finally(() => {
                this.updateQueue.shift();
            });
    };

    _updateAdminWeeks = (paramNameToUpdate, objToProcess) => {
        API.patch(`CourseCatalogue(${objToProcess.courseCatalogueId})`,
            {
                [paramNameToUpdate]: objToProcess.newValue,
            },
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    let courseDetails = { ...this.state.courseDetails };
                    courseDetails[objToProcess.paramName] = objToProcess.newValue;
                    this.setState({ courseDetails }, () => {
                        this._getCourseDetails();
                    });
                } else {
                    alert("Unable to update Course Outline.");
                    let courseDetails = { ...this.state.courseDetails };
                    courseDetails[objToProcess.paramName] = objToProcess.oldValue;
                    this.setState({ courseDetails });
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;

                alert("Unable to update Course Outline.");
                let courseDetails = { ...this.state.courseDetails };
                courseDetails[objToProcess.paramName] = objToProcess.oldValue;
                this.setState({ courseDetails });
            })
            .finally(() => {
                this.updateQueue.shift();
            });
    };

    _processChangeQueue = () => {
        if (this.updateQueue.length === 0) return;

        var objToProcess = this.updateQueue[0];
        if (objToProcess.curriculumComponentId) {
            this._updateCurriculumComponents(objToProcess);
        } else if (objToProcess.coursePlanId) {
            let paramNameToUpdate = capitalizeFirstLetter(objToProcess.paramName);
            if (objToProcess.paramName == "CourseDurationWeeksAdmin") {
                this._updateAdminWeeks(paramNameToUpdate, objToProcess);
            } else {
                this._updateCoursePlan(paramNameToUpdate, objToProcess);
            }
        }
    };

    _renderPercentages() {
        var toReturn = [];

        this.state.courseDetails.Components.map((obj, idx) => {
            toReturn.push(
                <div
                    key={"prc" + idx}
                    className="col powder-blue no-padding no-margin center thick-border smallVerticalSpacing span-to-center"
                >
                    <TextField
                        className="no-padding-input"
                        style={{ width: "50px", margin: "0" }}
                        defaultValue={obj.AcademicUnitPercentage}
                        margin="normal"
                        onBlur={(e) => {
                            this._addToChangeQueue(e, obj, "AcademicUnitPercentage");
                        }}
                        onChange={(e) => {
                            validateDecimal100Max(e);
                        }}
                        type="number"
                        inputProps={{ min: "0", max: "100", step: "1" }}
                    />
                </div>
            );
        });

        return toReturn;
    }

    _renderValues() {
        var toReturn = [];

        this.state.courseDetails.Components.map((obj, idx) => {
            toReturn.push(
                <div
                    key={"vls" + idx}
                    className="col powder-blue no-padding no-margin center smallVerticalSpacing"
                >
                    {obj.AcademicUnitValue}
                </div>
            );
        });

        return toReturn;
    }

    _renderAdminEdit(value, propName) {
        if (value != null || this.state.courseDetails[propName + "edit"]) {
            return (
                <div className="inlineBlock span-to-center">
                    <TextField
                        className="no-padding-input"
                        style={{
                            width: "50px",
                            margin: "0",
                            bottom: "4px",
                            paddingRight: "5px",
                        }}
                        defaultValue={value}
                        margin="normal"
                        onBlur={(e) => {
                            this._addToChangeQueueCoursePlan(
                                e,
                                this.state.courseDetails,
                                propName
                            );
                        }}
                        type="number"
                        inputProps={{ min: "0", max: "100", step: "1" }}
                    />
                    <div className="inlineBlock">
                        <ClearIcon
                            className="actionIcon"
                            style={{ width: "20px", height: "20px", color: "red" }}
                            onClick={() => this._clearAdminEdit(propName)}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <label
                    className="edit-blue poppinFont font12 normalFontWeight"
                    onClick={() => this._adminEditOnClick(propName)}
                >
                    Edit
                </label>
            );
        }
    }

    _renderCourseAttributes = () => {
        var otherDialog = "";

        if (this.state.showOtherHourDialog)
            otherDialog = (
                <InfoDialog
                    show={true}
                    content={"AU's are calcuated at 0.5 per Other Hour"}
                    title="Other Hours"
                    onDialogClose={() => {
                        this._showOtherHourDialog(false);
                    }}
                />
            );

        return (
            <>
                <div className="row no-margin no-padding">
                    <div className="col-12 bold font23 riverBed poppinFont inlineBlock no-margin no-padding">
                        <span className="inlineBlock">
                            Course Outline
                            <hr className="hrTitle" style={{ width: "75%", marginTop: 15 }} />
                        </span>
                    </div>
                </div>
                <div className="row no-margin no-padding">
                    <div className="col-4 bold font14 riverBed poppinFont courseOutlinePadding">
                        Course Attributes
                    </div>
                    <div className="col-4 bold font14 riverBed courseOutlinePadding center">
                        Registrar Data
                    </div>
                    <div className="col-4 bold font14 riverBed courseOutlinePadding center">
                        Admin Changes
                    </div>
                </div>
                <hr className="hrTable" />
                <div className="row no-margin no-padding">
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding ">
                        Weeks Taught:
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this.state.courseDetails.CourseDurationWeeks}
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this._renderAdminEdit(
                            this.state.courseDetails.CourseDurationWeeksAdmin,
                            "CourseDurationWeeksAdmin"
                        )}
                    </div>
                </div>
                <hr className="hrTable" />
                <div className="row no-margin no-padding">
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding">
                        Lecture (hrs/week):
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this.state.courseDetails.LectureHours}
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this._renderAdminEdit(
                            this.state.courseDetails.LectureHoursAdmin,
                            "LectureHoursAdmin"
                        )}
                    </div>
                </div>
                <hr className="hrTable" />
                <div className="row no-margin no-padding">
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding">
                        Labs (hrs/week):
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this.state.courseDetails.LabHours}
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this._renderAdminEdit(
                            this.state.courseDetails.LabHoursAdmin,
                            "LabHoursAdmin"
                        )}
                    </div>
                </div>
                <hr className="hrTable" />
                <div className="row no-margin no-padding">
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding">
                        Tutorial (hrs/week):
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this.state.courseDetails.TutorialHours}
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this._renderAdminEdit(
                            this.state.courseDetails.TutorialHoursAdmin,
                            "TutorialHoursAdmin"
                        )}
                    </div>
                </div>
                <hr className="hrTable" />
                <div className="row no-margin no-padding">
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding">
                        Others (hrs/week):
                        <img
                            src={HelpIcon}
                            alt=""
                            style={{ height: "20px", marginTop: "-20px" }}
                            onClick={() => {
                                this._showOtherHourDialog(true);
                            }}
                        ></img>
                        {otherDialog}
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this.state.courseDetails.OtherHours}
                    </div>
                    <div className="col-4 font14 riverBed poppinFont courseOutlinePadding center">
                        {this._renderAdminEdit(
                            this.state.courseDetails.OtherHoursAdmin,
                            "OtherHoursAdmin"
                        )}
                    </div>
                </div>
                <hr className="hrTable" />
                <div className="row no-margin no-padding">
                    <div className="col-12 center courseOutlinePadding">
                        <span className="font16 riverBed poppinFont">Calculated AUs: </span>
                        {this.state.courseDetails && (
                            <span className="font16 riverBed poppinFont bold">
                                {this.state.courseDetails.AccreditationUnits}
                            </span>
                        )}
                    </div>
                </div>
            </>
        );
    };

    _renderAuDistribution = () => {
        let subjects = [
            "Math",
            "Nat. Sci.",
            "Compl. Studies",
            "Eng. Sci",
            "Eng. Design",
        ];
        let auVAlues = [];
        let auPercentSum = this.state.courseDetails.Components.reduce(
            (previous, current) =>
                previous + parseInt(current.AcademicUnitPercentage),
            0
        );

        this.state.courseDetails.Components.map((obj, idx) => {
            auVAlues.push(
                <div key={"subjects" + idx}>
                    <div className="row no-margin no-padding">
                        <div className="col-4 courseOutlinePadding">{subjects[idx]}</div>
                        <div
                            key={"prc" + idx}
                            className="col no-padding no-margin center span-to-center courseOutlinePadding"
                        >
                            <TextField
                                className="no-padding-input"
                                style={{ width: "50px", margin: "0" }}
                                defaultValue={obj.AcademicUnitPercentage}
                                margin="normal"
                                onBlur={(e) => {
                                    this._addToChangeQueue(e, obj, "AcademicUnitPercentage");
                                }}
                                onChange={(e) => {
                                    this._updateAUDistribution(idx, e);
                                }}
                                type="number"
                                inputProps={{ min: "0", max: "100", step: "1" }}
                            />
                        </div>
                        <div
                            key={"vls" + idx}
                            className="col no-padding no-margin center span-to-center courseOutlinePadding"
                        >
                            {(
                                (this.state.courseDetails.AccreditationUnits *
                                    obj.AcademicUnitPercentage) /
                                100
                            ).toFixed(2)}
                        </div>
                    </div>
                    <hr className="hrTable" />
                </div>
            );
        });

        return (
            <>
                <div className=" row no-margin no-padding">
                    <div className="col-12 bold font23 riverBed poppinFont inlineBlock row no-margin no-padding">
                        <span className="inlineBlock">
                            AU Distribution
                            <hr className="hrTitle" style={{ width: "75%", marginTop: 15 }} />
                        </span>
                    </div>
                </div>
                <div className="row no-margin no-padding">
                    <div className="col-4 bold font14 riverBed poppinFont courseOutlinePadding">
                        Subjects
                    </div>
                    <div className="col-4 bold font14 riverBed poppinFont center courseOutlinePadding">
                        Set AU Distribution (%)
                    </div>
                    <div className="col-4 bold bold font14 riverBed poppinFont center courseOutlinePadding">
                        Calculated AU
                    </div>
                </div>
                <hr className="hrTable" />
                {auVAlues}
                {auPercentSum > 100 ? (
                    <div className="ehs-error">
                        AU Percent distribution should be less than or equal to 100%
                    </div>
                ) : (
                    ""
                )}
            </>
        );
    };

    _renderTableData = () => {
        let tblData;
        if (
            !this.state.courseDetails.Components ||
            this.state.courseDetails.Components.length === 0
        ) {
            tblData = (
                <div>No Course Components have been assigned to this Course Plan.</div>
            );
        } else {
            tblData = <React.Fragment>{this._renderAuDistribution()}</React.Fragment>;
        }

        return tblData;
    };

    render() {
        let tblData = this._renderTableData();

        return (
            <div className="full-width">
                <div className="row no-padding no-margin">
                    {this.state.courseDetails && (
                        <CourseMasterStatus
                            coursePlan={this.state.courseDetails}
                            coursePlanUpdated={this._getCourseDetails}
                        />
                    )}
                </div>
                <div className="row no-padding no-margin">
                    <div className="col-12 col-lg-5">
                        {this._renderCourseAttributes()}
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-2">{tblData}</div>
                </div>
                <div className="row no-padding no-margin margin-top-50">
                    <div className="col-12 no-padding">
                        <div className="row no-padding no-margin">
                            <CourseMasterCLOWeeks
                                coursePlanId={this.props.courseMasterDetail.Id}
                                clo={this.state.courseDetails.CourseLearningOutcomes}
                                courseWeeks={
                                    this.state.courseDetails.CourseDurationWeeksAdmin != null
                                        ? this.state.courseDetails.CourseDurationWeeksAdmin
                                        : this.state.courseDetails.CourseDurationWeeks
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row no-padding no-margin margin-top-50">
                    <div className="col-12" style={{ paddingBottom: 25 }}>
                        <div className="row no-padding no-margin">
                            <div className="col-12 bold font23 riverBed poppinFont inlineBlock no-margin no-padding margin-top-15">
                                <span className="inlineBlock">
                                    GAI Occurrences
                                    <hr
                                        className="hrTitle"
                                        style={{ width: "75%", marginTop: 15 }}
                                    />
                                </span>
                            </div>
                            <div className="col-12 col-lg-3" style={{ minWidth: 250 }}>
                                <Button
                                    className="embers whiteText top-right-button margin-right-10"
                                    onClick={this._showReport}
                                    style={{ maxHeight: 42 }}
                                    size="small"
                                >
                                    View Course GA - Weeks
                                </Button>
                                {this.state.showReport && (
                                    <WindowPortal closeWindowPortal={this.closeWindowPortal}>
                                        <Report
                                            reportName="Course GA - Weeks"
                                            coursePlanId={this.props.courseMasterDetail.Id}
                                            programId={this.props.programId}
                                        ></Report>
                                        <button onClick={() => this.closeWindowPortal()}>
                                            Close
                                        </button>
                                    </WindowPortal>
                                )}
                            </div>
                            <div className="col-12 col-lg-9">
                                {this.state.courseDetails && (
                                    <>
                                        <span className="poppinFont font14 riverBed margin-right-10">
                                            GAIs requested for instructor capture:
                                        </span>
                                        <GAIOccurances
                                            CLOGaiDataCaptures={this.state.courseDetails.DataCaptures}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row no-padding no-margin">
                    <div className="col-12 no-padding">
                        <div className="row no-padding no-margin">
                            <div className="col-12">
                                {this.state.courseDetails && (
                                    <CourseMasterCloGai
                                        coursePlanId={this.props.courseMasterDetail.Id}
                                        CLO={this.state.courseDetails.CourseLearningOutcomes}
                                        CLOGaiDataCaptures={this.state.courseDetails.DataCaptures}
                                        CLOGaiContentLevels={
                                            this.state.courseDetails.GaiContentLevels
                                        }
                                        coursePlanUpdated={this._getCourseDetails}
                                        allContentLevels={this.props.allContentLevels}
                                        allGAs={this.props.allGAs}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CourseMasterAttributes;
