// https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios
// https://github.com/axios/axios#axiosgeturl-config-1
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index

import axios from "axios";

// baseURL comes from appsettings
let baseUrl = "Invalid-URL";
let reloginAlertShown = false;

var axiosApi = axios.create({
    baseURL: baseUrl + "/odata/",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

axiosApi.baseURL = baseUrl + "/odata/";

axiosApi.setBaseURL = function (baseURL) {
    baseUrl = baseURL;
    axiosApi.baseURL = baseURL + "odata/";
    axiosApi.defaults.baseURL = axiosApi.baseURL;
};

axiosApi.getCancelactionToken = () => {
    return axios.CancelToken;
};

axiosApi.isCancel = axios.isCancel;

axiosApi.interceptors.request.use((config) => {
    var authToken = localStorage.getItem("AuthToken");
    if (authToken === 'undefined') authToken = undefined;

    config.headers["Authorization"] = "Bearer " + authToken;

    if (config.url.includes("token"))
        config.baseURL = axiosApi.baseURL.replace("odata/", "");
    else
        config.baseURL = axiosApi.baseURL;

    return config;
});

axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error.response ? error.response.status : null;

        if (status === 401) {
            return axiosApi
                .post(
                    baseUrl +
                    "RefreshToken?refresh_token=" +
                    encodeURIComponent(localStorage.getItem("RefreshToken"))
                )
                .then(
                    (response) => {
                        var authToken = localStorage.getItem("AuthToken");
                        if (authToken === 'undefined') authToken = undefined;

                        localStorage.setItem("RefreshToken", response.data.refresh_token);
                        localStorage.setItem("AuthToken", response.data.access_token);
                        error.config.headers["Authorization"] = "Bearer " + authToken;
                        return axios.request(error.config);
                    },
                    (error) => {
                        takeUserToLogin();
                    }
                );
        } else if (status === 403) {
            takeUserToLogin();
            return Promise.reject(error);
        } else {
            console.log(`Error: ${error}`);
            console.log(`Url: ${error.request.responseURL}`);

            return Promise.reject(error);
        }

        function takeUserToLogin() {
            localStorage.removeItem("AuthToken");
            localStorage.removeItem("RefreshToken");

            if (window.location.pathname !== "/") {
                if (reloginAlertShown === false) {
                    alert(
                        "Your session has expired and you will be taken to the login page. Please log in."
                    );
                    reloginAlertShown = true;
                }

                window.location = "/";
            }
        }
    }
);

export default axiosApi;
