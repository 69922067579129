import React from "react";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import LockIcon from "@material-ui/icons/Lock";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import "./AssessmentAttachments.css";
import API from "./../Components/api";
import GAIChart from "./GAIChart";
import AssessmentPhoto from "./../Assessment/AssessmentPhoto";
import QuestionGrid from "./QuestionGrid";
import AssessmentMarks from "./AssessmentMarks";

const styles1 = (theme1) => ({
  colorSwitchBase: {
    color: "#D7CEC7",
    "&$colorChecked": {
      color: "#B82601",
      "& + $colorBar": {
        backgroundColor: "#062F4F",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

class AssessmentAttachments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      GAIChartsData: null,
      assessmentId: this.props.assessmentId,
      isLocked: this.props.assessmentData.IsComplete,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.isLocked !== this.props.assessmentData.IsComplete)
      this.setState({ isLocked: this.props.assessmentData.IsComplete });
  }

  render() {
    const { classes } = this.props;

    let GAICharts = [];

    //TODO: Hard code for SFU refactor later
    let [keyTitle, highTitle, medTitle, lowTitle] = [
      "Key",
      "High Case",
      "Medium Case",
      "Low Case",
    ];

    let instName = localStorage.getItem("InstName");
    if (instName === "SFU")
      [keyTitle, highTitle, medTitle, lowTitle] = [
        "Exceeds Expectation",
        "Meets Expectation",
        "Marginal",
        "Below Expectation",
      ];

    if (this.state.GAIChartsData) {
      this.state.GAIChartsData.forEach((chart, idx) => {
        GAICharts.push(
          <Paper key={"GAI_" + idx}>
            <div style={{ marginTop: "10px" }}>
              <GAIChart data={this.state.GAIChartsData[idx]}></GAIChart>
            </div>
          </Paper>
        );
      });
    }

    if (GAICharts.length === 0)
      GAICharts = (
        <div className="full-width center">
          No GAIs have been assigned to this Assessment.
        </div>
      );

    return (
      <React.Fragment>
        <div className="bottom-box" id="question-grid">
          <QuestionGrid
            isDisabled={this.state.isLocked}
            onGaiChanged={this._getGaiCharts}
            assessmentId={this.props.assessmentData.Id}
            maxAssessmentScore={this.props.assessmentData.MaxGrade}
            academicTermId={this.props.academicTermId}
            courseInstanceId={this.props.courseInstanceId}
            programs={this.props.programs}
            academicYearId={this.props.academicYearId}
          ></QuestionGrid>
        </div>
        <div className="bottom-box">
          <AssessmentMarks
            assessmentMaxMark={this.props.assessmentMaxMark}
            isAssessmentClosed={this.state.isLocked}
            assessmentId={this.props.assessmentData.Id}
          ></AssessmentMarks>
        </div>
        <div className="bottom-box" id="samples-of-student-work">
          <Paper style={{ padding: 5 }}>
            Samples of Student Work
            <Grid
              container
              spacing={24}
              style={{ padding: 15, textAlign: "left" }}
            >
              <Grid item xs={12}>
                <Grid container spacing={24}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper>
                      <AssessmentPhoto
                        showButtons={!this.state.isLocked}
                        title={lowTitle}
                        assessmentId={this.props.assessmentData.Id}
                        typeOfPicture="Low"
                        onPictureUpdated={this._updateTargetUrl}
                        imageSource={this.props.assessmentData.LowImgUrl}
                      ></AssessmentPhoto>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper>
                      <AssessmentPhoto
                        showButtons={!this.state.isLocked}
                        title={medTitle}
                        assessmentId={this.props.assessmentData.Id}
                        typeOfPicture="Medium"
                        onPictureUpdated={this._updateTargetUrl}
                        imageSource={this.props.assessmentData.MediumImgUrl}
                      ></AssessmentPhoto>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper>
                      <AssessmentPhoto
                        showButtons={!this.state.isLocked}
                        title={highTitle}
                        assessmentId={this.props.assessmentData.Id}
                        typeOfPicture="High"
                        onPictureUpdated={this._updateTargetUrl}
                        imageSource={this.props.assessmentData.HighImgUrl}
                      ></AssessmentPhoto>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Paper>
                      <AssessmentPhoto
                        showButtons={!this.state.isLocked}
                        title={keyTitle}
                        assessmentId={this.props.assessmentData.Id}
                        typeOfPicture="Key"
                        onPictureUpdated={this._updateTargetUrl}
                        imageSource={this.props.assessmentData.KeyImgUrl}
                      ></AssessmentPhoto>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
        <div className="bottom-box">
          <Paper>
            <LockIcon></LockIcon>
            <div className="ehs-modal-span" id="lock-assessment-label">
              Lock Assessment:{" "}
              <div className="sub-text">Marks Entry is Complete?</div>
            </div>
            <Switch
              checked={this.state.isLocked}
              onChange={() => this._onLockedChange()}
              classes={{
                switchBase: classes.colorSwitchBase,
                checked: classes.colorChecked,
                bar: classes.colorBar,
              }}
              value={this.state.isLocked}
            />
            {this.state.isLocked === true ? "Y" : "N"}
          </Paper>
        </div>
        <div className="bottom-box center">
          <Paper className="center" style={{ padding: "5px 30px 30px 30px" }}>
            GAI Charts
            {GAICharts}
          </Paper>
        </div>
      </React.Fragment>
    );
  }

  _onLockedChange = () => {
    this.setState(
      {
        isLocked: !this.state.isLocked,
      },
      () => {
        this.props.LockToggleIsComplete(this.state.isLocked);
      }
    );
  };

  componentDidMount() {
    this._getGaiCharts();
  }

  _getGaiCharts = () => {
    if (this.state.assessmentId != "undefined" && this.state.assessmentId)
      API.get(`AssessmentGai/GaiCharts(assessmentId=${this.state.assessmentId})`)
        .then(this._onGetGaiChartsOk)
        .catch(this._onGetGaiChartsError);
  };

  _onGetGaiChartsOk = (response) => {
    var chartData = response.data.map((elem) => {
      elem.chartData = elem.chartData.map((x) => {
        return [x.xValue, x.percent, x.columnLabel];
      });
      return elem;
    });

    this.setState({ GAIChartsData: chartData });
  };

  _onGetGaiChartsError = (err) => {
    alert("Error: Unable to retrieve GAI charts.");
  };
}

export default withStyles(styles1)(AssessmentAttachments);
