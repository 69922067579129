import React from "react";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class ReportRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataRow: props.dataRow
        }
    }

    calcHeight = (transform, text, fontname, fontsize, height) => {
        if (!transform)
            return height;

        return this.getWidthOfText(text, fontname, fontsize) + 40;
    }

    getWidthOfText = (txt, fontname, fontsize) => {
        if (this.getWidthOfText.c === undefined) {
            this.getWidthOfText.c = document.createElement('canvas');
            this.getWidthOfText.ctx = this.getWidthOfText.c.getContext('2d');
        }

        this.getWidthOfText.ctx.font = fontsize + ' ' + fontname;
        return this.getWidthOfText.ctx.measureText(txt).width;
    }
    
    render() {
        return (
            <TableRow>
                {this.state.dataRow.map((cell, index) => (
                    <TableCell key={index} className={cell.cssClass} colSpan={cell.colSpan} rowSpan={cell.RowSpan} height={this.calcHeight(cell.Transform, cell.Title, "Arial", cell.FontSize, cell.Height)} 
                        style={{
                            textAlign: cell.TextAlign,
                            background: cell.BgColor,
                            fontSize: cell.FontSize,
                            fontWeight: cell.FontWeight,
                            padding: "1px",
                            color: cell.Color
                        }}>
                        <div className={cell.cssClass} style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            width: cell.hasOwnProperty("width") && cell.width != 0 ? cell.width : "auto",
                            padding: "1px",
                            backgroundImage: cell.backgroundImage
                            }}>{cell.title}</div>
                    </TableCell>
                ))}
            </TableRow>
        );
    }
}
    
export default (ReportRow);