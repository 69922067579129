import React from "react";
import { Redirect } from "react-router";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import DropDownList from "../Components/DropDownList";
import CourseSummary from "./CourseSummary";
import InstructorSummary from "./InstructorSummary";
import GAISummary from "./GAISummary";
import API from "./../Components/api";
import UserManagement from "./UserManagement";
import Report from "../BCITReports/Report";
import WindowPortal from "../WindowPortal";
import CourseMasterWrapper from "./CoursePlan/CourseMasterWrapper";
import ReportsWrapper from "./Reports/ReportsWrapper";
import { cancelHttpRequests } from "./../Helpers/HelperFunctions";

const styles = (theme) => ({
    tabsIndicator: {
        backgroundColor: "#B82601",
    },
});

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 12 }}>
            {props.children}
        </Typography>
    );
}

class AdminOverview extends React.Component {
    cancelToken = API.getCancelactionToken();
    source = this.cancelToken.source();

    constructor(props) {
        super(props);

        this.state = {
            allTaughtSessions: null,
            filteredTaughtSessions: null,
            allInstructors: null,
            allAcademicYears: [],
            allPrograms: [],
            tabId: 0,
            toInstructorView: false,
            anchorEl: null,
            showReport: false,
            reportButtons: [],
            reportName: "",
            selectedAcademicYear: "",
            selectedProgram: "",
            termId: null,
            showTermSelect: true,
        };

        this.ADMIN_TAB_KEY = "ADMINTAB";

        this.closeWindowPortal = this.closeWindowPortal.bind(this);
        this._getAcademicYears = this._getAcademicYears.bind(this);
        this._getPrograms = this._getPrograms.bind(this);
        this._getAllAcademicTerms = this._getAllAcademicTerms.bind(this);
        this._getTabData = this._getTabData.bind(this);
        this._getAcademicTermInstructors =
            this._getAcademicTermInstructors.bind(this);
        this._setSelectedTerm = this._setSelectedTerm.bind(this);
        this._isUserAdmin = this._isUserAdmin.bind(this);
        this._getRolesFromToken = this._getRolesFromToken.bind(this);
        this.setReportName = this.setReportName.bind(this);
    }

    componentDidMount() {
        window.addEventListener("beforeunload", () => {
            this.closeWindowPortal();
        });

        if (!this._isUserAdmin()) this.setState({ toInstructorView: true });
        else {
            this._getAcademicYears();
            this._getPrograms();
        }

        this.loadCurrentTab();
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
        this.storeCurrentTab();
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    reportClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    //Get current tab from local storage
    loadCurrentTab = () => {
        let adminTabStateJSON = localStorage.getItem(this.ADMIN_TAB_KEY);

        if (!adminTabStateJSON) return null;

        let adminTabState = JSON.parse(adminTabStateJSON);
        this.setState(
            {
                termId: adminTabState.termId,
                selectedProgram: adminTabState.selectedProgram,
                selectedAcademicYear: adminTabState.selectedAcademicYear,
                tabId: adminTabState.tabId,
                showTermSelect: adminTabState.tabId <= 2,
            },
            () => {
                this._getTabData();
            }
        );
    };

    //Store current tab in local storage
    storeCurrentTab = () => {
        let adminTabState = {
            termId: this.state.termId,
            tabId: this.state.tabId,
            selectedAcademicYear: this.state.selectedAcademicYear,
            selectedProgram: this.state.selectedProgram
        };

        let adminTabStateJSON = JSON.stringify(adminTabState);

        localStorage.setItem(this.ADMIN_TAB_KEY, adminTabStateJSON);
    };

    showReportButtons = (type) => {
        if (type === "Civil") {
            this.setState({
                reportButtons: [{ Name: "AU Breakdown" }],
            });
        } else if (type === "Mining") {
            this.setState({
                reportButtons: [],
            });
        } else if (type === "Mechanical") {
            this.setState({
                reportButtons: [],
            });
        } else if (type === "Electrical") {
            this.setState({
                reportButtons: [],
            });
        } else {
            this.setState({
                reportButtons: [],
            });
        }

        this.handleClose();
    };

    showReport = (reportType) => {
        this.setState(
            {
                showReport: true,
            },
            function () {
                this.setState(this.state);
            }
        );
    };

    closeWindowPortal() {
        this.setState({ showReport: false });
    }

    setReportName = (reportName, selectedAcademicYear, selectedProgram) => {
        // need to set to false to close currently opened report and then open a new one in the nested setState
        this.setState({ showReport: false }, function () {
            this.setState(
                {
                    reportName: reportName,
                    selectedAcademicYear: selectedAcademicYear,
                    selectedProgram: selectedProgram,
                    showReport: true,
                },
                function () {
                    this.setState(this.state);
                }
            );
        });
    };

    onProgramChanged = (program) => {
        this.setState(
            {
                selectedProgram: program,
            },
            () => {
                this._getTabData();
            }
        );
    };

    onAcademicYearChanged = (academicYear) => {
        this.setState(
            {
                selectedAcademicYear: academicYear,
            },
            () => {
                this._getAllAcademicTerms();
            }
        );
    };

    render() {
        const { classes } = this.props;
        if (this.state.toInstructorView) {
            let newUrl = "/terms";
            return <Redirect from="/admin" to={newUrl} push></Redirect>;
        }

        var termSelect = null;

        if (this.state.showTermSelect) {
            termSelect = (
                <DropDownList
                    title="Term"
                    hideNotSpecified={
                        this.state.filteredTaughtSessions &&
                        this.state.filteredTaughtSessions.length > 0
                    }
                    selectedId={this.state.termId}
                    dataSet={this.state.filteredTaughtSessions}
                    onSelectionChanged={(selectedOption) =>
                        this._setSelectedTerm(selectedOption)
                    }
                />
            );
        }

        return (
            <React.Fragment>
                <div className="appBar">
                    <div className="row no-margin">
                        <div
                            className="col-12 col-md-3 no-padding-left"
                            style={{ marginBottom: "5px", maxWidth: "350px" }}
                        >
                            {this.state.tabId < 5 && (
                                <DropDownList
                                    title="Program"
                                    hideNotSpecified={
                                        this.state.allPrograms && this.state.allPrograms.length > 0
                                    }
                                    selectedId={
                                        this.state.allPrograms && this.state.allPrograms.length > 0
                                            ? this.state.selectedProgram
                                        : null
                                    }
                                    dataSet={this.state.allPrograms}
                                    onSelectionChanged={this.onProgramChanged}
                                />
                            )}
                        </div>
                        <div
                            className="col-12 col-md-3"
                            style={{ marginBottom: "5px", maxWidth: "420px" }}
                        >
                            {this.state.tabId < 5 && (
                                <DropDownList
                                    title="Academic Year"
                                    hideNotSpecified={
                                        this.state.allAcademicYears &&
                                        this.state.allAcademicYears.length > 0
                                    }
                                    selectedId={
                                        this.state.allAcademicYears && this.state.allAcademicYears.length > 0
                                            ? this.state.selectedAcademicYear
                                            : null
                                    }
                                    dataSet={this.state.allAcademicYears}
                                    onSelectionChanged={this.onAcademicYearChanged}
                                />
                            )}
                        </div>
                        <div className="col-12 col-md-3 ">{termSelect}</div>
                    </div>
                </div>
                <div className="appBar">
                    <AppBar position="static">
                        <Tabs
                            value={this.state.tabId}
                            onChange={this._handleChange}
                            centered={true}
                            variant="fullWidth"
                            classes={{ indicator: classes.tabsIndicator }}
                        >
                            <Tab label="Courses" />
                            <Tab label="Instructors" />
                            <Tab label="GA" />
                            <Tab label="Course Master" />
                            <Tab onClick={this.reportClick} label="Reports" />
                            <Tab label="Users" />
                        </Tabs>
                    </AppBar>
                    {this.state.tabId === 0 && (
                        <TabContainer>
                            <CourseSummary
                                academicTermId={this.state.termId}
                                programId={this.state.selectedProgram}
                            />
                        </TabContainer>
                    )}
                    {this.state.tabId === 1 && (
                        <TabContainer>
                            {
                                <InstructorSummary
                                    allInstructors={this.state.allInstructors}
                                    termId={this.state.termId}
                                    programId={this.state.selectedProgram}
                                />
                            }
                        </TabContainer>
                    )}
                    {this.state.tabId === 2 && (
                        <TabContainer>
                            {
                                <GAISummary
                                    programId={this.state.selectedProgram}
                                    selectedAcademicYear={this.state.selectedAcademicYear}
                                    termId={this.state.termId}
                                />
                            }
                        </TabContainer>
                    )}
                    {this.state.tabId === 3 && (
                        <TabContainer>
                            {
                                <CourseMasterWrapper
                                    selectedProgram={this.state.selectedProgram}
                                    selectedAcademicYear={this.state.selectedAcademicYear}
                                    onProgramChanged={this.onProgramChanged}
                                    onAcademicYearChanged={this.onAcademicYearChanged}
                                />
                            }
                        </TabContainer>
                    )}
                    {this.state.tabId === 4 && (
                        <TabContainer>
                            <ReportsWrapper
                                setReportName={this.setReportName}
                                allPrograms={this.state.allPrograms}
                                selectedProgram={this.state.selectedProgram}
                                selectedAcademicYear={this.state.selectedAcademicYear}
                                allAcademicYears={this.state.allAcademicYears}
                                onProgramChanged={this.onProgramChanged}
                                onAcademicYearChanged={this.onAcademicYearChanged}
                            />
                        </TabContainer>
                    )}
                    {this.state.tabId === 5 && (
                        <TabContainer>{<UserManagement></UserManagement>}</TabContainer>
                    )}
                </div>
                <div>
                    {this.state.showReport && (
                        <WindowPortal closeWindowPortal={this.closeWindowPortal}>
                            <h4>
                                <span
                                    className="blue"
                                    style={{
                                        padding: "5px",
                                        borderRadius: "5px",
                                        fontFamily: "Helvetica, Arial, sans-serif",
                                    }}
                                >
                                    {this.state.reportName +
                                        " " +
                                        this.state.allAcademicYears.find(
                                            (x) => x.Id === this.state.selectedAcademicYear
                                        ).Name}
                                </span>
                            </h4>
                            <Report
                                reportName={this.state.reportName}
                                academicYearId={this.state.selectedAcademicYear}
                                programId={this.state.selectedProgram}
                            />
                            <button onClick={() => this.closeWindowPortal()}>Close</button>
                        </WindowPortal>
                    )}
                </div>
            </React.Fragment>
        );
    }

    _isUserAdmin() {
        var allRoles = this._getRolesFromToken();

        if (allRoles === null) {
            localStorage.removeItem("RefreshToken");

            if (window.location.pathname !== "/") window.location = "/";
        }

        return allRoles.includes("Admin") > -1;
    }

    _getRolesFromToken() {
        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        if (!authToken) {
            return null;
        } else {
            var base64Url = authToken.split(".")[1];
            var base64 = base64Url.replace("-", "+").replace("_", "/");
            var tokenInfo = JSON.parse(window.atob(base64));
            return tokenInfo[
                "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ];
        }
    }

    /**
     * Get Academic Years from API
     */
    _getAcademicYears = () => {
        var that = this;
        API.get(`AcademicYear`, {
            cancelToken: this.source.token,
        })
            .then((res) => {
                let academicYear = this.state.selectedAcademicYear;

                if (!academicYear)
                    academicYear = res.data.value[res.data.value.length - 1].Id;

                that.setState(
                    {
                        selectedAcademicYear: academicYear,
                        allAcademicYears: res.data.value,
                    },
                    () => {
                        this._getAllAcademicTerms();
                    }
                );
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    };

    /**
     * Get Programs from API
     */
    _getPrograms = () => {
        var that = this;

        API.get(`Program`, {
            cancelToken: this.source.token,
        })
            .then((res) => {
                let selectedProgram = this.state.selectedProgram;

                if (!selectedProgram)
                    selectedProgram = res.data.value[res.data.value.length - 1].Id;

                that.setState({
                    allPrograms: res.data.value,
                    selectedProgram: selectedProgram,
                });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    };

    /**
     * Navigate to Instructor view
     */
    _toInstructorView = () => {
        this.setState({
            toInstructorView: true,
        });
    };

    /**
     * Set the Term Id into state
     */
    _setSelectedTerm(termId) {
        if (this.state.termId === termId) return;

        var that = this;
        this.setState(
            {
                allInstructors: null,
                termId: termId,
            },
            that._getTabData
        );
    }

    _handleChange = (event, value) => {
        this.setState(
            {
                tabId: value,
                showTermSelect: value <= 2,
            },
            () => {
                this.storeCurrentTab();
                this._getTabData();
            }
        );
    };

    /**
     * Get data for the selected tab
     */
    _getTabData() {
        if (this.state.tabId === 1) this._getAcademicTermInstructors();
    }

    /**
     * Get Instructors for the selected Term
     */
    _getAcademicTermInstructors() {
        this.setState(
            {
                allInstructors: null,
            },
            () => {
                API.get(`AcademicTerm/GetInstructors(academicTermId=${this.state.termId},programId=${this.state.selectedProgram})`,
                    {
                        cancelToken: this.source.token,
                    }
                )
                    .then((res) => {
                        this.setState({
                            allInstructors: res.data.value,
                        });
                    })
                    .catch((err) => {
                        if (API.isCancel(err)) return;
                    });
            }
        );
    }

    /**
     * Get all Semesters and Years
     */
    _getAllAcademicTerms() {
        var that = this;

        if (!this.state.allTaughtSessions) {
            API.get(`Admin/GetAcademicTerms()`, {
                cancelToken: this.source.token,
            })
                .then((res) => {
                    let termId = that.state.termId;
                    let filteredSessions = res.data.value.filter(
                        (x) => x.AcademicYearId === this.state.selectedAcademicYear
                    );
                    if (
                        (!termId && filteredSessions && filteredSessions > 0) ||
                        !filteredSessions.some((x) => x.Id === termId)
                    ) {
                        termId = filteredSessions[0].Id;
                    }

                    that.setState(
                        {
                            termId: termId,
                            allTaughtSessions: res.data.value,
                            filteredTaughtSessions: filteredSessions,
                        },
                        () => {
                            this._getTabData();
                        }
                    );
                })
                .catch((err) => {
                    if (API.isCancel(err)) return;
                });
        } else {
            let filteredSessions = that.state.allTaughtSessions.filter(
                (x) => x.AcademicYearId === this.state.selectedAcademicYear
            );

            that.setState(
                {
                    termId: filteredSessions[0].Id,
                    filteredTaughtSessions: filteredSessions,
                },
                () => {
                    this._getTabData();
                }
            );
        }
    }
}

export default withStyles(styles)(AdminOverview);
