import React from "react";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import InstructorCourseNames from "./InstructorCourseNames";
import SortBy from "../SortBy";
import "./InstructorSummary.css";
import WaitSpinner from "./../Components/WaitSpinner";

class InstructorSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allInstructors: null,
      currentSortOrder: null,
    };

    this._expandInstructor = this._expandInstructor.bind(this);
    this._sortInstructors = this._sortInstructors.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allInstructors !== this.props.allInstructors)
      this.setState({ allInstructors: this.props.allInstructors }, () => {
        this._sortInstructors("FirstName");
      });
  }

  render() {
    if (
      this.state.allInstructors === undefined ||
      this.state.allInstructors === null ||
      this.state.currentSortOrder === null
    ) {
      return (
        <div className="center-wrapper full-height">
          <WaitSpinner></WaitSpinner>
        </div>
      );
    }

    if (this.state.allInstructors.length === 0)
      return (
        <React.Fragment>
          <span className="arrowCursor">
            No Instructors have been added for this Semester
          </span>
        </React.Fragment>
      );

    let sortOptions = [
      { paramName: "First Name", paramNameKey: "FirstName" },
      { paramName: "Last Name", paramNameKey: "LastName" },
    ];

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12 text-right">
            <SortBy
              onSortOrderChanged={this._sortInstructors}
              allSortOptions={sortOptions}
              sortOptionActive={sortOptions[0].paramNameKey}
            ></SortBy>
          </div>
        </div>
        {this.state.allInstructors.map((instructor, key) => {
          return (
            <ExpansionPanel
              key={key}
              onChange={this._expandInstructor}
              className="expansionPanelSpacing"
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className="white" />}
                className="ink"
              >
                <Typography style={{ color: "white" }}>
                  {instructor.FirstName} {instructor.LastName}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  {
                    <InstructorCourseNames
                      key={key}
                      termId={this.props.termId}
                      programId={this.props.programId}
                      instructorId={instructor.Id}
                      courses={instructor.Courses}
                    ></InstructorCourseNames>
                  }
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </React.Fragment>
    );
  }

  _sortInstructors(sortByOrder) {
    if (this.state.allInstructors == null) return;

    let newInstructorsStructure = this.state.allInstructors.slice();

    if (sortByOrder === "LastName") {
      newInstructorsStructure.sort(function (a, b) {
        if (a.LastName < b.LastName) return -1;
        if (a.LastName > b.LastName) return 1;
        if (a.FirstName < b.FirstName) return -1;
        if (a.FirstName > b.FirstName) return 1;
        return 0;
      });
    } else if (sortByOrder === "FirstName") {
      newInstructorsStructure.sort(function (a, b) {
        if (a.FirstName < b.FirstName) return -1;
        if (a.FirstName > b.FirstName) return 1;
        if (a.LastName < b.LastName) return -1;
        if (a.LastName > b.LastName) return 1;
        return 0;
      });
    }

    this.setState({
      allInstructors: newInstructorsStructure,
      currentSortOrder: sortByOrder,
    });
  }

  _expandInstructor = (e, isExpanded) => {
    if (!isExpanded) return;
  };
}

InstructorSummary.propTypes = {
  // allInstructors: PropTypes.array.isRequired,
  termId: PropTypes.number.isRequired,
};

export default InstructorSummary;
