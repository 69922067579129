export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

export function validateDecimal(e) {
    e.target.value = e.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
};

export function validateDecimal100Max(e) {
    validateDecimal(e);

    if (e.target.value > 100.0) e.target.value = 100;
    else if (e.target.value < 0) e.target.value = 0;
};

export function deepCloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
};

export function cancelHttpRequests(source) {
    if (source) source.cancel();
};