import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./AlertDialog.css"

class InfoDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.show
        }

        this._handleClose = this._handleClose.bind(this);
    }

    render() {

        return (
            <React.Fragment>
                <Dialog
                    open={this.state.open}
                    onClose={this._handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.props.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._handleClose}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    _handleClose() {
        this.setState({ open: false });
        if (this.props.onDialogClose)
            this.props.onDialogClose();
    }
}

export default InfoDialog;