import React from "react";
import Typography from '@material-ui/core/Typography';

import { InstructorCourseOverview } from "../MainPage/InstructorCourseOverview";
import DropDownList from "../Components/DropDownList";
import WaitSpinner from "./../Components/WaitSpinner"
import ToAdminMenu from "./../Components/ToAdminMenu"
import "../MainPage/Header";
import "../Components/DropDownList";
import API from "./../Components/api"

class TermCourses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theValue: -1,
            allTaughtSessions: [],
            termCourses: null,
            academicTermId: null
        }

        this._getAllTaughtSessions = this._getAllTaughtSessions.bind(this);
        this._getTermCourses = this._getTermCourses.bind(this);
        
        this.INSTRUCTOR_TAB_KEY = 'INSTRUCTORTAB';
    }

    loadCurrentTab = () => {
        let instructorTabStateJSON = localStorage.getItem(this.INSTRUCTOR_TAB_KEY);

        if (!instructorTabStateJSON)
            return null;

        let instructorTabState = JSON.parse(instructorTabStateJSON);
        this.setState({
            academicTermId: instructorTabState.academicTermId
      });
    }

    //Store current tab in local storage
    storeCurrentTab = () => {
        let instructorTabState = {
            academicTermId: this.state.academicTermId
        };

        let instructorTabStateJSON = JSON.stringify(instructorTabState);

        localStorage.setItem(this.INSTRUCTOR_TAB_KEY, instructorTabStateJSON );
    }

    componentDidMount() {
        this._getAllTaughtSessions();
        this.loadCurrentTab();
    }

    componentWillUnmount() {
        this.storeCurrentTab();
    }

    render() {
        return (
            <React.Fragment>
                <div className="appBar">
                    <div className="row" style={{ margin: "0" }}>
                        <div className="col-6 no-padding-left">
                            <DropDownList
                                title="Term"
                                selectedId={this.state.academicTermId}
                                hideNotSpecified={this.state.allTaughtSessions && this.state.allTaughtSessions.length > 0}
                                dataSet={this.state.allTaughtSessions}
                                onSelectionChanged={(selectedOption) => this._getTermCourses(selectedOption)}>
                            </DropDownList>
                        </div>
                    </div>
                </div>
                {this._renderTermCourses()}
            </React.Fragment>
        );
    }

    /**
     * Get all Sessions
     */
    _getAllTaughtSessions() {
        var that = this;
        
        API.get(`Instructor/GetTaughtSessions()`)
            .then((res) => 
            {
                let termId = that.state.academicTermId;
                if(!termId && res.data.value && res.data.value.length > 0){
                    termId = res.data.value[0].Id;
                }

                this.setState({
                    allTaughtSessions: res.data.value,
                    academicTermId: termId
                }, function (){ that._getTermCourses(termId) });
        });
    }

    /**
     * Get all Courses for a specific Term from the API
     * @param {int} termId Term Id
     */
    _getTermCourses(termId) {
        this.setState({ termCourses: null });

        API.get(`AcademicTerm/GetCourses(termId=${termId})`)
            .then(res => this.setState({
                termCourses: res.data.value,
                academicTermId: termId
            }))
    }

    /**
     * Render all Courses for a specific term
     */
    _renderTermCourses = () => {
        if (this.state.termCourses === null)
            return <WaitSpinner />;

        let allTermCourses = [];

        if (this.state.termCourses) {
            for (var i = 0; i < this.state.termCourses.length; i++)
                allTermCourses.push(
                    <div className="col-12" key={"termCourse" + i.toString()} style={{ paddingLeft: "5px" }}>
                        <InstructorCourseOverview course={this.state.termCourses[i]}></InstructorCourseOverview>
                    </div>);
        }

        if (allTermCourses.length > 0)
            return <div className="row paperBackground appBar">{allTermCourses}</div>;

        return (
            <Typography component="div" style={{ padding: 24 }}>
                No Courses have been assigned for this Term.
            </Typography>);
    }
}

export default TermCourses;