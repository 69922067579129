import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core";
import Input from "@material-ui/core/Input";

const styles = () => ({
  root: {
    backgroundColor: "#B82601",
    width: "250px",
    borderRadius: "4px",
  },
  selectMenu: {
    color: "white",
    paddingLeft: "10px",
    fontSize: "0.8125rem",
  },
  icon: {
    color: "white",
  },
  underline: {
    "&:before": {
      borderBottom: "none",
    },
  },
});

class StatusDropdown extends Component {
  constructor(props) {
    super(props);

    this.dataSet = [
      { Id: 1, Name: "Requires Setup" },
      { Id: 2, Name: "Complete" },
    ];

    this.state = {
      selectedId: this.props.selectedId,
      selectedName: this.dataSet[0].Name,
    };

    this._dataToMenuItem = this._dataToMenuItem.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  componentDidUpdate() {
    if (
      this.state.selectedName === "--- Not Specified ---" &&
      !this.props.selectedId
    ) {
      this.setState({
        selectedId: this.props.dataSet[0].Id,
        selectedName: this.props.dataSet[0].Name,
      });

      this.props.onSelectionChanged(this.props.dataSet[0].Id);
    } else if (this.props.selectedId !== this.state.selectedId) {
      this.setState({
        selectedId: this.props.selectedId,
      });
    }
  }

  render() {
    const { classes } = this.props;

    var theTitle = "";

    if (this.props.title !== undefined && this.props.title !== null)
      theTitle = this.props.title.trim() + ": ";

    var theSelectedId = this.props.selectedId
      ? this.props.selectedId
      : this.state.selectedId;

    return (
      <React.Fragment>
        <span className="poppinFont font14 margin-right-20">{theTitle}</span>
        <Select
          classes={{
            selectMenu: classes.selectMenu,
            icon: classes.icon,
          }}
          style={{
            width: "250px",
            borderRadius: "4px",
            backgroundColor: theSelectedId === 2 ? "green" : "#B82601",
          }}
          value={theSelectedId || "-1"}
          onChange={this._handleChange}
          input={
            <Input
              classes={{
                underline: classes.underline,
              }}
              className="poppinFont font14"
            />
          }
        >
          {this._dataToMenuItem()}
        </Select>
      </React.Fragment>
    );
  }

  /**
   * Deal with the Session change event
   */
  _handleChange = (event, selectedObj) => {
    this.setState(
      {
        selectedName: selectedObj.props.name,
        selectedId: selectedObj.props.value,
      },
      function () {
        this.props.onSelectionChanged(this.state.selectedId);
      }
    );
  };

  /**
   * Convert the data in the props to MenuItems
   */
  _dataToMenuItem() {
    let toReturn = [];
    // add the empty option
    if (!this.props.hideNotSpecified) {
      toReturn.push(
        <MenuItem key="-1" name="--- Not Specified ---" value="-1">
          <em>--- Not Specified ---</em>
        </MenuItem>
      );
    }

    // add the remaining items
    this.dataSet.map((obj) => {
      toReturn.push(
        <MenuItem key={obj.Id} name={obj.Name} value={obj.Id}>
          {obj.Name}
        </MenuItem>
      );
    });
    return toReturn;
  }
}

export default withStyles(styles)(StatusDropdown);
