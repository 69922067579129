import React from "react";
import API from "./../Components/api";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";
import { CloseSharp } from "@material-ui/icons";

import noImage from "./../../img/no-image.png";
import pdf from "./../../img/pdf.png";
import AlertDialog from "./../AlertDialog";

class AssessmentDetailsPhoto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadPercent: null,
      showFullImage: false,
      promptDeleteAttachment: false,
    };

    this._deleteImage = this._deleteImage.bind(this);
    this._promptDeleteAttachmentConfirmation =
      this._promptDeleteAttachmentConfirmation.bind(this);
  }

  render() {
    if (this.state.uploadPercent !== null) {
      return (
        <React.Fragment>
          <div style={{ fontSize: "85%", textAlign: "center" }}>
            {this.props.title}
          </div>
          <div
            style={{ minHeight: "125px", fontSize: "75%", textAlign: "center" }}
          >
            <span style={{ position: "relative", top: "50px" }}>
              Uploaded {this.state.uploadPercent}
            </span>
          </div>
        </React.Fragment>
      );
    }

    if (this.state.promptDeleteAttachment) {
      return (
        <AlertDialog
          title="Delete Attachment?"
          content="Are you sure you want to delete this attachment?"
          onOkClose={this._deleteImage}
          onCancelClose={this._hideDeletePrompt}
        />
      );
    }

    return (
      <React.Fragment>
        <div style={{ fontSize: "85%", textAlign: "center" }}>
          {this.props.title}
        </div>
        <div className="center">
          <img
            className="preview"
            src={this._renderImageSource()}
            ref={(imgTag) => (this.imgTag = imgTag)}
            onClick={this._openAttachment}
          ></img>
          {this.props.showButtons && (
            <DeleteIcon
              className="actionIcon"
              style={{ position: "absolute", top: "5px", right: "40px" }}
              onClick={this._promptDeleteAttachmentConfirmation}
            />
          )}
        </div>
        <Modal open={this.state.showFullImage} onClose={this.hideFullImage}>
          <div style={{ width: "100%", height: "100%" }}>
            <div className="fullImageStyle" onClick={this.hideFullImage}>
              <div id="close-image-button">
                <CloseSharp onClick={this.hideFullImage} />
              </div>
              <img src={this._renderImageSource()}></img>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  hideFullImage = (event) => {
    if (event.target.tagName.toLowerCase() === "img") return;

    this.setState({ showFullImage: false });
  };

  _openAttachment = () => {
    if (!this.props.imageSource) return;

    if (this.props.imageSource.toLowerCase().includes(".pdf"))
      window.open(this.props.imageSource, "_blank");
    else this.setState({ showFullImage: true });
  };

  _deleteImage = () => {
    API.post(`AssessmentGai(${this.props.assessmentDetailsId})/DeleteGaiAttachment`,
      { attachmentId: this.props.fileId }
    )
      .then(this._onDeleteOk)
      .catch(this._onDeleteError)
      .finally(this._hideDeletePrompt);
  };

  _onDeleteOk = (res) => {
    if (res.status === 200) {
      if (this.props.onPictureRemoved)
        this.props.onPictureRemoved(this.props.fileId);
    }
  };

  _onDeleteError = (err) => {
    // TODO: Something better
    alert("Error: Unable to delete attachment.");
  };

  _renderImageSource = () => {
    if (
      this.props.imageSource &&
      this.props.imageSource.includes("/static/media/no-image")
    )
      return;

    if (
      this.props.imageSource &&
      this.props.imageSource.toLowerCase().includes(".pdf")
    )
      return pdf;

    return this.props.imageSource || noImage;
  };

  _promptDeleteAttachmentConfirmation() {
    this.setState({ promptDeleteAttachment: true });
  }

  _hideDeletePrompt = () => {
    this.setState({ promptDeleteAttachment: false });
  };
}

export default AssessmentDetailsPhoto;
