import React from "react"
import { Redirect } from 'react-router'
import { Grid, Select, TextField, MenuItem } from '@material-ui/core';
import { Error as ErrorIcon } from "@material-ui/icons";
import { HelpRounded as InfoIcon } from "@material-ui/icons"
import moment from "moment"

import WaitSpinner from "./../Components/WaitSpinner"
import API from "./../Components/api"
import AlertDialog from "./../AlertDialog"
import "./AssessmentDetails.css"
import AssessmentAttachments from "./AssessmentAttachments";
import InfoDialog from "../InfoDialog";
import { cancelHttpRequests } from "./../Helpers/HelperFunctions";
import OneStepBack from "../OneStepBack";

const styles = theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200
    }
});

class AssessmentDetails extends React.Component {

    cancelToken = API.getCancelactionToken();
    source = this.cancelToken.source();

    constructor(props) {
        super(props);

        try {
            this.state = {
                isSaving: false,
                isLastSaveOk: true,
                sourceData: null,
                isLoading: true,
                promptConfirmLock: false,
                promptConfirmUnlock: false,
                GAIChartsData: null,
                showAssessmentInfoDialog: false,
                programs: [],
                academicTermId: null,
                academicYearId: null
            }

            this._renderAssessmentTypes = this._renderAssessmentTypes.bind(this);
            this._executePost = this._executePost.bind(this);
            this._executePatch = this._executePatch.bind(this);
            this._saveChanges = this._saveChanges.bind(this);
            this._renderStatusBadge = this._renderStatusBadge.bind(this);
            this._getTitle = this._getTitle.bind(this);
            this._toggleIsComplete = this._toggleIsComplete.bind(this);
            this._onCourseMarkedCompletedOk = this._onCourseMarkedCompletedOk.bind(this);
            this._onCourseMarkedCompletedError = this._onCourseMarkedCompletedError.bind(this);
            this._onConfirmLockClose = this._onConfirmLockClose.bind(this);
            this._showAssessmentInfoDialog = this._showAssessmentInfoDialog.bind(this);
        }
        catch (err) {
            this.state = { isError: true }
        }
    }

    componentDidMount() {
        this._getAssessment(this.props.match.params.id1);
        this._getAssessmentTypes();
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    _onConfirmLockClose(lock) {
        this.setState({
            promptConfirmLock: false,
            promptConfirmUnlock: false
        },
            function () {
                if (lock === null)
                    return;

                if (lock) {
                    API.post(`Assessment(${this.state.sourceData.Id})/MarkComplete`,
                        {
                            cancelToken: this.source.token
                        })
                        .then(this._onCourseMarkedCompletedOk)
                        .catch(this._onCourseMarkedCompletedError);
                }
                else {
                    API.post(`Assessment(${this.state.sourceData.Id})/OpenAssessment`,
                        {
                            cancelToken: this.source.token
                        })
                        .then(this._onCourseReopenedOk)
                        .catch(this._onCourseReopenedError);
                }
            })
    }

    _toggleIsComplete = (isLocked) => {
        if (isLocked)
            this.setState({ promptConfirmLock: true });
        else
            this.setState({ promptConfirmUnlock: true });
    }

    _onCourseReopenedOk = (res) => {
        let newSourceData = Object.assign({}, this.state.sourceData);
        newSourceData["IsComplete"] = false;
        this.setState({ sourceData: newSourceData })
    }

    _onCourseReopenedError = (err) => {
        if (API.isCancel(err))
            return;

        let newSourceData = Object.assign({}, this.state.sourceData);
        newSourceData["IsComplete"] = newSourceData["IsComplete"];
        this.setState({ sourceData: newSourceData });
    }

    _onCourseMarkedCompletedOk = (res) => {
        let newSourceData = Object.assign({}, this.state.sourceData);
        newSourceData["IsComplete"] = true;
        this.setState({ sourceData: newSourceData })
    }

    _onCourseMarkedCompletedError = (err) => {
        if (API.isCancel(err))
            return;

        let newSourceData = Object.assign({}, this.state.sourceData);
        newSourceData["IsComplete"] = newSourceData["IsComplete"];
        this.setState({ sourceData: newSourceData });
    }

    _updateTargetUrl = (imgUrl, typeOfPicture) => {
        // copy the current sourceData
        let newSourceData = Object.assign({}, this.state.sourceData);
        newSourceData[typeOfPicture + "ImgUrl"] = imgUrl;

        this.setState({ sourceData: newSourceData });
    }

    _getAssessment(id) {
        if (!id || id === "undefined") {
            var newAssessment = {
                "CourseInstanceId": this.props.match.params.id,
                "AssessmentTypeId": "",
                "Title": "",
                "Description": "",
                "Weight": null,
                "MaxGrade": null
            }

            this.setState({
                sourceData: newAssessment,
                isLoading: false
            })
        }
        else {
            let assessmentId = id;
            var that = this;

            API.get(`Assessment(${assessmentId})?$expand=GAIs,CourseInstance($expand=Programs,AcademicTerm)`,
                {
                    cancelToken: this.source.token
                })
                .then(function (res) {
                    that.setState({
                        sourceData: res.data,
                        programs:  res.data.CourseInstance.Programs,
                        academicYearId: res.data.CourseInstance.AcademicTerm.AcademicYearId,
                        academicTermId: res.data.CourseInstance.AcademicTerm.Id,
                        isLoading: false
                    })
                }).catch(err => {
                    if (API.isCancel(err))
                        return;
                });
        }
    }

    /**
     * Get Assessment Types
     */
    _getAssessmentTypes() {
        var that = this;

        API.get(`AssessmentType`,
            {
                cancelToken: that.source.token
            })
            .then(function (res) {
                that.setState({
                    allAssessmentTypes: res.data.value
                })
            }).catch(err => {
                if (API.isCancel(err))
                    return;
            });
    }

    _getTitle() {
        if (!this.state.allAssessmentTypes)
            return "";

        for (let idx = 0; idx < this.state.allAssessmentTypes.length; idx++) {
            if (this.state.allAssessmentTypes[idx].Id === this.state.sourceData.AssessmentTypeId)
                return this.state.sourceData.Title + " - " + this.state.allAssessmentTypes[idx].Title;
        }

        if (this.state.sourceData.Title === "")
            return "Create New Assessment";

        return this.state.sourceData.Title;
    }

    /**
     * Convert the date time as a string into a useable string for TextField
     */
    _convertToDate(theDateAsString) {
        if (theDateAsString === undefined || theDateAsString === null || theDateAsString === "")
            return;

        return moment.utc(theDateAsString).format("YYYY-MM-DD");
    }

    _renderAssessmentTypeControl = () => {
        if (this.state.sourceData.IsComplete) {
            return (
                <TextField
                    style={{
                        marginLeft: "theme.spacing.unit",
                        marginRight: "theme.spacing.unit",
                        marginTop: "0"
                    }}
                    readOnly
                    value={this._getAssessmentTypeText(this.state.sourceData.AssessmentTypeId)}
                    margin="normal"
                    fullWidth
                    disabled={true}
                />
            )
        }

        return (
            <Select
                className={this.state.sourceData.IsComplete ? "disabled-text" : ""}
                fullWidth
                autoWidth={false}
                value={this.state.sourceData.AssessmentTypeId}
                onChange={(e) => { this._saveChanges(e, "AssessmentTypeId") }}
            >
                {this._renderAssessmentTypes()}
            </Select>
        )
    }

    _getAssessmentTypeText = (assessmentTypeId) => {
        if (!this.state.allAssessmentTypes)
            return "unspecified";

        for (let idx = 0; idx < this.state.allAssessmentTypes.length; idx++) {
            if (this.state.allAssessmentTypes[idx].Id === assessmentTypeId)
                return this.state.allAssessmentTypes[idx].Title;
        }

        return "unspecified";
    }

    _renderAssessmentTypes() {
        let arr = [];

        arr.push(<MenuItem key="assessmentTypeEmpty" value=""><em>None</em></MenuItem>);

        if (this.state.allAssessmentTypes) {
            for (let idx = 0; idx < this.state.allAssessmentTypes.length; idx++) {
                var uniqueKey = "assessmentTypeId" + idx.toString();
                arr.push(<MenuItem
                    key={uniqueKey}
                    value={this.state.allAssessmentTypes[idx].Id}>{this.state.allAssessmentTypes[idx].Title}
                </MenuItem>);
            }
        }

        return arr;
    }

    _executePatch(theKey, newValue) {
        this.setState({
            isSaving: true
        });

        return API.patch(`Assessment(${this.state.sourceData.Id})`, 
        {
            [theKey]: newValue
        },
            {
                cancelToken: this.source.token
            });
    }

    _saveChanges(e, attrName) {
        const originalValue = this.state.sourceData[attrName];
        // save a reference to the caller to reset value in case of fail to POST/PATCH
        const orgTarget = e.target;
        let newValue = e.target.value;

        // if the data is the same, don't do anything
        if (this.state.sourceData[attrName] === newValue) {
            orgTarget.value = originalValue;
            return;
        }

        if (newValue === "" && attrName === "AssessmentDueDate")
            newValue = null;

        if (attrName === "Weight") {
            if (newValue > 100 || newValue < 0) {
                this.setState({
                    isSaving: false,
                    isLastSaveOk: false,
                    lastSaveError: "Course % Weight must be between 0 and 100."
                });

                return;
            }
            else
                newValue = parseFloat(newValue);
        }

        if (attrName === "MaxGrade")
            newValue = parseFloat(newValue);

        var that = this;

        // if the current object has an Id (has been created in the database)
        if (this.state.sourceData.Id) {
            this._executePatch(attrName, newValue)
                .then(function (response) {
                    var newData = that.state.sourceData;
                    newData[attrName] = newValue;

                    that.setState({
                        sourceData: newData,
                        isSaving: false,
                        isLastSaveOk: true,
                        lastSaveError: null
                    });
                })
                .catch(function (err) {
                    if (API.isCancel(err))
                        return;

                    // TODO: Alert User save was not good
                    orgTarget.value = originalValue;
                    that.setState({
                        isSaving: false,
                        isLastSaveOk: false,
                        lastSaveError: err.response.data.value ? err.response.data.value : "Error: Unable to update assessment."
                    });
                })
        }
        else {
            // needs to be created
            var od = this.state.sourceData;
            od[attrName] = newValue;

            this.setState({
                sourceData: od
            }, that._executePost);
        }
    }

    /**
     * Remove NULL and UNDEFINED and EMPTY attributes out of the object
     */
    _cleanBeforePost(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
                delete obj[propName];
            }
        }
    }

    _renderStatusBadge = () => {
        if (this.state.isSaving)
            return <span style={{ fontSize: "85%", fontStyle: "italic" }}>saving...</span>

        if (this.state.isLastSaveOk)
            return <span style={{ fontSize: "85%", fontStyle: "italic" }}>All Changes Saved</span>

        return (
            <React.Fragment>
                <ErrorIcon className="ehs-error-icon"></ErrorIcon>
                <span style={{ position: "relative", top: "3px" }}>{this.state.lastSaveError}</span>
            </React.Fragment>);
    }

    _validateDecimal100Max(e) {
        this._validateDecimal(e);

        if (e.target.value > 100.0)
            e.target.value = 100;
        else if (e.target.value < 0)
            e.target.value = 0;
    }

    _validateDecimal(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
    }

    _validateNumeric(e) {
        var parsedValue = e.target.value.replace(/\D/, "");

        if (e.target.value !== parsedValue)
            e.target.value = parsedValue;
    }

    /**
     * Create a new Assessment
     */
    _executePost() {
        var that = this;

        this.setState({
            isSaving: true
        });

        // clean object to remove null and undefined entries
        var objToPost = JSON.parse(JSON.stringify(this.state.sourceData));
        this._cleanBeforePost(objToPost);

        API.post(`Assessment`, objToPost,
            {
                cancelToken: this.source.token
            })
            .then(function (res) {
                that.setState({
                    isSaving: false,
                    isLastSaveOk: true,
                    lastSaveError: null,
                    sourceData: res.data
                }, function () {
                    this.props.history.replace("/instructor/course-details/" + that.state.sourceData.CourseInstanceId + "/assessment/" + that.state.sourceData.Id);
                    this._getAssessment(that.state.sourceData.Id);
                });
            })
            .catch(function (err) {
                if (API.isCancel(err))
                    return;

                // TODO: Alert User save was not good
                that.setState({
                    isSaving: false,
                    isLastSaveOk: false,
                    lastSaveError:
                        err.response &&
                            err.response.data &&
                            err.response.data.value ? err.response.data.value : "Error: Unable to create assessment."
                });
            });
    }

    _showAssessmentInfoDialog(state) {
        this.setState({ showAssessmentInfoDialog: state });
    }

    _renderAssessmentInfo() {
        return (
            <Grid container style={{ width: '85%', margin: '0 auto' }}>
                <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                    <span style={{ fontWeight: 700 }}>Course % Weighting </span>
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                    <span>Percentage of the assessment for the entire course grade.
                        <br /> E.g. A mid-term test that is worth 20% of the final course grade.                        
                    </span>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: 'right' }}>
                    <span style={{ fontWeight: 700 }}>Assessment Max Score</span>
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                    <span>Maximum number of marks for this assessment.</span>
                </Grid>
            </Grid>
        )
    }

    render() {
        if (this.state.isLoading)
            return <WaitSpinner></WaitSpinner>;

        if (this.state.isError)
            return <Redirect to="/" />

        if (this.state.promptConfirmUnlock)
            return <AlertDialog title="Unlock Assessment?" confirmButtonText="Unlock" content="Are you sure you want to unlock this assessment?"
                onOkClose={this._onConfirmLockClose.bind(this, false)} onCancelClose={this._onConfirmLockClose.bind(this, null)}></AlertDialog>

        if (this.state.promptConfirmLock)
            return <AlertDialog title="Lock Assessment?" confirmButtonText="Lock" content="Are you sure you want to lock this assessment? You won't be able to edit it anymore."
                onOkClose={this._onConfirmLockClose.bind(this, true)} onCancelClose={this._onConfirmLockClose.bind(this, null)}></AlertDialog>

        let mainStyle = "m-portlet m-portlet--creative m-portlet--bordered-semi widget-specs m-portlet--first";

        let attachments;

        if (this.state.sourceData.Id && this.state.academicTermId) {
            attachments = <AssessmentAttachments
                assessmentMaxMark={this.state.sourceData.MaxGrade}
                assessmentData={this.state.sourceData}
                assessmentId={this.props.match.params.id1}
                LockToggleIsComplete={this._toggleIsComplete}
                academicTermId={this.state.academicTermId}
                courseInstanceId={this.props.match.params.id}
                programs={this.state.programs}
                academicYearId={this.state.academicYearId}
            />
        }
        else
            attachments = <React.Fragment></React.Fragment>

        var info = <div style={{ visibility: 'hidden' }}></div>;
        if (this.state.showAssessmentInfoDialog)
            info = <InfoDialog show={true} content={this._renderAssessmentInfo()} title="Information" onDialogClose={this._showAssessmentInfoDialog.bind(this, false)}></InfoDialog>

        return (
            <React.Fragment>
                <OneStepBack></OneStepBack>
                <div className="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default full-height">
                    <div className="m-grid m-grid--hor m-grid--root m-page full-height">
                        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
                            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                                <div className="m-content">
                                    <div className="row">
                                        <div className="col-lg-12"></div>
                                        <div className={mainStyle} style={{ width: "100%", marginBottom: 0 }}>
                                            <div className="m-portlet__head">
                                                <div className="m-portlet__head-caption">
                                                    <div className="m-portlet__head-title">
                                                        <h2 className="m-portlet__head-label m-portlet__head-label--warning no-margin-top ink">
                                                            <span className="no-wrap course-title">{this._getTitle()}</span>
                                                        </h2>
                                                        <h3 className="m-portlet__head-text">
                                                            <span>{this._renderStatusBadge()}</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div style={{ position: "relative", top: "-50px" }}><InfoIcon className="help-button" onClick={this._showAssessmentInfoDialog.bind(this, true)}></InfoIcon>
                                                    {info}</div>
                                            </div>
                                            <div className="m-portlet__body">
                                                <Grid container spacing={24}>
                                                    <Grid item xs={12} md={2} xl={1}>
                                                        <span className="ehs-modal-span">Assessment Name:</span>
                                                    </Grid>
                                                    <Grid item xs={12} md={10} xl={11}>
                                                        <TextField
                                                            onBlur={(e) => { this._saveChanges(e, "Title") }}
                                                            style={{
                                                                marginLeft: "theme.spacing.unit",
                                                                marginRight: "theme.spacing.unit",
                                                                marginTop: "0"
                                                            }}
                                                            defaultValue={this.state.sourceData.Title}
                                                            margin="normal"
                                                            fullWidth
                                                            disabled={this.state.sourceData.IsComplete}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2} xl={1}>
                                                        <span className="ehs-modal-span">Assessment Type:</span>
                                                    </Grid>
                                                    <Grid item xs={12} md={10} xl={11}>
                                                        {this._renderAssessmentTypeControl()}
                                                    </Grid>
                                                    <Grid item xs={12} md={2} xl={1}>
                                                        <span className="ehs-modal-span">Assessment Date:</span>
                                                    </Grid>
                                                    <Grid item xs={12} sm={10} xl={11}>
                                                        <TextField
                                                            style={{ width: "100%" }}
                                                            onBlur={(e) => { this._saveChanges(e, "AssessmentDueDate") }}
                                                            type="date"
                                                            defaultValue={this._convertToDate(this.state.sourceData.AssessmentDueDate)}
                                                            className={styles.textField}
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            disabled={this.state.sourceData.IsComplete}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2} xl={1}>
                                                        <span className="ehs-modal-span">Description:</span>
                                                    </Grid>
                                                    <Grid item xs={12} md={10} xl={11}>
                                                        <TextField
                                                            onBlur={(e) => { this._saveChanges(e, "Description") }}
                                                            style={{
                                                                marginLeft: "theme.spacing.unit",
                                                                marginRight: "theme.spacing.unit",
                                                                marginTop: "0"
                                                            }}
                                                            className={this.state.sourceData.IsComplete ? "disabled-text" : ""}
                                                            defaultValue={this.state.sourceData.Description}
                                                            margin="normal"
                                                            fullWidth
                                                            multiline={true}
                                                            disabled={this.state.sourceData.IsComplete}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2} xl={1}>
                                                        <span className="ehs-modal-span">Course % Weighting:</span>
                                                    </Grid>
                                                    <Grid item xs={12} md={10} xl={11}>
                                                        <TextField
                                                            onBlur={(e) => { this._saveChanges(e, "Weight") }}
                                                            onChange={(e) => { this._validateDecimal100Max(e) }}
                                                            style={{
                                                                marginLeft: "theme.spacing.unit",
                                                                marginRight: "theme.spacing.unit",
                                                                marginTop: "0"
                                                            }}
                                                            defaultValue={this.state.sourceData.Weight}
                                                            margin="normal"
                                                            fullWidth
                                                            disabled={this.state.sourceData.IsComplete}
                                                            type="number"
                                                            inputProps={{ min: "0", max: "100", step: "1" }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2} xl={1}>
                                                        <span className="ehs-modal-span">Assessment Max Score:</span>
                                                    </Grid>
                                                    <Grid item xs={12} md={10} xl={11}>
                                                        <TextField
                                                            onBlur={(e) => { this._saveChanges(e, "MaxGrade") }}
                                                            onChange={(e) => { this._validateDecimal(e) }}
                                                            style={{
                                                                marginLeft: "theme.spacing.unit",
                                                                marginRight: "theme.spacing.unit",
                                                                marginTop: "0"
                                                            }}
                                                            defaultValue={this.state.sourceData.MaxGrade}
                                                            margin="normal"
                                                            fullWidth
                                                            disabled={this.state.sourceData.IsComplete}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                        <React.Fragment>
                                            {attachments}
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AssessmentDetails;