import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    root: {
        width: "auto",
    },
    svg: {
        color: "#062F4F !important"
    }
});

class WaitSpinner extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="center-wrapper">
                <CircularProgress
                    classes={{
                        root: classes.root,
                        svg: classes.svg
                    }}
                />
            </div>
        );
    }
}

WaitSpinner.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WaitSpinner);