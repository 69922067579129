import React from "react";
import { withRouter } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox";

import API from "./api";
import WaitSpinner from "./WaitSpinner";


class CredentialList extends React.Component {
    constructor(props) {
        super(props);
        let personIdLoggedIn = localStorage["PersonId"];

        this.state = {
            personId: props.instructorId || personIdLoggedIn,
            credentialList: null,
            credentialText: null,
            showSaveSuccess: false,
            showSaveFailure: false,
            loaded: false
        }

        this._getCredentials = this._getCredentials.bind(this);
    }

    componentDidMount() {
        this._getCredentials();
    }

    render() {
        if (!this.state.loaded) {
            return <WaitSpinner></WaitSpinner>;
        }

        if (!this.state.credentialList) {
            return <React.Fragment></React.Fragment>
        }

        return (<React.Fragment>
            <h4>Credentials</h4>
            <div style={{ padding:"20px" }}>
                <TextField className="no-padding-input"
                    variant="outlined"
                    label="Credential Info"
                multiline={true}
                    rows={5}
                    fullWidth
                    style={{
                        borderWidth: "1px",
                        borderColor: "black"
                    }}
                margin="normal"
                disabled={!this.props.canEdit}
                onBlur={(e) => { this._saveCredentialText(e) }}
                inputProps={{ min: "0", max: "255", step: "1" }}
                defaultValue={this.state.credentialText}
                />
            </div>
            {this._renderCredentials()}
            {this.state.showSaveSuccess ? <h5>Save Successful!</h5> : <h5></h5>}
            {this.state.showSaveFailure ? <h5>There was an error saving.</h5> : <h5></h5>}
        </React.Fragment>);
    }

    _renderCredentials() {
        var toReturn = [];

        (this.state.credentialList).forEach((obj, idx) => {
            toReturn.push(
                <FormControlLabel
                    key={idx}
                    control={
                        <Checkbox
                            key={idx}
                            checked={obj.owned}
                            disabled={!this.props.canEdit}
                            onChange={(sender) => this._updateCredentials(idx, sender.currentTarget.checked)}
                        />
                    }
                    label={obj.name}
                />
            );
        });

        return toReturn;
    }

    _updateCredentials(index, owned) {
        let newItems = [...this.state.credentialList];
        newItems[index].owned = owned;
        this.setState({ credentialList: newItems });
        this._saveCredentials(this.state.personId, newItems[index].id, newItems[index].owned);
    }

    _saveCredentials(personId, credentialId, owned) {
        var that = this;

        var parameters =
        {
            personId: personId,
            credentialId: credentialId,
            owned:owned
        };

        API.post(`Credential/SaveCredential`, parameters)
            .then(function (res) {
                that.setState({
                    showSaveFailure: false,
                    showSaveSuccess: true
                });
            }).catch(function (err) {
                //TODO: Something
                that.setState({
                    showSaveFailure: true,
                    showSaveSuccess: false
                });
            });
    }

    _saveCredentialText(e) {
        var that = this;

        var parameters =
        {
            personId: this.state.personId,
            text: e.target.value
        };

        API.post(`Credential/SaveCredentialText`, parameters)
            .then(function (res) {
                that.setState({
                    showSaveFailure: false,
                    showSaveSuccess: true
                });
            }).catch(function (err) {
                //TODO: Something
                that.setState({
                    showSaveFailure: true,
                    showSaveSuccess: false
                });
            });
    }
    
    _getCredentials() {
        var that = this;
        API.get(`Credential/GetCredentials(personId=${this.state.personId})`)
            .then(res => {
                that.setState({
                    credentialList: res.data.credentialList,
                    credentialText: res.data.credentialText,
                    loaded: true
                });
            })
    }

}

export default withRouter(CredentialList) 