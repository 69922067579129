import React from "react";
import MainLogo from "../img/wideAppLogo.png";
import WaitSpinner from "./Components/WaitSpinner";
import API from "./Components/api";
import { Link } from "react-router-dom";
import "./SignIn.css";

export class SignIn extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            isLoggingIn: false
        }

        this._handlePasswordChange = this._handlePasswordChange.bind(this);
        this._handleUsernameChange = this._handleUsernameChange.bind(this);
    }

    _handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    _handleUsernameChange(event) {
        this.setState({ username: event.target.value });
    }

    _login = (event) => {
        event.preventDefault();
        if (this.state.username.trim() === "" || this.state.password.trim() === "") {
            alert("Please enter your Email and Password.");
            return;
        }

        this.setState({ isLoggingIn: true });

        var that = this;

        API.post(`token`, {
            Email: this.state.username,
            Password: this.state.password
        })
            .then(response => {
                if (response && response.status === 200) {
                    if (that._isUserOnlyIntegrator(response.data)) {
                        alert("Your are not permitted to access Libellum under your current role. Please contact your system administrator.");
                        if (window.location.pathname !== "/")
                            window.location = "/";

                        that.setState({
                            isLoggingIn: false
                        });
                    }
                    else
                        that.props.onLogin(response.data);
                }
                else {
                    alert("Unable to validate username / password.");
                    that.setState({
                        isLoggingIn: false
                    });
                }
            })
            .catch(err => {
                alert("It's a possibility that you are not connected to the internet. Please check your connection and try again.");
                that.setState({
                    isLoggingIn: false
                });
            });
    }

    _isUserOnlyIntegrator(token) {
        var base64Url = token.access_token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var info = JSON.parse(window.atob(base64));

        var allRoles = info["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        return allRoles === "Integrator";
    }

    render() {
        return (
            <form onSubmit={this._login}>
                <div className="m-grid m-grid--hor m-grid--root m-page">
                    <div className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2" id="m_login"
                        style={{ backgroundImage: 'url(https://metronic.blob.core.windows.net/metronic/v5.5.5/theme/default/dist/demo6/assets/app/media/img/bg/bg-3.jpg)' }}>
                        <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
                            <div className="m-login__container">
                                <div className="m-login__logo">
                                    <img style={{ height: "75px" }} alt="" src={MainLogo}></img>
                                </div>
                                <div className="m-login__signin">
                                    <div className="m-login__form m-form">
                                        <div className="form-group m-form__group">
                                            <input readOnly={this.state.isLoggingIn} className="form-control m-input" type="text" placeholder="Email" name="email" autoComplete="off"
                                                onChange={this._handleUsernameChange}></input>
                                        </div>
                                        <div className="form-group m-form__group">
                                            <input readOnly={this.state.isLoggingIn} className="form-control m-input m-login__form-input--last" type="password" placeholder="Password" name="password"
                                                onChange={this._handlePasswordChange}></input>
                                        </div>
                                        <div className="m-login__form-action">
                                            {this.state.isLoggingIn ?
                                                <WaitSpinner></WaitSpinner> :
                                                <input type="submit" id="m_login_signin_submit" className="btn btn-focus m-btn m-btn--pill m-btn--custom m-login__btn m-login__btn--primary"
                                                    value="Sign in" />}
                                        </div>
                                        <div className="m--align-right"><h5><Link to="/ForgotPassword">Forgot Password?</Link></h5></div>
                                        <div style={{ position: "fixed", right: "5px", bottom: "0", fontSize: "10px" }}>1.12.357.1</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}