import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

import API from "../../Components/api";
import StatusDropdown from "./../../Components/StatusDropdown";
import { cancelHttpRequests } from "./../../Helpers/HelperFunctions";

class CourseMasterStatus extends React.Component {
  cancelToken = API.getCancelactionToken();
  source = this.cancelToken.source();

  constructor(props) {
    super(props);

    this.state = {
      requiresSetup: this.props.coursePlan.RequiresSetup ? 1 : 2,
      isAdminVerified: this.props.coursePlan.IsAdminVerified,
    };

    this.setupStates = [
      { Id: 1, Name: "Requires Setup" },
      { Id: 2, Name: "Complete" },
    ];
  }

  componentWillUnmount() {
    cancelHttpRequests(this.source);
  }

  _renderChangeDetectedAlert = () => {
    if (
      this.props.coursePlan.IsAdminVerified ||
      (!this.props.coursePlan.RequiresSetup &&
        !this.props.coursePlan.OutlineChangeSummary)
    )
      return <></>;

    let [backgroundColor, textColor] = ["fairPinkBackground", "oxBloodText"];

    if (!this.props.coursePlan.RequiresSetup)
      [backgroundColor, textColor] = ["beesWaxBackground", "buddhaGold"];

    return (
      <div
        className={`${backgroundColor} margin-bottom-30 margin-top-50 padding-bottom-20`}
        style={{ borderRadius: 10 }}
      >
        <div
          className={`poppinFont font23 bold ${textColor} padding-top-50 margin-bottom-30 center`}
        >
          Changes Detected!
        </div>
        {this.props.coursePlan.RequiresSetup && (
          <div className="margin-bottom-30 center">
            <span
              className={`poppinFont font16 bold ${textColor} margin-right-10 center`}
            >
              Note:
            </span>
            <span className={`poppinFont font16 ${textColor} center`}>
              CLOs have changed from a prior year.
            </span>
          </div>
        )}
        {this.props.coursePlan.OutlineChangeSummary && (
          <div className="margin-bottom-30 center">
            <span
              className={`poppinFont font16 bold ${textColor} margin-right-10 center`}
            >
              Note:
            </span>
            <span
              className={`poppinFont font16 ${textColor} center`}
            >{`${this.props.coursePlan.OutlineChangeSummary}`}</span>
          </div>
        )}
        {!this.props.coursePlan.RequiresSetup ? (
          <div
            className={`poppinFont font16 bold ${textColor} margin-bottom-30 center`}
          >
            <Checkbox
              checked={this.state.isAdminVerified}
              onChange={() => this._acknowledgeChanges()}
              style={{ color: "#ae9d03" }}
            />
            <span>I acknowledge these changes.</span>
          </div>
        ) : (
          <div
            className={`poppinFont font16 ${textColor} margin-bottom-30 center`}
          >
            <span>
              This course <span className="bold">MUST</span> be remapped
            </span>
          </div>
        )}
      </div>
    );
  };

  _onRequireSetupChanged = (requiresSetup) => {
    var that = this;
    var requiresSetupVal = requiresSetup == 1 ? true : false;
    this.setState({ requiresSetup }, () => {
      API.patch(`CoursePlan(${this.props.coursePlan.Id})`,
        {
          RequiresSetup: requiresSetupVal,
        },
        {
          cancelToken: this.source.token,
        }
      )
        .then((res) => {
          that.props.coursePlanUpdated();
        })
        .catch((err) => {
          if (API.isCancel(err)) return;
        });
    });
  };

  _acknowledgeChanges = () => {
    var that = this;

    this.setState({ isAdminVerified: true }, () => {
      API.patch(`CoursePlan(${this.props.coursePlan.Id})`,
        {
          IsAdminVerified: true,
        },
        {
          cancelToken: this.source.token,
        }
      )
        .then((res) => {
          that.props.coursePlanUpdated();
        })
        .catch((err) => {
          if (API.isCancel(err)) return;
        });
    });
  };

  render() {
    let changesDetectedAlert = this._renderChangeDetectedAlert();

    return (
      <React.Fragment>
        <div className="center margin-top-50 margin-bottom-30">
          <StatusDropdown
            title="Setup Status"
            hideNotSpecified={true}
            selectedId={this.state.requiresSetup}
            onSelectionChanged={(sender) => {
              this._onRequireSetupChanged(sender);
            }}
          />
        </div>
        <div className="col-12">{changesDetectedAlert}</div>
      </React.Fragment>
    );
  }
}

export default CourseMasterStatus;
