import React from 'react';
import ReactDOM from 'react-dom';

class WindowPortal extends React.Component {
    constructor(props) {
        super(props);
        this.containerEl = null;
        this.externalWindow = null;
    }
    
    componentDidMount() {
        this.externalWindow = window.open('about:blank', '', 'width=1280,height=800,left=0,top=0');
        this.containerEl = this.externalWindow.document.createElement('div');
        this.externalWindow.document.head.innerHTML = "<link rel='stylesheet' type='text/css' href='" + window.location.origin + "/ReportRow.css'>";
        this.externalWindow.document.body.appendChild(this.containerEl);
        this.externalWindow.addEventListener('beforeunload', () => {
            this.props.closeWindowPortal();
        });
        this.setState(this.state);
    }
    
    componentWillUnmount() {
        this.externalWindow.close();
    }

    render() {
        if (!this.containerEl) 
            return null;

        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }
}

export default WindowPortal;