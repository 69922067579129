import React from "react";
import {
  Person as PersonIcon,
  Edit as EditIcon,
} from "@material-ui/icons";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Redirect } from "react-router";

import API from "./../Components/api";
import EditUserDialog from "./EditUserDialog";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import WaitSpinner from "./../Components/WaitSpinner";

const styles = (theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    maxWidth: "100%",
  },
  inputField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
});

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      firstName: "",
      lastName: "",
      email: "",
      allUserRoles: [],
      createUserRoles: [],
      personNumber: "",
      roleValidationError: false,
      isCreatingUser: false,
      userCreated: false,
      showEditDialog: false,
      userToEdit: null,
      goToProfile: false,
      profilePersonId: null,
      orderBy: "",
      orderDirection: "asc",
    };

    this.TABLE_HEADERS = {
      LastName: "LastName",
      UserRoles: "UserRoles",
      UserStatus: "UserStatus",
      EMail: "EMail"
    };

    this._renderUsersList = this._renderUsersList.bind(this);
    this._renderForm = this._renderForm.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._showDialog = this._showDialog.bind(this);
    this._closeDialog = this._closeDialog.bind(this);
  }

  componentDidMount() {
    this.getUserRolesFromAPI();
    this.getUsersFromAPI();
  }

  getUsersFromAPI() {
    let that = this;
    API.get(`Auth`).then((res) => {
      that.setState({ users: res.data.value });
    });
  }

  getUserRolesFromAPI() {
    let that = this;
    API.get(`Auth/GetAllUserRoles()`).then((res) => {
      that.setState({
        allUserRoles: res.data.value.map((role) => ({
          roleName: role,
          checked: false,
        })),
        createUserRoles: res.data.value.map((role) => ({
          roleName: role,
          checked: false,
        })),
      });
    });
  }

  render() {
    var that = this;

    if (this.state.goToProfile)
      return (
        <Redirect to={"/userprofile/" + this.state.profilePersonId} push />
      );

    if (this.state.users === undefined || this.state.users === null) {
      return (
        <div className="center-wrapper full-height">
          <WaitSpinner />
        </div>
      );
    } else {
      var editUserDialog = null;

      if (this.state.showEditDialog) {
        editUserDialog = (
          <EditUserDialog
            allUserRoles={that.state.allUserRoles}
            open={that.state.showEditDialog}
            onClose={that._closeDialog}
            value={that.state.userToEdit}
          />
        );
      }

      return (
        <React.Fragment>
          <Paper className={styles.root}>
            <div style={{ textAlign: "center", paddingTop: "5px" }}>
              <h4>Add New User</h4>
            </div>
            {this._renderForm()}
          </Paper>
          <Paper className={styles.root} style={{ marginTop: 30 }}>
            <div style={{ textAlign: "center", paddingTop: "5px" }}>
              <h4>Users</h4>
            </div>
            {this._renderUsersList()}
          </Paper>
          {editUserDialog}
        </React.Fragment>
      );
    }
  }

  _handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  _handleRoleChange = (name) => (event) => {
    this.state.createUserRoles.find((x) => x.roleName === name).checked =
      event.target.checked;

    let that = this;
    this.setState(
      {
        createUserRoles: [...this.state.createUserRoles],
      },
      function () {
        that._validateRoles();
      }
    );
  };

  _validateRoles() {
    this.setState({
      roleValidationError: !this.state.createUserRoles.filter((x) => x.checked)
        .length
        ? true
        : false,
    });
  }

  _handleSubmit() {
    if (this.state.roleValidationError) {
      //TODO: show some error to the user
      return;
    }

    this.setState({ isCreatingUser: true });
    var userObject = this._prepareUserForAPI();
    var that = this;

    API.post(`Auth/CreateUser`, { User: userObject })
      .then(function (res) {
        that.setState({
          users: [...that.state.users, res.data],
          userCreated: true,
          firstName: "",
          lastName: "",
          email: "",
          personNumber: "",
        });
      })
      .catch(function (err) {
        alert("There was an error while adding the user.");
      })
      .finally(function () {
        that.setState({ isCreatingUser: false });
      });
  }

  _showDialog(user) {
    //Filter out uneditable roles.
    user.UserRoles = user.UserRoles.filter((role) =>
      this.state.allUserRoles
        .map((allRole) => allRole.roleName)
        .includes(role.RoleName)
    );

    this.setState({
      userToEdit: user,
      showEditDialog: true,
    });
  }

  _closeDialog(userUpdated) {
    this.setState({
      userToEdit: null,
      showEditDialog: false,
    });

    if (userUpdated) this.getUsersFromAPI();
  }

  _goToProfile = (personId) => {
    this.setState({
      goToProfile: true,
      profilePersonId: personId,
    });
  };

  _renderForm() {
    return (
      <ValidatorForm
        className={styles.container}
        style={{ textAlign: "center", paddingBottom: 20 }}
        onSubmit={this._handleSubmit}
      >
        <TextValidator
          id="firstName"
          label="First Name *"
          className={styles.textField}
          value={this.state.firstName}
          style={{ marginLeft: 10 }}
          onChange={this._handleChange("firstName")}
          validators={["required"]}
          errorMessages={["First name is required"]}
        />
        <TextValidator
          id="lastName"
          label="Last Name *"
          className={styles.textField}
          value={this.state.lastName}
          style={{ marginLeft: 10 }}
          onChange={this._handleChange("lastName")}
          validators={["required"]}
          errorMessages={["Last name is required"]}
        />
        <TextValidator
          id="email"
          label="E-Mail *"
          className={styles.textField}
          value={this.state.email}
          style={{ marginLeft: 10 }}
          onChange={this._handleChange("email")}
          validators={["required", "isEmail"]}
          errorMessages={["E-mail is required", "Email is not valid"]}
        />
        <TextValidator
          id="personNumber"
          label="Person Number *"
          className={styles.textField}
          value={this.state.personNumber}
          style={{ marginLeft: 10 }}
          onChange={this._handleChange("personNumber")}
          validators={["required"]}
          errorMessages={["Person Number is required"]}
        />
        <div style={{ textAlign: "center", display: "inline-block" }}>
          {this.state.createUserRoles.map((role) => (
            <FormControlLabel
              key={role.roleName}
              control={
                <Checkbox
                  checked={role.checked}
                  onChange={this._handleRoleChange(role.roleName)}
                  value={role.roleName}
                  style={{
                    color: "#B82601",
                  }}
                />
              }
              label={role.roleName}
              style={{ marginTop: 10, marginLeft: 5 }}
            />
          ))}
          <p
            className="ehs-error"
            hidden={!this.state.roleValidationError}
            style={{ marginTop: "-10px" }}
          >
            You must select at least one role.
          </p>
        </div>
        <div>
          {this.state.isCreatingUser ? (
            <WaitSpinner/>
          ) : (
            <Button
              className="ehs-add-button"
              style={{ marginTop: 10 }}
              type="Submit"
            >
              <span className="nowrap white-text">Create</span>
            </Button>
          )}
          {this.state.userCreated && <h5>User Created!</h5>}
        </div>
      </ValidatorForm>
    );
  }

  _renderUsersList() {
    if (!this.state.users || this.state.users.length > 0) {
      var that = this;

      let sortedUsers = [...this.state.users].sort((a, b) => {
        let aItem = a[this.state.orderBy];
        let bItem = b[this.state.orderBy];

        if (this.state.orderBy === this.TABLE_HEADERS.UserRoles) {
          aItem = that._computeAccountType(aItem, a.IsIntegrator);
          bItem = that._computeAccountType(bItem, b.IsIntegrator);
        }

        if (this.state.orderDirection === "asc") {
          if (aItem > bItem) return 1;
          else if (aItem < bItem) return -1;
        } else {
          if (aItem > bItem) return -1;
          else if (aItem < bItem) return 1;
        }
        return 0;
      });

      return (
        <Table className={styles.table}>
          <TableHead>
            <TableRow selected>
              <TableCell
                sortDirection={
                  this.state.orderBy === this.TABLE_HEADERS.LastName
                    ? this.state.orderDirection
                    : false
                }
              >
                <TableSortLabel
                  active={this.state.orderBy === this.TABLE_HEADERS.LastName}
                  direction={
                    this.state.orderBy === this.TABLE_HEADERS.LastName
                      ? this.state.orderDirection
                      : "asc"
                  }
                  onClick={() => this._sortByClicked(this.TABLE_HEADERS.LastName)}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  this.state.orderBy === this.TABLE_HEADERS.UserRoles
                    ? this.state.orderDirection
                    : false
                }
              >
                <TableSortLabel
                  active={this.state.orderBy === this.TABLE_HEADERS.UserRoles}
                  direction={
                    this.state.orderBy === this.TABLE_HEADERS.UserRoles
                      ? this.state.orderDirection
                      : "asc"
                  }
                  onClick={() => this._sortByClicked(this.TABLE_HEADERS.UserRoles)}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={
                  this.state.orderBy === this.TABLE_HEADERS.UserStatus
                    ? this.state.orderDirection
                    : false
                }
              >
                <TableSortLabel
                  active={this.state.orderBy === this.TABLE_HEADERS.UserStatus}
                  direction={
                    this.state.orderBy === this.TABLE_HEADERS.UserStatus
                      ? this.state.orderDirection
                      : "asc"
                  }
                  onClick={() => this._sortByClicked(this.TABLE_HEADERS.UserStatus)}
                >
                  Status
                </TableSortLabel>
              </TableCell>
              <TableCell
                padding="none"
                className="text-center"
                sortDirection={
                  this.state.orderBy === this.TABLE_HEADERS.EMail
                    ? this.state.orderDirection
                    : false
                }
              >
                <TableSortLabel
                  active={this.state.orderBy === this.TABLE_HEADERS.EMail}
                  direction={
                    this.state.orderBy === this.TABLE_HEADERS.EMail
                      ? this.state.orderDirection
                      : "asc"
                  }
                  onClick={() => this._sortByClicked(this.TABLE_HEADERS.EMail)}
                >
                  E-mail
                </TableSortLabel>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map(function (row, idx) {
              var theKey = "usersList" + idx.toString();

              return (
                <TableRow key={theKey}>
                  <TableCell>{`${row.LastName}, ${row.FirstName}`}</TableCell>
                  <TableCell>
                    {that._computeAccountType(row.UserRoles, row.IsIntegrator)}
                  </TableCell>
                  <TableCell>{row.UserStatus}</TableCell>
                  <TableCell className="text-center">{row.EMail}</TableCell>
                  <TableCell className="icon">
                    <PersonIcon
                      style={{ color: "black" }}
                      onClick={() => that._goToProfile(row.PersonId)}
                    />
                  </TableCell>
                  <TableCell className="icon">
                    <EditIcon
                      style={{ color: "black" }}
                      onClick={() => that._showDialog(row)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    }

    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        No users have been added.
      </div>
    );
  }

  _computeAccountType(userRoles, isIntegrator) {
    let accountType = "";
    if (userRoles)
      accountType = userRoles
        .filter((role) => role.Checked)
        .map((role) => role.RoleName)
        .join(", ");

    if (isIntegrator) {
      if (accountType !== "") accountType += ", ";

      accountType += "Integrator";
    }

    return accountType === "" ? "Unspecified" : accountType;
  }

  _sortByClicked = (name) => {
    let newOrderBy = this.state.orderDirection === "asc" ? "desc" : "asc";
    this.setState({
      orderBy: name,
      orderDirection: newOrderBy,
    });
  };

  _prepareUserForAPI() {
    //Prepare user object
    var user = {
      EMail: this.state.email,
      FirstName: this.state.firstName,
      LastName: this.state.lastName,
      PersonNumber: this.state.personNumber,
    };

    let userRoles = [];

    userRoles = this.state.createUserRoles
      .filter((x) => x.checked)
      .map((role) => {
        return { RoleName: role.roleName, Checked: role.checked };
      });

    user["UserRoles"] = userRoles;

    return user;
  }
}

export default withStyles(styles)(UserManagement);
