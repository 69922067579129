import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  warning: {
    backgroundColor: '#B82601'
  },
  message: {
    color: '#ffffff'
  }
});

class InternetExplorerAlert extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    this._checkBrowser();
  }

  render() {
    const { classes } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes.warning
          }
        }}
        variant="warning"
        message={
          <span id="browser-alert" className={classes.message}>
            For security and stability reasons, Libellum recommends the use of <a href="https://www.google.com/chrome/" target="_blank">Chrome</a>, <a href="https://www.mozilla.org/en-CA/firefox/new/" target="_blank">Firefox</a>, or Safari. Please discontinue use of Internet Explorer.
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this._handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    );
  }

  _handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };

  _checkBrowser = () => {
    if (/MSIE 10/i.test(navigator.userAgent) || //IE 10
      /MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent) || // IE9 & IE11
      /Edge\/\d./i.test(navigator.userAgent)) { // EDGE
      this.setState({ open: true });
    }
  };
}

InternetExplorerAlert.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InternetExplorerAlert);
