import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from '@material-ui/core';

import { CLOParser } from "./../../Components/CLOParser";
import "./CLOListItem.css";

class CLOListItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isSelected: this.props.isSelected
    }
  }

  render() {
    return (
      <div key={this.props.cloId} className="row">
        <div className="clo-checkbox">
          <Checkbox
            checked={this.state.isSelected}
            onChange={(e) => { this._onCLOChanged(e) }}
            value={this.props.cloId.toString()}
            className="checkbox" />
          <span style={{ marginTop: 3 }}>{this.props.cloName}</span>
        </div>
        <div className="outer-box">
          {CLOParser(this.props.cloDescription)}
        </div>
      </div>
    );
  }

  _onCLOChanged = (event) => {
    var isChecked = event.currentTarget.checked;

    if (this.props.onCLOChanged)
      this.props.onCLOChanged(this.props.cloId, isChecked);

    this.setState({ isSelected: isChecked });
  };

}

CLOListItem.propTypes = {
  cloName: PropTypes.string.isRequired,
  cloDescription: PropTypes.string.isRequired,
  cloId: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default CLOListItem;
