import React from "react";
import Button from '@material-ui/core/Button';

import WaitSpinner from "../../Components/WaitSpinner";
import AUSummaryThumbnail from "./AUSummary.png"
import GAIDASummaryThumbnail from "./GAIDASummary.png"
import SpectrumGAThumbnail from "./SpectrumGA.png"
import SpectrumGAIThumbnail from "./SpectrumGAI.png"
import SpectrumGAIDAThumbnail from "./SpectrumGAIDA.png"
import SpectrumGAIIDAThumbnail from "./SpectrumGAIIDA.png"

export class ReportsWrapper extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }

        this.listOfReports = [];
        this.setReportName = this.setReportName.bind(this);
    }

    setReportName(reportName, selectedAcademicYear, selectedProgram) {
        this.props.setReportName(reportName, selectedAcademicYear, selectedProgram);
    }

    componentDidMount() {
        this.listOfReports.push({ Id: 1, Name: "GA Spectrum Map", Thumbnail: SpectrumGAThumbnail });
        this.listOfReports.push({ Id: 2, Name: "GA - IDA Spectrum Map", Thumbnail: SpectrumGAIDAThumbnail });
        this.listOfReports.push({ Id: 3, Name: "GAI Spectrum Map", Thumbnail: SpectrumGAIThumbnail });
        this.listOfReports.push({ Id: 4, Name: "GAI - IDA Spectrum Map", Thumbnail: SpectrumGAIIDAThumbnail });
        this.listOfReports.push({ Id: 5, Name: "AU Summary / Components", Thumbnail: AUSummaryThumbnail });
        this.listOfReports.push({ Id: 6, Name: "GA IDA Summary", Thumbnail: GAIDASummaryThumbnail });

        this.setState({
            isLoading: false
        });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="center-wrapper full-height">
                    <WaitSpinner/>
                </div>
            );
        }

        return (
            <React.Fragment>
                {this._renderReportOptions()}
            </React.Fragment>
        )
    }

    _renderReportOptions = () => {
        return (
            <div className="container no-margin no-padding">
                <div className="row">
                    <div className="col-sm">
                        <div className="ehs-add-button report-button" onClick={(sender) => this._openReport(sender, this.listOfReports[0])}>
                            <Button className="ehs-add-button report-button"><span className="nowrap white-text">{this.listOfReports[0].Name}</span></Button>
                            <img width="100%" src={this.listOfReports[0].Thumbnail} />
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="ehs-add-button report-button" onClick={(sender) => this._openReport(sender, this.listOfReports[1])}>
                            <Button className="ehs-add-button report-button"><span className="nowrap white-text">{this.listOfReports[1].Name}</span></Button>
                            <img width="100%" src={this.listOfReports[1].Thumbnail} />
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="ehs-add-button report-button" onClick={(sender) => this._openReport(sender, this.listOfReports[2])}>
                            <Button className="ehs-add-button report-button"><span className="nowrap white-text">{this.listOfReports[2].Name}</span></Button>
                            <img width="100%" src={this.listOfReports[2].Thumbnail} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="ehs-add-button report-button" onClick={(sender) => this._openReport(sender, this.listOfReports[3])}>
                            <Button className="ehs-add-button report-button"><span className="nowrap white-text">{this.listOfReports[3].Name}</span></Button>
                            <img width="100%" src={this.listOfReports[3].Thumbnail} />
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="ehs-add-button report-button" onClick={(sender) => this._openReport(sender, this.listOfReports[4])}>
                            <Button className="ehs-add-button report-button"><span className="nowrap white-text">{this.listOfReports[4].Name}</span></Button>
                            <img width="100%" src={this.listOfReports[4].Thumbnail} />
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="ehs-add-button report-button" onClick={(sender) => this._openReport(sender, this.listOfReports[5])}>
                            <Button className="ehs-add-button report-button"><span className="nowrap white-text">{this.listOfReports[5].Name}</span></Button>
                            <img width="100%" src={this.listOfReports[5].Thumbnail} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _openReport = (sender, report) => {
        this.setReportName(report.Name, this.props.selectedAcademicYear, this.props.selectedProgram);
    }
}

export default ReportsWrapper;