import React from "react";
import { Chip } from "@material-ui/core";

import "./Libellum.css";

class GAIOccurances extends React.Component {
  constructor(props) {
    super(props);
  }

  _renderGaiOccurances = () => {
    if (!this.props.CLOGaiDataCaptures) return [];

    return this.props.CLOGaiDataCaptures.map((x) => (
        <Chip
          className="cloGaiContentLevel-chip margin-topbottom-5"
          key={`CLO ${x.CourseLearningOutcomeNumber} - ${x.GAINumber} ${x.ContentLevel}`}
          label={`CLO ${x.CourseLearningOutcomeNumber} - ${x.GAINumber} ${x.ContentLevel}`}
        />
    ));
  };

  render() {
    let gaiOccurances = this._renderGaiOccurances();

    return <React.Fragment>{gaiOccurances}</React.Fragment>;
  }
}

export default GAIOccurances;
