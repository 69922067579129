import React from 'react';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const CustomRadioButton = withStyles({
    root: {
      color: '#B82601',
      '&$checked': {
        color: '#B82601',
      },
    },
    checked: {},
  })(props => <Radio color="default" {...props} />);

  export default CustomRadioButton;