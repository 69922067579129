import React from "react";

/**
 * This function splits the CLO Description based on ^ and returns the Outcome as a string and Sub-Outcomes as <ul>
 * @param {string} cloText CLO Description to split into its pieces
 */
export function CLOParser(cloText) {
    function _extractCloOutcome(cloText) {
        if (!cloText)
            return "";

        var splitCloText = cloText.split("^");

        if (splitCloText.length === 1)
            return cloText;

        return splitCloText[0];
    }

    /**
     * Get the CLO Sub-Outcomes as an unordered list
     * @param {string} cloText CLO Description
     */
    function _extractCloSubOutcomes(cloText) {
        if (!cloText)
            return "";
            
        var splitCloText = cloText.split("^");

        if (splitCloText.length < 2)
            return null;

        // remove the first element of array (the outcome as we only care about sub-outcomes)
        splitCloText.shift();

        return <div className="clo-suboutcome"><ul>{splitCloText.map((clo, idx) => <li key={idx}>{clo}</li>)}</ul></div>;
    }

    return (
        <React.Fragment>
            <div className="clo-outcome">{_extractCloOutcome(cloText)}</div>
            {_extractCloSubOutcomes(cloText)}
        </React.Fragment>
    );
}
