import React, { Component } from "react";
import { Redirect } from "react-router";

import GAIOccurances from "./../GAIOccurances";

export class InstructorCourseOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this._openInstructorCourse = this._openInstructorCourse.bind(this);
  }

  _openInstructorCourse = () => {
    this.setState({
      openInstructorCourseDetails: true,
    });
  };

  render() {
    if (this.state.openInstructorCourseDetails) {
      let newUrl =
        "/instructor/course-details/" + this.props.course.CourseInstanceId;
      return <Redirect from="/" to={newUrl} push></Redirect>;
    }

    let mainStyle =
      "m-portlet m-portlet--creative m-portlet--bordered-semi widget-specs pointerCursor";

    if (this.props.isFirst) mainStyle += " m-portlet--first";

    return (
      <div className={mainStyle} onClick={this._openInstructorCourse}>
        <div className="m-portlet__head">
          <div className="m-portlet__head-caption">
            <h2 className="m-portlet__head-label m-portlet__head-label--warning no-margin-top ink">
              <span className="no-wrap course-title">
                {this.props.course.CourseCode} {this.props.course.CourseNumber}{" "}
                - {this.props.course.CourseName}
              </span>
            </h2>
          </div>
        </div>
        <div className="m-portlet__body" style={{ paddingTop: "0" }}>
          <div className="row">
            <div className="col-4">
              <div className="riverBed">
                <span className="poppinFont semibold font14 padding-right-20">
                  {this.props.course.AcademicTerm}
                </span>
                <span className="poppinFont semibold font14">
                  {this.props.course.InstructorName}
                </span>
              </div>
              <div className="riverBed font14 poppinFont padding-top-10">
                Number of times previously taught:{" "}
                <span className="semibold">
                  {this.props.course.TimesTaught}
                </span>
              </div>
              <div className="riverBed font14 poppinFont padding-top-10">
                Passing Grade:{" "}
                <span className="semibold">
                  {this.props.course.PassingGrade}%
                </span>
              </div>
              <div className="riverBed font14 poppinFont padding-top-10">
                Students:{" "}
                <span className="semibold">
                  {this.props.course.TotalStudents}
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="center">
                <span className="poppinFont font14 semibold riverBed">
                  GAIs Completed
                </span>
              </div>
              <div className="padding-top-10 center">
                <span className="poppinFont font19 semibold riverBed center">
                  {this.props.course.TotalAssessments}
                </span>
                <span className="poppinFont font18 riverBed">
                  {" "}
                  Assessment(s)
                </span>
              </div>
              <div className="padding-top-10 center">
                <span className="poppinFont font19 semibold riverBed center">
                  {this.props.course.TotalGAIsAssigned}
                </span>
                <span className="poppinFont font18 riverBed"> GAIs Mapped</span>
              </div>
            </div>
            <div className="col-5">
              <div className="poppinFont font14 semibold center riverBed">
                GAIs Requested
              </div>
              <div className="padding-top-10">
                <GAIOccurances
                  CLOGaiDataCaptures={this.props.course.DataCaptures}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
