import React from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./CourseMasterCloGai.css";
import API from "./../../Components/api";
import { cancelHttpRequests } from "./../../Helpers/HelperFunctions";

class CourseMasterCloGai extends React.Component {
    cancelToken = API.getCancelactionToken();
    source = this.cancelToken.source();

    classes = (theme) => ({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    });

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            allClos: [],
            gaiClo: [],
            gaExpanded: [],
            selectedClo: null,
        };
    }

    componentDidMount() {
        this._setCLOs();
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    _setCLOs = () => {
        var dropDownListOptions = [];

        this.props.CLO.forEach((obj, idx) => {
            dropDownListOptions.push({
                Id: obj.CourseLearningOutcomeId,
                Name: "CLO " + obj.CourseLearningOutcomeNumber,
                Description: obj.CourseLearningOutcomeDescription,
            });
        });

        var selectedCloId = null;

        if (!this.state.selectedClo && this.props.CLO.length > 0) {
            selectedCloId = this.props.CLO[0].CourseLearningOutcomeId;
        }

        this.setState({
            allClos: dropDownListOptions,
            selectedClo: selectedCloId,
            gaiClo: this.props.CLOGaiContentLevels.filter(
                (x) => x.CourseLearningOutcomeId === selectedCloId
            ),
        });
    };

    _patchGaiContentLevel = (isChecked, gaiId, contentLevelId) => {
        var that = this;

        API.patch(`CoursePlanCLOGAIContentLevel(CLOId=${this.state.selectedClo},ContentLevelId=${contentLevelId},CoursePlanId=${this.props.coursePlanId},GAIId=${gaiId})`,
            { cancelToken: that.source.token }
        )
            .then((res) => {
                if (res.status === 200) {
                    // copy current state to new object
                    var newState = Object.assign([], that.state.gaiClo);

                    // adding a new entry
                    if (isChecked) {
                        newState.push({ ContentLevelId: contentLevelId, GAIId: gaiId });

                        that.setState({
                            gaiClo: newState,
                        });
                    } else {
                        var indexToRemoveAt = -1;

                        for (let idx = 0; idx < newState.length; idx++) {
                            var obj = newState[idx];
                            if (
                                obj.GAIId === gaiId &&
                                obj.ContentLevelId === contentLevelId
                            ) {
                                indexToRemoveAt = idx;
                                break;
                            }
                        }

                        if (indexToRemoveAt > -1) {
                            newState.splice(indexToRemoveAt, 1);

                            that.setState({
                                gaiClo: newState,
                            });
                        }
                    }

                    this.props.coursePlanUpdated();
                } else {
                    // TODO: Something here
                    alert("Error: Unable to update GAI / Content Level.");
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    };

    /**
     * Do something on CLO Id changing
     */
    _onCloChanged = (cloId) => {
        if (this.state.selectedClo === cloId) return;

        this.setState({
            selectedClo: cloId,
            gaiClo: this.props.CLOGaiContentLevels.filter(
                (x) => x.CourseLearningOutcomeId === cloId
            ),
        });
    };

    //Track expand state so we don't unnecessarily render thousands of checkboxes that are just hidden in the ExpansionPanel
    _onGaExpand = (gaId, expanded) => {
        let gaExpanded = this.state.gaExpanded;

        if (expanded) {
            if (!gaExpanded.includes(gaId)) {
                gaExpanded.push(gaId);
            }
        } else {
            if (!gaExpanded.includes(gaId)) {
                const index = gaExpanded.indexOf(gaId);
                if (index > -1) {
                    gaExpanded.splice(index, 1);
                }
            }
        }

        this.setState({
            gaExpanded: [...gaExpanded],
        });
    };

    render() {
        if (!this.state.allClos || this.state.allClos.length === 0) {
            return (
                <div className="empty-message">
                    Cannot assign Graduate Attribute Indicators to Course Learning
                    Outcomes because no Course Learning Outcomes have been assigned to
                    this Course Plan.
                </div>
            );
        }

        let selectedCloDescription = "";

        if (this.state.selectedClo)
            selectedCloDescription = this.state.allClos.filter(
                (x) => x.Id === this.state.selectedClo
            )[0].Description;

        return (
            <React.Fragment>
                <div
                    style={{
                        marginLeft: "0",
                        marginRight: "0",
                        marginBottom: "5px",
                        width: "100%",
                    }}
                    className="row"
                >
                    {this._renderCloSelect()}
                </div>
                <div className="margin-bottom-30 margin-top-15">
                    <span className="font16 riverBed poppinFont">
                        Course Learning Outcome:{" "}
                    </span>
                    <span className="font16 riverBed poppinFont bold">
                        {selectedCloDescription}
                    </span>
                </div>
                {this._renderGAs()}
            </React.Fragment>
        );
    }

    /**
     * Render the CLOs in a drop down list
     */
    _renderCloSelect = () => {
        return this.state.allClos.map((x) => {
            let buttonClass =
                this.state.selectedClo === x.Id ? "cloButtonSelected" : "cloButton";

            return (
                <span key={x.Id}>
                    <Button
                        className={"margin-top-15 margin-right-20 " + buttonClass}
                        onClick={() => {
                            this._onCloChanged(x.Id);
                        }}
                        size="small"
                    >
                        {x.Name}
                    </Button>
                    {this.state.selectedClo === x.Id ? <hr className="hrClo" /> : <></>}
                </span>
            );
        });
    };

    _renderGAs = () => {
        let arr = [];

        if (this.props.allGAs.length === 0) return arr;

        if (this.props.allGAs) {
            for (let idx = 0; idx < this.props.allGAs.length; idx++) {
                var theKey = "expPanel" + idx.toString();
                arr.push(
                    <div key={theKey} className="row">
                        {this._renderGAIs(
                            this.props.allGAs[idx].GAIs,
                            this.props.allGAs[idx].Title
                        )}
                        <hr className="hrGai" />{" "}
                    </div>
                );
            }
        }

        return arr;
    };

    _renderGAIs = (gais, gaTitle) => {
        var that = this;

        if (!gais) return <span>No GAIs for this GA</span>;

        return gais.map((gai, idx) => {
            return (
                <div className="col-12" key={gai.Id}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 poppinsFont riverBed font14 bold">
                            {idx == 0 ? gaTitle : ""}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 poppinsFont riverBed font14 ">
                            {`${gai.Number} ${gai.Title}`}
                        </div>
                        <div
                            className="col-xs-12 col-sm-12 col-md-2 col-lg-2 no-margin"
                            style={{ paddingLeft: 0 }}
                        >
                            <div className="row no-margin no-padding">
                                {that._renderContentLevel(gai.Id)}
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 break-word poppinsFont riverBed font14 ">
                            {this._renderDataCapture(gai.Id)}
                        </div>
                    </div>
                </div>
            );
        });
    };

    _patchDataCapture = (gaiId) => {
        var that = this;

        API.patch(`CoursePlanCloGaiDataCapture(CLOId=${this.state.selectedClo},CoursePlanId=${this.props.coursePlanId},GAIId=${gaiId})`,
            { cancelToken: that.source.token }
        )
            .then((res) => {
                if (res.status === 200) {
                    this.props.coursePlanUpdated();
                } else {
                    // TODO: Something here
                    alert("Error: Unable to update Data Capture.");
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    };

    _renderDataCapture = (gaiId) => {
        let isChecked = false;
        if (
            this.props.CLOGaiDataCaptures.find(
                (x) =>
                    x.GAIId === gaiId &&
                    x.CourseLearningOutcomeId === this.state.selectedClo
            )
        )
            isChecked = true;

        //Disable data capture if GAI IDA not selected
        let disable = false;
        if (!this.state.gaiClo.find((x) => x.GAIId == gaiId)) {
            disable = true;
        }

        return (
            <div className=" padding-5px poppinsFont riverBed font14 center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            disabled={disable}
                            onChange={() => this._patchDataCapture(gaiId)}
                        />
                    }
                    label="Request Data Capture"
                />
            </div>
        );
    };

    _renderContentLevel = (gaiId) => {
        var allContentLevels = [];

        allContentLevels.push(
            <div key={"contentLevelLeft"} className="col-2"></div>
        );

        var that = this;
        this.props.allContentLevels.forEach((contentLevel, idx) => {
            let isChecked = false;

            that.state.gaiClo.forEach((obj) => {
                if (
                    obj.GAIId === gaiId &&
                    obj.ContentLevelId === contentLevel.ContentLevelId
                ) {
                    isChecked = true;
                }
            });

            allContentLevels.push(
                <div
                    key={idx}
                    className="col-3 padding-5px poppinsFont riverBed font14 "
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                onChange={(sender) =>
                                    this._patchGaiContentLevel(
                                        sender.currentTarget.checked,
                                        gaiId,
                                        contentLevel.ContentLevelId
                                    )
                                }
                            />
                        }
                        label={contentLevel.Code}
                    />
                </div>
            );
        });

        allContentLevels.push(
            <div key={"contentLevelRight"} className="col-1"></div>
        );
        return allContentLevels;
    };
}

export default CourseMasterCloGai;
