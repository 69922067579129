import React from 'react';
import { Paper } from "@material-ui/core"
import OneStepBack from "./OneStepBack"
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import API from "./Components/api";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 300
      },
      dense: {
        marginTop: 19
      }
  });
  

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailSent: false,
            emailNotSent: false
        }

        this._handleSubmit = this._handleSubmit.bind(this);
    }
    
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <OneStepBack></OneStepBack>
                <Paper style={{ width: "80%", margin: "20px auto", textAlign: "center"}}>
                    <div style={{ paddingTop: 15 }}>
                        <h4>Forgot Password</h4>
                    </div>
                    <ValidatorForm onSubmit={this._handleSubmit}>
                        <div>
                            <TextValidator
                                name="email"
                                label="Email"
                                className={classes.textField}
                                validators={['required', 'isEmail']}
                                errorMessages = { ['This field is required', 'Email is not valid']}
                                value={this.state.email}
                                onChange={this._handleChange('email')}
                            />
                        </div>
                        <div>
                            <Button className="ehs-add-button" style={{ marginTop: 10, marginBottom: 20 }} type="Submit">
                                <span className="nowrap white-text">Submit</span>
                            </Button>
                            {this.state.emailSent && <h5>An email to reset your password has been sent!</h5>}
                            {this.state.emailNotSent && <h5>Invalid email!</h5>}
                        </div>
                    </ValidatorForm>
                </Paper>
            </React.Fragment>
        )
    }

    _handleChange = name => event => {
        this.setState({
            [name]: event.target.value
          });
    }

    _handleSubmit() {
        var that = this;

        var parameters =
        {
            email: this.state.email
        };

        API.post(`Token/ForgotPassword`, parameters)
            .then(function (res) {
                that.setState({
                    email: '',
                    emailSent: true,
                    emailNotSent: false
                });
            }).catch(function (err) {
                that.setState({
                    emailSent: false,
                    emailNotSent: true
                });
            });
    };
}

export default withStyles(styles)(ForgotPassword);