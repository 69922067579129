import React from "react";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        height: '323px'
    },
    table: {
        maxWidth: "100%"
    }
});

class StudentGrid extends React.Component {

    constructor(props) {
        super(props);
        this._rowId = 0;

        this._createData = this._createData.bind(this);
    }

    _createData(FullName, StudentNumber, CurrentPerformanceOverall, CurrentPerformanceByGAI) {
        var id = this._rowId;
        this._rowId += 1;
       
        return { id, FullName, StudentNumber, CurrentPerformanceOverall, CurrentPerformanceByGAI };
    }

    render() {
        var that = this;

        const { classes } = this.props;

        var studentContent;

        if (this.props.students && this.props.students.length > 0) {
            var allStudents = [];

            this.props.students.map(function (row) {
                var modifiedRow = that._createData(row.FullName, row.StudentNumber, row.CurrentPerformanceOverall, row.CurrentPerformanceByGAI);

                var theNewRow =
                    (<TableRow key={modifiedRow.id}>
                        <TableCell>{modifiedRow.FullName}</TableCell>
                        <TableCell align="center">{modifiedRow.StudentNumber}</TableCell>
                        <TableCell align="center">{modifiedRow.CurrentPerformanceOverall}</TableCell>
                        <TableCell align="center">{modifiedRow.CurrentPerformanceByGAI}</TableCell>
                    </TableRow>);

                allStudents.push(theNewRow);
            });

            studentContent = (
                <Table className={classes.table}>
                     <TableHead>
                        <TableRow>
                            <TableCell>Student</TableCell>
                            <TableCell align="center">ID#</TableCell>
                            <TableCell align="center">Course Overall %</TableCell>
                            <TableCell align="center">Course GAI %</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allStudents}
                    </TableBody>
                </Table>
            );
        }
        else
            studentContent = <div className="center-wrapper full-height"><div className="full-width center">No students are registered in this Course.</div></div>

        return (
            <div className="text-center">
                <Paper className={classes.root}>
                    {studentContent}
                </Paper>
            </div>
        )
    }
}

StudentGrid.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StudentGrid);