import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { alpha } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Link } from "react-router-dom";
import LibellumLogo from "./../../img/wideAppLogo.png"
import Avatar from '@material-ui/core/Avatar';
import "./Header.css"
import { Redirect } from 'react-router'
import FeedbackIcon from "@material-ui/icons/Comment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import API from "./../Components/api";

const styles = theme => ({
    root: {
        width: '100%'
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing.unit * 3,
            width: 'auto'
        }
    },
    searchIcon: {
        width: theme.spacing.unit * 9,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputRoot: {
        color: 'inherit',
        width: '100%'
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 10,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        }
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        }
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    }
});

class Header extends React.Component {
    state = {
        anchorEl: null,
        mobileMoreAnchorEl: null,
    };

    constructor(props) {
        super(props);

        this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this);
        this._handleProfileClick = this._handleProfileClick.bind(this);
        this._handlePasswordClick = this._handlePasswordClick.bind(this);
        this._onSubmit = this._onSubmit.bind(this);
        this._onClose = this._onClose.bind(this);
        this._feedbackOnChange = this._feedbackOnChange.bind(this);
    }

    handleProfileMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    _handleProfileClick() {
        if (this.props.goToUserProfile)
            this.props.goToUserProfile();
    }

    _handlePasswordClick() {
        if (this.props.goToPassword)
            this.props.goToPassword();
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
        this.handleMobileMenuClose();
    };

    handleMobileMenuOpen = event => {
        this.setState({ mobileMoreAnchorEl: event.currentTarget });
    };

    handleMobileMenuClose = () => {
        this.setState({ mobileMoreAnchorEl: null });
    };

    _logoutUser = () => {
        localStorage.removeItem("AuthToken");
        localStorage.removeItem("ProfilePicture");
        localStorage.removeItem("academicTermId");
        this.setState({});
    }

    _openFeedbackWindow = () => {
        this.setState({
            openFeedbackModal: true
        })
    }

    _onSubmit = () => {
        if (!this.state.feedbackComments || this.state.feedbackComments === "")
            this._onClose();
        else {
            var parameters =
            {
                feedback: this.state.feedbackComments
            };

            API.post(`AppUserFeedback/SendFeedback`, parameters)
                .then(function (res) {
                    // TODO: Something maybe
                })
                .catch(function (err) {
                    // TODO: Something maybe
                });

            this._onClose();
        }
    }

    _onClose = () => {
        this.setState({
            openFeedbackModal: false
        })
    }

    _feedbackOnChange = name => event => {
        this.setState({ [name]: event.target.value })
    }

    _renderFeedbackWindow = () => {
        return (
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Submit Feedback</DialogTitle>
                <DialogContent>
                    <Input fullWidth
                        multiline
                        onChange={this._feedbackOnChange('feedbackComments')}>
                    </Input>
                </DialogContent>
                <DialogActions>
                    <Button className="confirm embers" onClick={this._onSubmit}>
                        Submit
                        </Button>
                    <Button className="cancel ink" onClick={this._onClose}>
                        Cancel
                        </Button>
                </DialogActions>
            </Dialog>
        );
    }

    _goHome() {
        if (this.props.goHome)
            this.props.goHome();
    }

    _isUserAdmin() {
        try {
            var allRoles = this._getRolesFromToken();

            if (allRoles === null) {
                localStorage.removeItem("RefreshToken");

                if (window.location.pathname !== "/") {
                    window.location = "/";
                }
            }

            var x = "";

            return allRoles.includes("Admin") > -1;
        } catch (error) {
            console.error("error", error);
        }
    }

    _isUserInstructor() {
        var allRoles = this._getRolesFromToken();

        if (allRoles === null) {
            localStorage.removeItem("RefreshToken");

            if (window.location.pathname !== "/") window.location = "/";
        }

        return allRoles.includes("Instructor") > -1;
    }

    _getRolesFromToken() {
        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        if (!authToken) {
            return null;
        } else {
            var base64Url = authToken.split(".")[1];
            var base64 = base64Url.replace("-", "+").replace("_", "/");
            var tokenInfo = JSON.parse(window.atob(base64));
            return tokenInfo[
                "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
            ];
        }
    }

    render() {
        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        if (!authToken)
            return <Redirect to="/"></Redirect>

        const { anchorEl, mobileMoreAnchorEl } = this.state;
        const { classes } = this.props;
        const isMenuOpen = Boolean(anchorEl);
        const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem onClick={this._handleProfileClick}>My Profile</MenuItem>
                <MenuItem onClick={this._handlePasswordClick}>Password</MenuItem>
                <MenuItem onClick={this._logoutUser}>Sign Out</MenuItem>
            </Menu>
        );

        const renderMobileMenu = (
            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
            >
                <MenuItem onClick={this.handleProfileMenuOpen}>
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                    <p>Profile</p>
                </MenuItem>
            </Menu>
        );

        let renderPortalButton = (<div></div>);
        if (window.location.href.match('admin$') && this._isUserInstructor()) {
            renderPortalButton = (
                <Link to="/terms">
                    <Button
                        className="ink top-left-button"
                        size="small"
                    >
                        My Courses
                        </Button>
                </Link>
            );
        }
        else if (window.location.href.match('terms$') && this._isUserAdmin()) {
            renderPortalButton = (
                <Link to="/admin">
                    <Button
                        className="ink top-left-button"
                        size="small"
                    >
                        Admin Portal
                        </Button>
                </Link>
            );
        }

        var profilePic = localStorage.getItem("ProfilePicture");
        var instLogo = localStorage.getItem("InstLogo");
        var feedbackWindow = null;

        if (this.state.openFeedbackModal) {
            feedbackWindow = this._renderFeedbackWindow();
        }

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <img src={instLogo} alt="" style={{ height: "50px", cursor: "pointer"}} onClick={() => { this._goHome() }}></img>
                        <div className={classes.sectionDesktop} style={{ marginLeft: "5pt" }}>
                            <Avatar alt="" src={profilePic} className="avatar" onClick={this.handleProfileMenuOpen} />
                        </div>
                        <div className={classes.sectionMobile} style={{ marginLeft: "5pt" }}>
                            <Avatar alt="" src={profilePic} className="avatar" onClick={this.handleProfileMenuOpen} />
                        </div>
                        {renderPortalButton}
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            {/* Libellum */}
                        </Typography>
                        <div className={classes.grow} />
                        { <div>
                            <FeedbackIcon style={{ color: "black", marginRight: "50px" }} className="actionIcon" onClick={() => this._openFeedbackWindow()}></FeedbackIcon>
                            <h6 style={{ position: "relative", right: "10px", margin: "0px" }}>Feedback</h6>
                        </div>}

                        <img src={LibellumLogo} alt="" style={{ height: "35px" }}></img>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                {renderMobileMenu}
                {feedbackWindow}
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);