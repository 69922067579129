import React from "react";
import { withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import JExcel from 'jexcel';

class Jexcel extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = function () {
        const options = {
            ...this.props.options,
            contextMenu: function (el, x, y, e) {
                var items = [];

                // We have to re-create the context menu, so we're adding in Copy and Paste as those were
                // the original values we wanted to keep.

                // Re-add copy.
                items.push({
                    title: el.options.text.copy,
                    shortcut: 'Ctrl + C',
                    onclick: function () {
                        el.copy(true);
                    }
                });

                // Re-add paste.
                if (navigator && navigator.clipboard) {
                    items.push({
                        title: el.options.text.paste,
                        shortcut: 'Ctrl + V',
                        onclick: function () {
                            if (el.selectedCell) {
                                navigator.clipboard.readText().then(function (text) {
                                    if (text) {
                                        el.paste(el.selectedCell[0], el.selectedCell[1], text);
                                    }
                                });
                            }
                        }
                    });
                }

                // A seperator to make the menu easier to work with.
                items.push({ type: 'line' });

                // The clear cell value is a way for someone to use the context menu to clear a cell.
                items.push({
                    title: 'Clear cell value',
                    onclick: () => {
                        const selectedCells = el.selectedCell;
                        if (selectedCells) {
                            const startRow = selectedCells[0];
                            const startCol = selectedCells[1];
                            const endRow = selectedCells[2];
                            const endCol = selectedCells[3];

                            for (let row = startRow; row <= endRow; row++) {
                                for (let col = startCol; col <= endCol; col++) {
                                    el.setValueFromCoords(row, col, '');
                                }
                            }
                        }
                    }
                });

                return items;
            },
            allowEmpty: true // Allow cells to remain empty
        };

        this.el = JExcel(ReactDOM.findDOMNode(this).children[0], options);

        // Override the 'about' option to prevent 'About' from being added to the context menu.
        this.el.options.about = null;
        this.el.options.allowEmpty = true;
        
        this.el.colgroupContainer.children[0].width = 35;
    }

    render() {
        return (
            <div>
                <div></div><br />
            </div>
        );
    }
}

export default withRouter(Jexcel) 