import React from "react";
import { Paper, Button } from "@material-ui/core"
import { Redirect } from "react-router"

import "./AssessmentMarks.css"

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto"
    },
    table: {
        maxWidth: "100%"
    }
});

class AssessmentMarks extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openMarks: false
        }
    }

    render() {
        if (this.state.openMarks) {            
            return <Redirect to={{
                pathname: "/instructor/course-details/assessment/" + this.props.assessmentId + "/marks",
                state: {                    
                    assessmentMaxMark: this.props.assessmentMaxMark
                }
            }} push></Redirect>
        }

        return (
            <div className="center">
                <Button onClick={this._openMarks} style={{ width: "22%" }} className=" ink">
                    Enter Marks
                </Button>
            </div>
        );
    }

    _openMarks = () => {
        this.setState({ openMarks: true });
    }
}

export default AssessmentMarks;
