import React from "react"
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { withStyles } from "@material-ui/core";
import "./SortBy.css";

const NoHoverButton = withStyles(theme => ({
    root: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      marginLeft: 0,
      marginRight: 0,
      textTransform: 'capitalize'
    },
  }))(Button);

/**
 * Admin Menu: List Courses for a given Term Id
 */
class SortBy extends React.Component {

    inputRefs = [];

    constructor(props) {
        super(props);

        this.state = {
            openCourseId: null,
            sortOptionActive: this.props.sortOptionActive
        }

        this._sortData = this._sortData.bind(this);
        this._renderSortOptions = this._renderSortOptions.bind(this);
    }

    componentDidMount() {
        this._sortData(null, this.props.sortOptionActive)
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ marginBottom: 5 }}>
                    <Typography inline={true}>
                        Sort By:
                    </Typography>
                    {this._renderSortOptions()}
                </div>
            </React.Fragment>
        );
    }

    _renderSortOptions() {
        var allSortOptions = [];

        this.props.allSortOptions.forEach((sortOption, idx) => {
            var sortKey = sortOption["paramNameKey"];
            var sortName = sortOption["paramName"];
            var sortbyButtonClass = classNames({
                'sortByButton': true,
                'active': this.state.sortOptionActive == sortKey,

            });

            allSortOptions.push(
                <NoHoverButton
                    key={idx}
                    size="medium"
                    onClick={(sender) => this._sortData(sender, sortKey)}
                    className={sortbyButtonClass}>
                    {sortName}
                </NoHoverButton>
            );
        });

        return allSortOptions;
    }

    _sortData(sender, sortBy) {
        if (this.props.onSortOrderChanged) {
            this.setState({ sortOptionActive: sortBy });
            this.props.onSortOrderChanged(sortBy);
        }
    }
}

export default SortBy;