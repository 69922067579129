import React from 'react';
import { Paper } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import WaitSpinner from "./Components/WaitSpinner"
import API from "./Components/api";
import OneStepBack from "./OneStepBack"

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
    dense: {
        marginTop: 19,
    }
});

class PasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isUpdatingPassword: false,
            password: "",
            newPassword: "",
            confirmPassword: "",
            userEmail: "",
            passwordChanged: false
        }

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.newPassword)
                return false;
            return true;
        });
        ValidatorForm.addValidationRule('complexity', (value) => {
            if (value == '')
                return true;
            return value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,}$/);
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <OneStepBack></OneStepBack>
                <Paper style={{ width: "80%", margin: "20px auto", textAlign: "center" }}>
                    <div className="col-12" style={{ paddingTop: 15 }}>
                        <h4>Change password</h4>
                    </div>
                    <ValidatorForm onSubmit={this._handleSubmit}>
                        <div className="col-12">
                            <TextValidator
                                name="password"
                                label="Current password"
                                className={classes.textField}
                                type="password"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                value={this.state.password}
                                onChange={this._handleChange('password')}
                            />

                            <TextValidator
                                name="newPassword"
                                label="New password"
                                className={classes.textField}
                                type="password"
                                validators={['required', 'complexity']}
                                errorMessages={['this field is required', 'The password minimum length is 6 characters. It also needs a number, uppercase and lowercase character.']}
                                value={this.state.newPassword}
                                onChange={this._handleChange('newPassword')}
                            />

                            <TextValidator
                                name="confirmPassword"
                                label="Confirm new password"
                                className={classes.textField}
                                type="password"
                                validators={['required', 'isPasswordMatch']}
                                errorMessages={['this field is required', 'Passwords do not match']}
                                value={this.state.confirmPassword}
                                onChange={this._handleChange('confirmPassword')}
                            />
                        </div>
                        <div className="col-12">
                            {this.state.isUpdatingPassword ?
                                <WaitSpinner></WaitSpinner> :
                                <Button className="ehs-add-button" style={{ marginTop: 10, marginBottom: 20 }} type="Submit">
                                    <span className="nowrap white-text">Change</span></Button>}
                            {this.state.passwordChanged && <h5>Password Changed!</h5>}
                        </div>
                    </ValidatorForm>
                </Paper>
            </React.Fragment>
        )
    }

    _handleChange = name => event => {
        this.setState({
            [name]: event.target.value
        }, function () {
            console.log(this.state)
        });
    }

    _handleSubmit() {
        var that = this;

        var passwordObject = {
            'NewPassword': this.state.newPassword,
            "OldPassword": this.state.password
        };

        API.post(`Auth/ChangePassword`, { ChangePassword: passwordObject })
            .then(function (res) {
                that.setState({
                    password: '',
                    confirmPassword: '',
                    newPassword: '',
                    passwordChanged: true
                });
            }).catch(function (err) {
                alert('There was an error changing the password.')
            });
    };
}

export default withStyles(styles)(PasswordPage);