import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import OneStepBack from "./../OneStepBack";
import CourseInstanceDetails from "./Course/CourseInstanceDetails";
import AssessmentInfo from "./Course/AssessmentInfo";
import WaitSpinner from "./../Components/WaitSpinner";
import API from "../Components/api";

import GAIOccurances from "./../GAIOccurances";

const styles = (theme) => ({
  colorSwitchBase: {
    color: "#D7CEC7",
    "&$colorChecked": {
      color: "#B82601",
      "& + $colorBar": {
        backgroundColor: "#062F4F",
      },
    },
  },
  colorBar: {},
  colorChecked: {},
});

class CourseDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passingGrade: 0,
      timesTaught: 0,
      isLoading: true,
      GAIsMappedCount: 0,
      errorMessage: null,
      instructorId: null,
      instructorName: "",
      programs: [],
      academicYearId: 0
    };

    this.timesTaughtInput = React.createRef();
  }

  componentDidMount() {
    this._getInstructorCourse();
  }

  /**
   * Save to API the Passing Grade
   * @param {event} event Event that triggered this function call
   */
  _updatePassingGrade = (event) => {
    const newValue = event.target.value.replace(/\D/, "");
    if (newValue === this.state.passingGrade) return;
    const that = this;
    this._saveInstructorCourse("PassingGrade", parseInt(newValue, 10))
      .then(function (response) {
        that.setState(
          {
            passingGrade: parseInt(newValue, 10),
          },
          function () {
            that.refs.passingGradeRef.value = parseInt(newValue, 10);
          }
        );
      })
      .catch(function (err) {
        alert("Error: Unable to execute request.");
        that.refs.passingGradeRef.value = that.state.passingGrade;
      });
  }

  /**
   * Execute the PATCH against the API
   * @param {string} theKey The key in the JSON object
   * @param {obj} newValue The value in the JSON object
   */
  _saveInstructorCourse = (theKey, newValue) => {
    return API.patch(`CourseInstance(${this.props.match.params.id})`, {
      [theKey]: newValue,
    });
  }

  _saveNumberOfTimesTaught = (e) => {
    var originalTarget = e.target;
    var newValue = e.target.value;

    if (newValue === this.state.timesTaught) return;

    API.post(`Instructor(${this.state.instructorId})/SetTimesTaught`, {
      CourseId: this.state.courseId,
      TimesTaught: newValue,
    })
      .then(
        this._onNumberOfTimeTaughtUpdateOk.bind(this, originalTarget, newValue)
      )
      .catch(this._onNumberOfTimeTaughtUpdateError.bind(this, originalTarget));
  };

  _onNumberOfTimeTaughtUpdateOk = (e, newValue, result) => {
    if (result.status === 200) {
      e.value = newValue;
      this.setState({
        timesTaught: newValue,
      });
    } else {
      e.value = this.state.timesTaught;
      alert("Error: Unable to process your request. Please try again.");
    }
  };

  _onNumberOfTimeTaughtUpdateError = (e, result) => {
    alert("Error: Unable to process your request. Please try again.");
    e.value = this.state.timesTaught;
  };

  _getGAIsMapped(data) {
    let GAIsMappedCount = 0;
    for (let i = 0; i < data.Assessments.length; i++)
      GAIsMappedCount += data.Assessments[i].GAIs.length;
    return GAIsMappedCount;
  }

  /**
   * Get Instructor Course from API
   */
  _getInstructorCourse = () => {
    var that = this;
    API.get(`CourseInstance(${this.props.match.params.id})?$expand=Instructor,Course,AcademicTerm,Assessments($expand=GAIs,AssessmentType),PerformanceDescriptors,Programs`)
      .then(({ status, data }) => {
        if (status === 200 && data && data.Instructor) {
          that.setState({
            courseDetail: data,
            academicYearId: data.AcademicYearId,
            programs: data.Programs,
            passingGrade: data.PassingGrade,
            timesTaught: data.TimesTaught,
            isLoading: false,
            GAIsMappedCount: that._getGAIsMapped(data),
            errorMessage: null,
            courseId: data.Course.Id,
            instructorId: data.Instructor.Id,
            instructorName:
              data.Instructor.FirstName + " " + data.Instructor.LastName,
          });
        } else {
          that.setState({
            isLoading: false,
            errorMessage: "Unable to retrieve course details.",
          });
        }
      })
      .catch((err) => {
        that.setState({
          isLoading: false,
          errorMessage: "Error: Unable to retrieve course details.",
        });
      });
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <div className="full-width center">
          <OneStepBack/>
          {this.state.errorMessage}
        </div>
      );
    }

    if (this.state.isLoading || !this.state.courseDetail)
      return <WaitSpinner></WaitSpinner>;

    let mainStyle =
      "m-portlet m-portlet--creative m-portlet--bordered-semi widget-specs m-portlet--first";

    return (
      <React.Fragment>
        <OneStepBack/>
        <div className="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default full-height">
          <div className="m-grid m-grid--hor m-grid--root m-page full-height">
            <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
              <div className="m-grid__item m-grid__item--fluid m-wrapper">
                <div className="m-content">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={mainStyle}>
                        <div className="m-portlet__head">
                          <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                              <h2 className="m-portlet__head-label m-portlet__head-label--warning no-margin-top ink">
                                <span className="no-wrap course-title">
                                  {this.state.courseDetail.Course.Code}{" "}
                                  {this.state.courseDetail.Course.Number} (
                                  {this.state.courseDetail.Course.Title})
                                </span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="m-portlet__body">
                          <div className="row margin-top--25">
                            <div className="col-4">
                              <div className="riverBed">
                                <span className="poppinFont semibold font14 padding-right-20">
                                  {this.state.courseDetail.AcademicTerm.Name}
                                </span>
                                <span className="poppinFont semibold font14">
                                  {this.state.instructorName}
                                </span>
                              </div>
                              <div className="riverBed font14 poppinFont padding-top-10">
                                <span>Number of times previously taught:</span>
                                <TextField
                                  className="times-taught"
                                  key={new Date().getTime()}
                                  ref={this.timesTaughtInput}
                                  style={{
                                    position: "relative",
                                    width: "60px",
                                    marginRight: "theme.spacing.unit",
                                    marginTop: "0",
                                  }}
                                  onBlur={(e) => {
                                    this._saveNumberOfTimesTaught(e);
                                  }}
                                  fullWidth={false}
                                  defaultValue={this.state.timesTaught}
                                  margin="normal"
                                  type="number"
                                />
                              </div>
                              <div className="riverBed font14 poppinFont padding-top-10">
                                Passing Grade:{" "}
                                <span className="semibold">
                                  {this.state.passingGrade}%
                                </span>
                              </div>
                              <div className="riverBed font14 poppinFont padding-top-10">
                                Students:{" "}
                                <span className="semibold">
                                  {this.state.courseDetail.Students.length}
                                </span>
                              </div>
                              <div className="riverBed font14 poppinFont padding-top-10">
                                Programs:{" "}
                                <span className="semibold">
                                    {this.state.courseDetail.Programs.map(x=>x.Code).join(', ')}
                                </span>
                               </div>
                            </div>
                            <div className="col-3">
                              <div className="center">
                                <span className="poppinFont font14 semibold riverBed">
                                  GAIs Completed
                                </span>
                              </div>
                              <div className="padding-top-10 center">
                                <span className="poppinFont font19 semibold riverBed center">
                                  {this.state.courseDetail.Assessments.length}
                                </span>
                                <span className="poppinFont font18 riverBed">
                                  {" "}
                                  Assessment(s)
                                </span>
                              </div>
                              <div className="padding-top-10 center">
                                <span className="poppinFont font19 semibold riverBed center">
                                  {this.state.GAIsMappedCount}
                                </span>
                                <span className="poppinFont font18 riverBed">
                                  {" "}
                                  GAIs Mapped
                                </span>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="poppinFont font14 semibold center riverBed">
                                GAIs Requested
                              </div>
                              <div className="padding-top-10">
                                <GAIOccurances
                                  CLOGaiDataCaptures={
                                    this.state.courseDetail.DataCaptures
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="padding-top-50">
                            {this.state.courseDetail.Course.Description}
                          </div>
                        </div>
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <CourseInstanceDetails
                          courseInstanceId={
                            this.state.courseDetail.CourseInstanceId
                          }
                          indicators={
                            this.state.courseDetail.PerformanceDescriptors
                          }
                          students={this.state.courseDetail.Students}
                        />
                      </div>
                      <div style={{ marginTop: "5px" }}>
                        <AssessmentInfo
                          courseDetailId={this.props.match.params.id}
                          academicTermId={
                            this.state.courseDetail.AcademicTerm.Id
                          }
                          assessments={this.state.courseDetail.Assessments}
                          programs={this.state.programs}
                          academicYearId={this.state.academicYearId}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CourseDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourseDetails);
