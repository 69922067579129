import React from "react";
import { Redirect } from "react-router";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";

import API from "./../Components/api";
import "./GAISummary.css";
import WaitSpinner from "./../Components/WaitSpinner";
import { cancelHttpRequests } from "./../Helpers/HelperFunctions";

class GAISummary extends React.Component {
    classes = (theme) => ({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
    });

    cancelToken = API.getCancelactionToken();
    source = this.cancelToken.source();

    constructor(props) {
        super(props);

        this.state = {
            allGAs: null,
            allGAICounts: [],
            selectedGAIs: this.props.selectedGAIs,
            termId: this.props.termId,
            gaiCourses: null,
        };

        this._getGAICountForSemester = this._getGAICountForSemester.bind(this);
        this._renderGAIs = this._renderGAIs.bind(this);
        this._getGaiCoursesOk = this._getGaiCoursesOk.bind(this);
    }

    componentDidMount() {
        this._getGAsFromApi();
        this._getGAICountForSemester();
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    /**
     * Catch props updates
     * @param {*} nextProps Next Props
     * @param {*} prevState Previous State
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.termId !== nextProps.termId)
            return { termId: nextProps.termId };

        return null;
    }

    /**
     * Fired when component has updated
     * @param {*} prevProps Previous Props
     * @param {*} prevState Previous State
     */
    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.termId !== prevState.termId ||
            this.props.programId !== prevProps.programId
        ) {
            this._getGAICountForSemester();
            this._getGAsFromApi();
        }

    }

    render() {
        if (this.state.goToCourseInstanceId)
            return (
                <Redirect
                    from="/admin"
                    to={"/instructor/course-details/" + this.state.goToCourseInstanceId}
                    push
                />
            );

        if (this.state.allGAs === undefined || this.state.allGAs === null) {
            return (
                <div className="center-wrapper full-height">
                    <WaitSpinner></WaitSpinner>
                </div>
            );
        }

        if (this.state.allGAs.length === 0)
            return (
                <React.Fragment>
                    <span className="arrowCursor">
                        No Graduate Attributes have been added for this Semester
                    </span>
                </React.Fragment>
            );

        return (
            <React.Fragment>
                {this._renderExpandablePanels()}
                {this._renderDialog()}
            </React.Fragment>
        );
    }

    _renderDialog = () => {
        if (this.state.gaiCourses === null) return null;

        return (
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={true}
                onClose={this._handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    GAI {this.state.selectedGai.Number} Course Occurrences
                </DialogTitle>
                <DialogContent>
                    <div className="row">{this._gaiCoursesToUI()}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this._handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    _handleClose = () => {
        this.setState({
            gaiCourses: null,
            selectedGai: null,
        });
    };

    /**
     * Render the GAIs for the terms as UI elements
     */
    _gaiCoursesToUI = () => {
        var toReturn = [];

        this.state.gaiCourses.map((obj, index) => {
            toReturn.push(
                <div className="col-4" key={index}>
                    <Button
                        className="embers whiteText"
                        onClick={() => this._redirectToCourseInstance(obj.courseInstanceId)}
                    >
                        {obj.courseInstanceName}
                    </Button>
                </div>
            );
        });

        if (toReturn.length === 0)
            return (
                <div key={0} className="col-12">
                    GAI {this.state.selectedGai.Number} is not assigned in this term.
                </div>
            );

        return toReturn;
    };

    _redirectToCourseInstance = (courseInstanceId) => {
        this.setState({ goToCourseInstanceId: courseInstanceId });
    };

    /**
     * Render the GAs in their collapsible panels
     */
    _renderExpandablePanels() {
        let arr = [];

        if (this.state.allGAs) {
            for (let idx = 0; idx < this.state.allGAs.length; idx++) {
                var theKey = "expPanel" + idx.toString();
                arr.push(
                    <ExpansionPanel key={theKey} className="expansionPanelSpacing">
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon className="white" />}
                            className="ink"
                        >
                            <Typography
                                style={{ color: "white" }}
                                className={this.classes.heading}
                            >
                                {this.state.allGAs[idx].Title}
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div className="row full-width">
                                {this._renderGAIs(this.state.allGAs[idx].GAIs)}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            }
        }

        return arr;
    }

    _renderGAIs(gais) {
        var that = this;

        let allGAIs = [];

        if (!gais) return <span>No GAIs for this GA</span>;

        gais.forEach(function (gai) {
            let count = 0;

            for (var idx = 0; idx < that.state.allGAICounts.length; idx++) {
                if (gai.Id === that.state.allGAICounts[idx].GAIId) {
                    count = that.state.allGAICounts[idx].Count;
                    break;
                }
            }

            allGAIs.push(
                <div className="col-12" key={gai.Id}>
                    <div className="col-md-1 col-sm-1 col-xs-1">{gai.Number}</div>
                    <div className="col-md-4 col-xs-3 col-sm-3 break-word">
                        {gai.Title}
                    </div>
                    <div className="col-md-5 col-sm-4 col-xs-5">{gai.Description}</div>
                    <div className="col-md-2 col-xs-1 ga-ocurrences">
                        <Button
                            onClick={() => that._getGaiCourses(gai)}
                            className="embers whiteText"
                        >
                            {count} occurrences
                        </Button>
                    </div>
                </div>
            );
        });

        return allGAIs;
    }

    _getGaiCourses = (gai) => {
        API.get(`AssessmentGai/GaiCourseInstances(gaiId=${gai.Id},semesterId=${this.state.termId},programId=${this.props.programId})`,
            {
                cancelToken: this.source.token,
            }
        )
            .then((result) => this._getGaiCoursesOk(result, gai))
            .catch(this._getGaiCoursesError);
    };

    _getGaiCoursesOk = (result, gai) => {
        if (result.status === 200)
            this.setState({
                gaiCourses: result.data,
                selectedGai: gai,
            });
        else alert("Unable to retrieve GAI course information.");
    };

    _getGaiCoursesError = (err) => {
        if (API.isCancel(err)) return;

        alert("Error: Unable to retrieve GAI course information.");
    };

    /**
     * Get GA and GAI from the API
     */
    _getGAsFromApi() {
        let that = this;

        API.get(`GraduateAttribute/GetList(programId=${this.props.programId},academicYearId=${this.props.selectedAcademicYear})?$expand=GAIs`,
            {
                cancelToken: this.source.token,
            })
            .then((res) => {
                that.setState({
                    allGAs: res.data.value,
                });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    }

    /**
     * Get the GAI counts (how many times each one is offered in a Term)
     */
    _getGAICountForSemester() {
        var that = this;

        API.get(`AcademicTerm/CountsOfGAIsPerTerm(termId=${this.state.termId},programId=${this.props.programId})`,
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                if (res.data.value)
                    that.setState({
                        allGAICounts: res.data.value,
                    });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    }
}

export default GAISummary;
