import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import WaitSpinner from "./../Components/WaitSpinner";

const styles = () => ({
  root: {
    width: "250px",
    borderRadius: "4px",
  },
  selectMenu: {
    color: "white",
    paddingLeft: "10px",
    fontSize: "0.8125rem",
   },
    select: {
        backgroundColor: "#B82601",
    '&:focus': {
        backgroundColor: "#B82601",
    }
  },
  icon: {
    color: "white",
  },
  underline: {
      width: '280px',
    "&:before": {
      borderBottom: "none",
    },
  },
});

class DropDownList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedId: 
        this.props.selectedId ||
        (!this.props.dataSet || this.props.dataSet.length === 0
          ? "-1"
          : this.props.dataSet[0].Id),
      selectedName:
        !this.props.dataSet || this.props.dataSet.length === 0
          ? "--- Not Specified ---"
          : this.props.dataSet[0].Name,
    };

    this._dataToMenuItem = this._dataToMenuItem.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  componentDidUpdate() {
      if (
          this.props.dataSet &&
          this.props.dataSet.length > 0 &&
          (this.state.selectedName === "--- Not Specified ---") 
          && !this.props.selectedId 
      ) {
          this.setState({
              selectedId: this.props.dataSet[0].Id,
              selectedName: this.props.dataSet[0].Name,
          });

          this.props.onSelectionChanged(this.props.dataSet[0].Id);
      }
      else if (this.props.selectedId !== this.state.selectedId) {
          this.setState({
              selectedId: this.props.selectedId
          });
      }
  }

  render() {
    const { classes } = this.props;

    if (this.props.dataSet === null) {
      return (
        <div className="center-wrapper full-height">
          <WaitSpinner></WaitSpinner>
        </div>
      );
    }

    var theTitle = "";

    if (this.props.title !== undefined && this.props.title !== null)
      theTitle = this.props.title.trim() + ": ";

    var theSelectedId = this.props.selectedId
      ? this.props.selectedId
      : this.state.selectedId;

    return (
       <React.Fragment>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: "10px" }}>
                <div>{theTitle}</div>
                <Select
                  classes={{
                    root: classes.root,
                    selectMenu: classes.selectMenu,
                    select: classes.select,
                    icon: classes.icon,
                  }}
                  value={theSelectedId || "-1"}
                  onChange={this._handleChange}
                  input={
                    <Input
                      classes={{
                        underline: classes.underline,
                      }}
                    />
                  }
                >
                  {this._dataToMenuItem()}
                </Select>
            </div>
      </React.Fragment>
    );
  }

  /**
   * Deal with the Session change event
   */
  _handleChange = (event, selectedObj) => {
    this.setState(
      {
        selectedName: selectedObj.props.name,
        selectedId: selectedObj.props.value,
      },
      function () {
        this.props.onSelectionChanged(this.state.selectedId);
      }
    );
  };

  /**
   * Convert the data in the props to MenuItems
   */
  _dataToMenuItem() {
    let toReturn = [];
    // add the empty option
    if (!this.props.hideNotSpecified) {
      toReturn.push(
        <MenuItem key="-1" name="--- Not Specified ---" value="-1">
          <em>--- Not Specified ---</em>
        </MenuItem>
      );
    }

    // add the remaining items
    if (this.props.dataSet != null && this.props.dataSet !== undefined) {
      this.props.dataSet.map((obj) => {
        toReturn.push(
          <MenuItem key={obj.Id} name={obj.Name} value={obj.Id}>
            {obj.Name}
          </MenuItem>
        );
      });
    }
    return toReturn;
  }
}

DropDownList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DropDownList);
