import React from "react";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { AddPhotoAlternate as AddPhotoIcon } from "@material-ui/icons";
import { CloseSharp } from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";

import API from "./../Components/api";
import noImage from "./../../img/no-image.png";
import pdf from "./../../img/pdf.png";
import AlertDialog from "./../AlertDialog";
import WaitSpinner from "./../Components/WaitSpinner";

class AssessmentPhoto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      doneUpload: true,
      showFullImage: false,
      imageSource: this.props.imageSource,
      promptDeleteSample: false,
      attachmentId: this.props.attachmentId,
    };

    this._fileChanged = this._fileChanged.bind(this);
    this._promptDeleteSampleConfirmation =
      this._promptDeleteSampleConfirmation.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageSource != this.props.imageSource)
      this.setState({ imageSource: this.props.imageSource });
    if (prevProps.attachmentId != this.props.attachmentId)
      this.setState({ attachmentId: this.props.attachmentId });
  }

  render() {
    if (!this.state.doneUpload) {
      return (
        <div className="center-wrapper full-height">
          <WaitSpinner></WaitSpinner>
        </div>
      );
    }

    let actionButtons = null;

    if (this.props.showButtons && this.props.showButtons === true) {
      actionButtons = (
        <div className="row no-padding no-margin">
          <div className="col-10 no-padding">
            <AddPhotoIcon
              className="actionIcon"
              onClick={() => this.fileInput.click()}
            />
          </div>
          {this.state.imageSource && (
            <div className="col-2 no-padding" style={{ textAlign: "right" }}>
              <DeleteIcon
                className="actionIcon"
                onClick={this._promptDeleteSampleConfirmation}
              />
            </div>
          )}
        </div>
      );
    }

    if (this.state.promptDeleteSample) {
      return (
        <AlertDialog
          title="Delete Sample?"
          content="Are you sure you want to delete this sample?"
          onOkClose={this._deleteImage}
          onCancelClose={this._hideDeletePrompt}
        />
      );
    }

    return (
      <React.Fragment>
        <div style={{ fontSize: "85%", textAlign: "center" }}>
          {this.props.title}
        </div>
        {actionButtons}
        <input
          type="file"
          ref={(fileInput) => (this.fileInput = fileInput)}
          className="hide"
          onChange={this._fileChanged}
        ></input>
        <div className="center">
          <img
            className="preview"
            src={this._renderImageSource()}
            ref={(imgTag) => (this.imgTag = imgTag)}
            onClick={this._openAttachment}
          ></img>
        </div>
        <Modal open={this.state.showFullImage}>
          <div style={{ width: "100%", height: "100%" }}>
            <div className="fullImageStyle" onClick={this.hideFullImage}>
              <div id="close-image-button">
                <CloseSharp onClick={this.hideFullImage} />
              </div>
              <img src={this._renderImageSource()}></img>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  hideFullImage = (event) => {
    if (event.target.tagName.toLowerCase() === "img") return;

    this.setState({ showFullImage: false });
  };

  _openAttachment = () => {
    if (!this.state.imageSource) return;

    if (this.state.imageSource.toLowerCase().includes(".pdf"))
      window.open(this.state.imageSource, "_blank");
    else this.setState({ showFullImage: true });
  };

  _deleteImage = () => {
    if (this.props.assessmentId) {
      API.delete(`AssessmentMedia(${this.props.assessmentId})?classification=${this.props.typeOfPicture}`)
        .then(this._onDeleteOk)
        .catch(this._onDeleteError)
        .finally(this._hideDeletePrompt);
    } else {
      API.post(`AssessmentGai(${this.props.assessmentDetailsId})/DeleteGaiAttachment`,
        { attachmentId: this.state.attachmentId }
      )
        .then(this._onDeleteOk)
        .catch(this._onDeleteError)
        .finally(this._hideDeletePrompt);
    }
  };

  _onDeleteOk = (res) => {
    if (res.status === 200) {
      if (this.props.onPictureUpdated) this.props.onPictureUpdated(null);

      this.setState({ imageSource: null });
    }
  };

  _onDeleteError = (err) => {
    // TODO: Something better
    alert("Error: Unable to delete attachment.");
  };

  _renderImageSource = () => {
    if (
      this.state.imageSource &&
      this.state.imageSource.includes("/static/media/no-image")
    )
      return;

    if (
      this.state.imageSource &&
      this.state.imageSource.toLowerCase().includes(".pdf")
    )
      return pdf;

    return this.state.imageSource || noImage;
  };

  /**
   * Check to make sure the file size is valid
   * @param {File} fileInstance
   */
  _isFileSizeOk(fileInstance) {
    // if file size exceeds 8 MB (1MB = 125000)
    return fileInstance.size <= 50 * 1024 * 1024;
  }

  _isFileTypeOk(fileInstance) {
    return (
      fileInstance.type === "image/png" ||
      fileInstance.type === "image/jpeg" ||
      fileInstance.type === "application/jpg" ||
      fileInstance.type === "application/pdf"
    );
  }

  _fileChanged = (evt) => {
    if (!this._isFileSizeOk(evt.target.files[0])) {
      alert("File size cannot exceed 50 MB.");
      this.setState({ imageSource: null });
      return;
    }

    if (!this._isFileTypeOk(evt.target.files[0])) {
      alert("File must be a PNG, JPG, or PDF.");
      this.fileInput.value = "";
      this.setState({ imageSource: null });
      return;
    }

    var bodyFormData = new FormData();
      const headers = {
          'Content-Type': 'multipart/form-data'
      };

      if (this.props.assessmentId) {
          bodyFormData.set("assessmentId", this.props.assessmentId);
          bodyFormData.set("classification", this.props.typeOfPicture);
          bodyFormData.append("image", evt.target.files[0]);

          this.setState({ doneUpload: false });

          API.post(`AssessmentMedia`, bodyFormData, {headers: headers})
        .then(this._attachmentUploadOk)
        .catch(this._attachmentUploadError);
    } else {
      bodyFormData.append("image", evt.target.files[0]);
      bodyFormData.append("attachmentType", this.props.typeOfPicture);

      this.setState({ doneUpload: false });
      API.post(`AssessmentGai(${this.props.assessmentDetailsId})/AddGaiAttachment`,
          bodyFormData, { headers: headers }
      ).then(this._attachmentUploadOk)
        .catch(this._attachmentUploadError);
    }
  };

  _attachmentUploadOk = (res) => {
    if (res.status === 200) {
      if (this.props.onPictureUpdated)
        this.props.onPictureUpdated(res.data.value);

      this.setState({
        imageSource: res.data.value || res.data.FileUrl,
        doneUpload: true,
        attachmentId: res.data.Id,
      });
    }
  };

  _attachmentUploadError = (err) => {
    alert("Unable to save attachment.");

    this.setState({
      doneUpload: true,
    });
  };

  _promptDeleteSampleConfirmation() {
    this.setState({ promptDeleteSample: true });
  }

  _hideDeletePrompt = () => {
    this.setState({ promptDeleteSample: false });
  };
}

export default AssessmentPhoto;
