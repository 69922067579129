import React from "react"
import { Redirect } from "react-router"

class Landing extends React.Component {

    render() {
        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        if (!authToken) {
            return <Redirect from="/" to="/terms" push></Redirect>
        }

        var base64Url = authToken.split('.')[1];

        if (!base64Url) {
            return <Redirect from="/" to="/terms" push></Redirect>
        }

        var base64 = base64Url.replace('-', '+').replace('_', '/');
        var info = JSON.parse(window.atob(base64));

        var allRoles = info["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

        if (allRoles.includes("Admin"))
            return <Redirect from="/" to="/admin" push></Redirect>

        return <Redirect from="/" to="/terms" push></Redirect>
    }
}

export default Landing;