import React from "react";
import {
    TextField,
    Button,
    Dialog,
    Grid,
    Select,
    MenuItem,
    DialogActions,
    DialogContent,
    DialogTitle,
    Chip,
    Paper,
} from "@material-ui/core";
import {
    Error as ErrorIcon,
    HelpRounded as InfoIcon,
} from "@material-ui/icons";
import { AddPhotoAlternate as AddPhotoIcon } from "@material-ui/icons";

import "./QuestionEditDialog.css";
import API from "./../../Components/api";
import AssessmentPhoto from "./../../Assessment/AssessmentPhoto";
import InfoDialog from "../../InfoDialog";
import AssessmentDetailsPhoto from "./../../Assessment/AssessmentDetailsPhoto";
import CLOListItem from "./CLOListItem";
import { cancelHttpRequests } from "./../../Helpers/HelperFunctions";

class QuestionEditDialog extends React.Component {
    constructor(props) {
        super(props);
        let srcData = null;

        if (!Object.keys(this.props.sourceData).length) {
            var newQuestion = {
                GAIId: "",
            };

            srcData = newQuestion;
        } else srcData = this.props.sourceData;

        this.selectedCLOIds = [];

        this.cancelToken = API.getCancelactionToken();
        this.source = this.cancelToken.source();

        this.state = {
            isSaving: false,
            isLastSaveOk: null,
            sourceData: srcData,
            selectedGAId: "",
            allGAs: [],
            allGAIs: [],
            allContentLevels: [],
            allAlignments: [],
            allCLOs: [],
            selectedCLOes: [],
            showGAIInfoDialog: false,
            errorFetchingQuestion: false,
        };

        // TODO: Use errorFetchingQuestion to not display the Question at all if there's an error

        this.queue = [];
        this.gaiRef = React.createRef();
        this.questionTextRef = React.createRef();
        this.maxMarkRef = React.createRef();

        this._handleClose = this._handleClose.bind(this);
        this._saveChanges = this._saveChanges.bind(this);
        this._getGAs = this._getGAs.bind(this);
        this._patchOk = this._patchOk.bind(this);
        this._patchException = this._patchException.bind(this);
        this._getAssessmentQuestionDetails =
            this._getAssessmentQuestionDetails.bind(this);
        this._getAllContentLevels = this._getAllContentLevels.bind(this);
        this._getAllAlignments = this._getAllAlignments.bind(this);
        this._processSavedQuestion = this._processSavedQuestion.bind(this);
        this._validateRequiredFields = this._validateRequiredFields.bind(this);
        this._showGAIInfoDialog = this._showGAIInfoDialog.bind(this);
        this._removePicture = this._removePicture.bind(this);
        this._gaiAttachmentUploadOk = this._gaiAttachmentUploadOk.bind(this);
        this._processQueue = this._processQueue.bind(this);
        this._getAllCourseCLOs = this._getAllCourseCLOs.bind(this);
        this._onCLOChanged = this._onCLOChanged.bind(this);
    }

    componentDidMount() {
        // TODO: Group these into 1 response
        this._getGAs();
        this._getAllContentLevels();
        this._getAllAlignments();
        this._getAllCourseCLOs();

        if (this.state.sourceData.AssessmentQuestionId)
            this._getAssessmentQuestionDetails();
    }

    componentDidUpdate() {
        if (!this.state.showGAIInfoDialog) this._validateRequiredFields();
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    render() {
        var currentBadge = this._renderStatusBadge();
        var info = <div style={{ visibility: "hidden" }}></div>;
        if (this.state.showGAIInfoDialog)
            info = (
                <InfoDialog
                    show={true}
                    content={this._renderGAIInfo()}
                    title="Information"
                    onDialogClose={this._showGAIInfoDialog.bind(this, false)}
                />
            );

        let cloDialog = null;
        if (this.state.showCLODialog) {
            cloDialog = (
                <Dialog
                    fullWidth={false}
                    maxWidth="md"
                    open={true}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title">
                        Course Learning Outcome Selection
                    </DialogTitle>
                    <DialogContent>
                        <div>{this._renderCLOList()}</div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this._closeCLODialog();
                            }}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return (
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                open={true}
                onClose={this._handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {this._renderModalTitle()}
                    <InfoIcon
                        className="help-button"
                        onClick={this._showGAIInfoDialog.bind(this, true)}
                    />
                    {info}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={2}>
                            <span className="ehs-modal-span">GA: *</span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={this._setGA}
                                autoWidth={false}
                                disabled={this.props.isDisabled}
                                value={this.state.selectedGAId}
                            >
                                {this._renderGAs()}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span ref={this.gaiRef} className="ehs-modal-span">
                                GAI: *
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    this._saveChanges(e, "GAIId");
                                }}
                                disabled={this.props.isDisabled}
                                autoWidth={false}
                                value={this.state.sourceData.GAIId}
                            >
                                {this._renderGAIs()}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span ref={this.questionTextRef} className="ehs-modal-span">
                                Question Text: *
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                onBlur={(e) => {
                                    this._saveChanges(e, "QuestionText");
                                }}
                                disabled={this.props.isDisabled}
                                multiline={true}
                                rowsMax={5}
                                style={{
                                    marginLeft: "theme.spacing.unit",
                                    marginRight: "theme.spacing.unit",
                                    marginTop: "0",
                                }}
                                defaultValue={this.state.sourceData.QuestionText}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span ref={this.maxMarkRef} className="ehs-modal-span">
                                Max Mark: *
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                onBlur={(e) => {
                                    this._saveChanges(e, "MarkedOutOf");
                                }}
                                disabled={this.props.isDisabled}
                                onChange={(e) => {
                                    this._validateDecimal(e);
                                }}
                                style={{
                                    marginLeft: "theme.spacing.unit",
                                    marginRight: "theme.spacing.unit",
                                    marginTop: "0",
                                }}
                                defaultValue={this.state.sourceData.MarkedOutOf}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span className="ehs-modal-span">Content Level:</span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    this._saveChanges(e, "ContentLevelId");
                                }}
                                autoWidth={false}
                                value={this.state.sourceData.ContentLevelId}
                                disabled={
                                    this.state.sourceData.AssessmentQuestionId === undefined ||
                                    this.props.isDisabled
                                }
                            >
                                {this._renderContentLevels()}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span className="ehs-modal-span">Alignment:</span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    this._saveChanges(e, "AlignmentId");
                                }}
                                autoWidth={false}
                                value={this.state.sourceData.AlignmentId}
                                disabled={
                                    this.state.sourceData.AssessmentQuestionId === undefined ||
                                    this.props.isDisabled
                                }
                            >
                                {this._renderAlignments()}
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span className="ehs-modal-span">CLO:</span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            {this._renderSelectedCLOs()}
                            {cloDialog}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <span className="ehs-modal-span">Comments / Notes:</span>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                placeholder="Notes or comments about this GAI"
                                multiline={true}
                                rows={1}
                                onBlur={(e) => {
                                    this._saveChanges(e, "Comment");
                                }}
                                defaultValue={this.state.sourceData.Comment}
                                rowsMax={5}
                                fullWidth
                                disabled={
                                    this.state.sourceData.AssessmentQuestionId === undefined ||
                                    this.props.isDisabled
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} className="attachments-row">
                            <span className="ehs-modal-span">Attachments:</span>
                        </Grid>
                        <Grid item xs={12} sm={10} className="attachments-row">
                            <div className="row">
                                <div className="col-1" id="attachments-label">
                                    {!this.props.isDisabled && (
                                        <AddPhotoIcon
                                            className="actionIcon"
                                            style={{ width: "50px", height: "50px" }}
                                            onClick={() => this.fileInput.click()}
                                        />
                                    )}
                                </div>
                                <input
                                    type="file"
                                    ref={(fileInput) => (this.fileInput = fileInput)}
                                    className="hide"
                                    onChange={this._fileChanged}
                                    disabled={
                                        this.state.sourceData.AssessmentQuestionId === undefined ||
                                        this.props.isDisabled
                                    }
                                />
                                <div className="col-11" id="attachments-box">
                                    <div className="row">{this._renderAttachments()}</div>
                                </div>
                            </div>
                        </Grid>
                        {this.state.sourceData.AssessmentQuestionId !== undefined &&
                            !this.props.isDisabled &&
                            this._renderStudentSamples()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <span style={{ float: "left", width: "100%" }}>{currentBadge}</span>
                    <Button
                        onClick={() => {
                            this._handleClose();
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    _renderStudentSamples = () => {
        var attachments = this.state.sourceData.Attachments;
        var keyAttachment =
            attachments &&
            attachments.find(
                (x) => x.FileType && x.FileType.toLowerCase() === "Key".toLowerCase()
            );
        var lowAttachment =
            attachments &&
            attachments.find(
                (x) => x.FileType && x.FileType.toLowerCase() === "Low".toLowerCase()
            );
        var medAttachment =
            attachments &&
            attachments.find(
                (x) => x.FileType && x.FileType.toLowerCase() === "Medium".toLowerCase()
            );
        var highAttachment =
            attachments &&
            attachments.find(
                (x) => x.FileType && x.FileType.toLowerCase() === "High".toLowerCase()
            );

        return (
            <div className="bottom-box" id="samples-of-student-work">
                <Paper style={{ padding: 5 }}>
                    Samples of Student Work
                    <Grid
                        container
                        spacing={24}
                        style={{ padding: 15, textAlign: "left" }}
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Paper>
                                        <AssessmentPhoto
                                            showButtons={!this.props.isDisabled}
                                            title="Low"
                                            attachmentId={lowAttachment ? lowAttachment.Id : null}
                                            assessmentDetailsId={
                                                this.state.sourceData.AssessmentQuestionId
                                            }
                                            typeOfPicture="Low"
                                            imageSource={lowAttachment ? lowAttachment.FileUrl : null}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Paper>
                                        <AssessmentPhoto
                                            showButtons={!this.props.isDisabled}
                                            title="Med"
                                            attachmentId={medAttachment ? medAttachment.Id : null}
                                            assessmentDetailsId={
                                                this.state.sourceData.AssessmentQuestionId
                                            }
                                            typeOfPicture="Medium"
                                            imageSource={medAttachment ? medAttachment.FileUrl : null}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Paper>
                                        <AssessmentPhoto
                                            showButtons={!this.props.isDisabled}
                                            title="High"
                                            attachmentId={highAttachment ? highAttachment.Id : null}
                                            assessmentDetailsId={
                                                this.state.sourceData.AssessmentQuestionId
                                            }
                                            typeOfPicture="High"
                                            imageSource={
                                                highAttachment ? highAttachment.FileUrl : null
                                            }
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Paper>
                                        <AssessmentPhoto
                                            showButtons={!this.props.isDisabled}
                                            title="Key"
                                            attachmentId={keyAttachment ? keyAttachment.Id : null}
                                            assessmentDetailsId={
                                                this.state.sourceData.AssessmentQuestionId
                                            }
                                            typeOfPicture="Key"
                                            imageSource={keyAttachment ? keyAttachment.FileUrl : null}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    };

    _renderSelectedCLOs = () => {
        if (!this.state.sourceData.CourseLearningOutcomes) {
            return (
                <div className="clos-selected-field">
                    <Chip
                        className="clo-chip"
                        label="Please specify the GA, GAI and Question Text before adding CLOs."
                    />
                </div>
            );
        }

        if (
            !this.state.sourceData.CourseLearningOutcomes ||
            this.state.sourceData.CourseLearningOutcomes.length === 0
        ) {
            return (
                <div className="clos-selected-field" onClick={this._openCLODialog}>
                    <Chip className="clo-chip" label="Select CLO" />
                </div>
            );
        }

        var clos = this.state.sourceData.CourseLearningOutcomes.map((clo) => {
            let idx = this.state.allCLOs.findIndex(
                (x) => x.CourseLearningOutcomeId === clo
            );
            if (idx > -1) {
                return (
                    <Chip
                        className="clo-chip"
                        key={this.state.allCLOs[idx].CourseLearningOutcomeId}
                        label={`CLO ${this.state.allCLOs[idx].CourseLearningOutcomeNumber}`}
                    />
                );
            }
        });

        return (
            <div className="clos-selected-field" onClick={this._openCLODialog}>
                {clos}
            </div>
        );
    };

    _closeCLODialog = () => {
        this.setState({
            showCLODialog: false,
        });

        var newEvent = { target: { value: this.selectedCLOIds } };

        this._saveChanges(newEvent, "CourseLearningOutcomes");
    };

    _openCLODialog = () => {
        if (this.props.isDisabled) return;

        this.setState({
            showCLODialog: true,
        });
    };

    _isFileSizeOk(fileInstance) {
        // if file size exceeds 50 MB (1MB = 125000)
        return fileInstance.size <= 50 * 1024 * 1024;
    }

    _isFileTypeOk(fileInstance) {
        return (
            fileInstance.type === "image/png" ||
            fileInstance.type === "image/jpeg" ||
            fileInstance.type === "application/jpg" ||
            fileInstance.type === "application/pdf"
        );
    }

    _fileChanged = (evt) => {
        if (!this._isFileSizeOk(evt.target.files[0])) {
            alert("File size cannot exceed 50 MB.");
            this.setState({ imageSource: null });
            return;
        }

        if (!this._isFileTypeOk(evt.target.files[0])) {
            alert("File must be a PNG, JPG, or PDF.");
            this.fileInput.value = "";
            this.setState({ imageSource: null });
            return;
        }

        var that = this;
        var bodyFormData = new FormData();

        bodyFormData.append("image", evt.target.files[0]);


        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        var cfg = {
            onUploadProgress: function (prog) {
                that.setState({
                    uploadPercent: Math.round((prog.loaded / prog.total) * 100) + "%",
                });
            },
            cancelToken: this.source.token,
            headers: headers
        };

        API.post(`AssessmentGai(${this.state.sourceData.AssessmentQuestionId})/AddGaiAttachment`,
            bodyFormData, cfg
        )
            .then(this._gaiAttachmentUploadOk)
            .catch(this._gaiAttachmentUploadError);
    };

    _gaiAttachmentUploadOk = (res) => {
        if (res.status === 200) {
            let newSourceData = Object.assign({}, this.state.sourceData);
            newSourceData.Attachments.push({
                FileUrl: res.data.FileUrl,
                Id: res.data.Id,
                FileType: res.data.FileType,
            });
            this.setState({ sourceData: newSourceData });
        }
    };

    _gaiAttachmentUploadError = (err) => {
        alert("Unable to upload GAI attachment. Please try again.");
    };

    _renderAttachments = () => {
        if (!this.state.sourceData.Attachments) return null;

        var toReturn = [];

        toReturn = this.state.sourceData.Attachments.filter(
            (x) => x.FileType === null
        ).map((attachment, idx) => {
            return (
                <div key={idx} className="col-2">
                    <AssessmentDetailsPhoto
                        showButtons={!this.props.isDisabled}
                        title=""
                        assessmentDetailsId={this.state.sourceData.AssessmentQuestionId}
                        onPictureRemoved={this._removePicture}
                        fileId={attachment.Id}
                        imageSource={attachment.FileUrl}
                    />
                </div>
            );
        });

        if (toReturn.length === 0)
            return (
                <span style={{ margin: "7px", marginLeft: "15px" }}>
                    No attachments have been added.
                </span>
            );

        return toReturn;
    };

    _renderCLOList() {
        let CLOs = this.state.allCLOs.map((clo) => {
            return (
                <CLOListItem
                    cloId={clo.CourseLearningOutcomeId}
                    cloName={`CLO ${clo.CourseLearningOutcomeNumber}`}
                    cloDescription={clo.CourseLearningOutcomeDescription}
                    isSelected={this._isCLOSelected(clo.CourseLearningOutcomeId)}
                    key={clo.CourseLearningOutcomeId}
                    onCLOChanged={this._onCLOChanged}
                />
            );
        });

        return <div>{CLOs}</div>;
    }

    _isCLOSelected = (cloId) => {
        return (
            this.state.sourceData.CourseLearningOutcomes &&
            this.state.sourceData.CourseLearningOutcomes.indexOf(cloId) > -1
        );
    };

    _onCLOChanged = (cloId, isChecked) => {
        if (isChecked) {
            this.selectedCLOIds.push(cloId);
        } else {
            var indexOfCloId = this.selectedCLOIds.indexOf(cloId);
            this.selectedCLOIds.splice(indexOfCloId, 1);
        }
    };

    /**
     * Remove picture from state
     */
    _removePicture = (fileId) => {
        for (let idx = 0; idx < this.state.sourceData.Attachments.length; idx++) {
            if (this.state.sourceData.Attachments[idx].Id === fileId) {
                let newUserData = Object.assign({}, this.state.sourceData);
                newUserData.Attachments.splice(idx, 1);
                this.setState({ sourceData: newUserData });
            }
        }
    };

    _getAllContentLevels() {
        var that = this;

        API.get(`ContentLevel`, {
            cancelToken: this.source.token,
        })
            .then((res) => {
                that.setState({
                    allContentLevels: res.data.value,
                });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    }

    _getAllAlignments() {
        var that = this;

        API.get(`Alignment`, {
            cancelToken: this.source.token,
        })
            .then((res) => {
                that.setState({
                    allAlignments: res.data.value,
                });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    }

    _getAssessmentQuestionDetails() {
        var that = this;

        API.get(`AssessmentQuestion(${this.props.sourceData.AssessmentQuestionId})?$expand=Attachments,CourseLearningOutcomes`,
            {
                cancelToken: this.source.token,
            }
        )
            .then((res) => {
                that.selectedCLOIds = [...res.data.CourseLearningOutcomes];

                that.setState({
                    sourceData: res.data,
                    errorFetchingQuestion: false,
                });
            })
            .catch((err) => {
                that.setState({ errorFetchingQuestion: true });

                if (API.isCancel(err)) return;
            });
    }

    /**
     * Get GA and GAI from the API
     */
    _getGAs() {
        let that = this;

        let programId = this.props.programs[0].Id;

        API.get(`GraduateAttribute/GetList(programId=${programId},academicYearId=${this.props.academicYearId})?$expand=GAIs`, {
            cancelToken: this.source.token,
        })
            .then((res) => {
                var newState = { allGAs: res.data.value };

                if (that.state.sourceData.GAIId) {
                    for (let idx = 0; idx < res.data.value.length; idx++) {
                        var gaObj = res.data.value[idx];
                        for (let idx1 = 0; idx1 < gaObj.GAIs.length; idx1++) {
                            if (gaObj.GAIs[idx1].Id === that.state.sourceData.GAIId) {
                                newState["selectedGAId"] = gaObj.Id;
                                newState["allGAIs"] = gaObj.GAIs;
                                break;
                            }
                        }
                    }
                }

                that.setState(newState);
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    }

    /**
     * Catch close event
     */
    _handleClose = () => {
        if (!this.state.isSaving) {
            if (this.props.onClose) this.props.onClose();
        }
    };

    /**
     * Patch the value to API
     * @param {string} theKey The attribute to patch
     * @param {*} newValue The value to set to
     */
    _executePatch(theKey, newValue) {
        this.setState({
            isSaving: true,
        });

        if (theKey === "Weight") newValue = parseFloat(newValue);

        return API.patch(`AssessmentQuestion(${this.state.sourceData.AssessmentQuestionId})`,
            {
                [theKey]: newValue,
            },
            {
                cancelToken: this.source.token,
            }
        );
    }

    /**
     * Validate required fields for POSTing / PATCHing
     */
    _validateRequiredFields() {
        var isGaiValid = this._validateRef(
            this.state.sourceData.GAIId,
            this.gaiRef.current
        );
        var isMarkedOutOfValid = this._validateRef(
            this.state.sourceData.MarkedOutOf,
            this.maxMarkRef.current
        );
        var isQuestionValid = this._validateRef(
            this.state.sourceData.QuestionText,
            this.questionTextRef.current
        );

        return isGaiValid && isMarkedOutOfValid && isQuestionValid;
    }

    _validateRef(sourceDataAttr, refAttr) {
        if (!sourceDataAttr) {
            if (refAttr.className.indexOf("errorText") < 0)
                refAttr.className = refAttr.className + " errorText";

            return false;
        } else
            refAttr.className = refAttr.className
                .replace(" errorText", "")
                .replace("errorText", "")
                .trim();

        return true;
    }

    /**
     * Save the changed to the API
     * @param {event} e Event that caused the function call
     * @param {string} attrName Attribute that changed
     */
    _saveChanges(e, attrName) {
        // get the new value
        let orgTarget = e.target;
        let newValue = e.target.value;
        var request; //request to be added to the queue.

        if (attrName === "MarkedOutOf") newValue = parseFloat(newValue);

        if (
            attrName === "CourseLearningOutcomes" &&
            this._CLOsHaveNotChanged(this.state.sourceData[attrName], newValue)
        )
            return;

        // if the data is the same, don't do anything
        if (this.state.sourceData[attrName] === newValue) {
            e.target.value = this.state.sourceData[attrName];
            return;
        }

        if (this.state.isSaving) {
            //Saving hasn't finished
            request = { reqType: "PATCH", attrName, newValue, orgTarget };
            if (!this._requestIsInQueue(request)) this.queue.push(request);
        } else {
            if (this.state.sourceData.AssessmentQuestionId) {
                //Saving has finished and GAI exists so do an update.
                request = { reqType: "PATCH", attrName, newValue, orgTarget };

                if (!this._requestIsInQueue(request)) {
                    this.queue.push(request);
                    this._processQueue();
                }
            } else {
                //It's not saving and GAI does not exist so create it.
                var od = this.state.sourceData;
                od[attrName] = newValue;
                this.setState({ sourceData: od }, function () {
                    if (this._validateRequiredFields()) {
                        request = { reqType: "POST", od };
                        this.queue.push(request);
                        this._processQueue();
                    }
                });
            }
        }
    }

    /**
     * Patch threw an exception
     * @param {*} response API Response
     * @param {*} ctrl Control to update
     */
    _patchException(err, attrName, orgTarget) {
        if (API.isCancel(err)) return;

        // if CourseLearningOutcomes fails to update, then don't do anything because we are not working with state.
        if (attrName === "CourseLearningOutcomes") return;

        // TODO: Alert User save was not good
        orgTarget.value = this.state.sourceData[attrName];

        this.setState(
            {
                isSaving: false,
                isLastSaveOk: false,
                lastSaveError: err.response.data.value
                    ? err.response.data.value
                    : "Error: Unable to update question.",
            },
            function () {
                this.queue.shift();
                this._processQueue();
            }
        );
    }

    _processQueue() {
        var that = this;

        if (this.queue.length <= 0) return;

        var req = this.queue[0];
        if (req.reqType === "POST") {
            this.setState(
                {
                    sourceData: req.od,
                },
                that._executePost
            );
        } else if (req.reqType === "PATCH") {
            this._executePatch(req.attrName, req.newValue)
                .then((response) => {
                    this._patchOk(response, req);
                })
                .catch((err) => {
                    this._patchException(err, req.attrName, req.orgTarget);
                });
        }
    }

    _getAllCourseCLOs() {
        var that = this;

        API.get(`CourseInstance/GetCourseCLOs(courseInstanceId=${this.props.courseInstanceId},academicTermId=${this.props.academicTermId})`,
            {
                cancelToken: this.source.token,
            }
        )
            .then(({ data }) => {
                that.setState({
                    allCLOs: data.value,
                });
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
            });
    }

    _requestIsInQueue(request) {
        var lookForObject = (obj) => {
            return (
                obj.attrName == request.attrName &&
                obj.newValue == request.newValue &&
                obj.orgTarget == request.orgTarget &&
                obj.reqType == request.reqType
            );
        };

        return this.queue.some(lookForObject);
    }
    /**
     * Patch Ok
     * @param {*} response API Response
     */
    _patchOk(response, req) {
        var that = this;
        let newValue =
            req.attrName === "CourseLearningOutcomes"
                ? req.newValue
                : req.orgTarget.value;
        const originalValue = this.state.sourceData[req.attrName];

        if (response.status === 200) {
            var newData = that.state.sourceData;
            newData[req.attrName] = newValue;
            that.setState(
                {
                    sourceData: newData,
                    isSaving: false,
                    isLastSaveOk: true,
                    lastSaveError: null,
                },
                function () {
                    that.queue.shift();
                    that._processQueue();
                }
            );
        } else {
            req.orgTarget.value = originalValue;
            that.setState({
                isSaving: false,
                isLastSaveOk: false,
                lastSaveError: response.data,
            });

            that._processQueue();
        }
    }

    /**
     * Remove NULL and UNDEFINED and EMPTY attributes out of the object
     */
    _cleanBeforePost(obj) {
        for (var propName in obj) {
            if (
                obj[propName] === null ||
                obj[propName] === undefined ||
                obj[propName] === ""
            ) {
                delete obj[propName];
            }
        }
    }

    /**
     * Create a new Question
     */
    _executePost() {
        if (!this._validateRequiredFields()) return;

        var that = this;

        this.setState({
            isSaving: true,
        });

        // clean object to remove null and undefined entries
        var objToPost = JSON.parse(JSON.stringify(this.state.sourceData));
        objToPost["AssessmentId"] = this.props.assessmentId;
        this._cleanBeforePost(objToPost);

        API.post(`AssessmentQuestion`, objToPost, {
            cancelToken: this.source.token,
        })
            .then(that._processSavedQuestion)
            .catch(function (err) {
                if (API.isCancel(err)) {
                    return;
                }

                // TODO: Alert User save was not good
                that.setState({
                    isSaving: false,
                    isLastSaveOk: false,
                    lastSaveError: err.response.data.value
                        ? err.response.data.value
                        : "Error: Unable to save question.",
                });

                that.queue.shift();
                that._processQueue();
            });
    }

    _processSavedQuestion(res) {
        this.setState(
            {
                isSaving: false,
                isLastSaveOk: true,
                lastSaveError: null,
                sourceData: res.data,
            },
            function () {
                this.queue.shift();
                this._processQueue();
            }
        );
    }

    /**
     * Populate Content Level drop down list
     */
    _renderContentLevels() {
        let arr = [];

        arr.push(
            <MenuItem key="clEmpty" value="">
                <em>None</em>
            </MenuItem>
        );

        if (this.state.allContentLevels) {
            for (let idx = 0; idx < this.state.allGAs.length; idx++) {
                var uniqueKey = "clId" + idx.toString();
                arr.push(
                    <MenuItem
                        key={uniqueKey}
                        value={this.state.allContentLevels[idx].ContentLevelId}
                    >
                        {this.state.allGAs[idx].Code + " - " + this.state.allGAs[idx].Name}
                    </MenuItem>
                );
            }
        }

        return arr;
    }

    /**
     * Render the GAs drop down list
     */
    _renderGAs() {
        let arr = [];

        if (this.state.allGAs) {
            for (let idx = 0; idx < this.state.allGAs.length; idx++) {
                var uniqueKey = "gaId" + idx.toString();
                arr.push(
                    <MenuItem key={uniqueKey} value={this.state.allGAs[idx].Id}>
                        {this.state.allGAs[idx].Title}
                    </MenuItem>
                );
            }
        }

        return arr;
    }

    _renderContentLevels() {
        let toReturn = [];

        if (
            !this.state.allContentLevels ||
            this.state.allContentLevels.length === 0
        )
            return toReturn;

        this.state.allContentLevels.map((cl) => {
            toReturn.push(
                <MenuItem key={"cl" + cl.ContentLevelId} value={cl.ContentLevelId}>
                    {cl.Code + " - " + cl.Name}
                </MenuItem>
            );
        });

        return toReturn;
    }

    _renderAlignments() {
        let toReturn = [];

        if (!this.state.allAlignments || this.state.allAlignments.length === 0)
            return toReturn;

        this.state.allAlignments.map((cl) => {
            toReturn.push(
                <MenuItem key={"al" + cl.AlignmentId} value={cl.AlignmentId}>
                    {cl.Number + " - " + cl.Name}
                </MenuItem>
            );
        });

        return toReturn;
    }

    /**
     * Render the GAs drop down list
     */
    _renderGAIs() {
        let arr = [];

        arr.push(
            <MenuItem key="gaiEmpty" value="">
                <em>None</em>
            </MenuItem>
        );

        if (this.state.allGAIs) {
            for (let idx = 0; idx < this.state.allGAIs.length; idx++) {
                var uniqueKey = "gaiId" + idx.toString();
                arr.push(
                    <MenuItem
                        key={uniqueKey}
                        value={this.state.allGAIs[idx].Id}
                        className="GAI-list-item"
                        divider={true}
                    >
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: "0 0 40px" }}>
                                {this.state.allGAIs[idx].Number + " - "}
                            </div>
                            <div>{this.state.allGAIs[idx].Description}</div>
                        </div>
                    </MenuItem>
                );
            }
        }

        return arr;
    }

    _renderStatusBadge = () => {
        if (this.state.isSaving)
            return (
                <span style={{ fontSize: "85%", fontStyle: "italic" }}>saving...</span>
            );

        if (this.state.isLastSaveOk === null)
            return <React.Fragment></React.Fragment>;

        if (this.state.isLastSaveOk === true)
            return (
                <span style={{ fontSize: "85%", fontStyle: "italic" }}>
                    All Changes Saved
                </span>
            );

        return (
            <span>
                <ErrorIcon></ErrorIcon>
                <span style={{ position: "relative", top: "2px" }}>
                    {this.state.lastSaveError}
                </span>
            </span>
        );
    };

    _validateDecimal(e) {
        e.target.value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");
    }

    _validateNumeric(e) {
        var parsedValue = e.target.value.replace(/\D/, "");

        if (e.target.value !== parsedValue) e.target.value = parsedValue;
    }

    /**
     * Get the modal title
     */
    _renderModalTitle() {
        return (
            (this.state.sourceData.AssessmentQuestionId ? "Edit" : "Add") + " GAI"
        );
    }

    /**
     * Catch GA change event
     * @param {event} e Event carrying GA information
     */
    _setGA = (e) => {
        var selectedGA = parseInt(e.target.value);

        for (let idx = 0; idx < this.state.allGAs.length; idx++) {
            if (parseInt(this.state.allGAs[idx].Id) !== selectedGA) continue;

            // clear the currently selected GAIId if the GA changed
            let orgSourceData = this.state.sourceData;
            orgSourceData["GAIId"] = "";

            this.setState({
                selectedGAId: selectedGA,
                sourceData: orgSourceData,
                allGAIs: this.state.allGAs[idx].GAIs,
            });

            break;
        }
    };

    _showGAIInfoDialog(state) {
        this.setState({ showGAIInfoDialog: state });
    }

    _CLOsHaveNotChanged(currentCLOs, NewCLOs) {
        // Check if the arrays are the same length
        if (currentCLOs.length !== NewCLOs.length) return false;

        //check if the content isn't the same
        for (var i = 0; i < currentCLOs.length; i++) {
            if (NewCLOs.indexOf(currentCLOs[i]) == -1) return false;
        }

        return true;
    }

    _renderGAIInfo() {
        return (
            <Grid container style={{ width: "70%", margin: "0 auto" }}>
                <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                    <span style={{ fontWeight: 700 }}>Question Text </span>
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                    <span>
                        {" "}
                        Enter or paste the text from the question attributed to the GAI.
                    </span>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                    <span style={{ fontWeight: 700 }}>Max Mark</span>
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                    <span>
                        Maximum number of marks for this assessment task (question or full
                        task).
                    </span>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                    <span style={{ fontWeight: 700 }}>Level*</span>
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                    <span>
                        Classify the instruction level: <br />
                        I = Introduced <br />
                        D = Developed <br />
                        A = Applied <br />
                    </span>
                </Grid>
                <Grid item xs={12} sm={3} style={{ textAlign: "right" }}>
                    <span style={{ fontWeight: 700 }}>Alignment</span>
                </Grid>
                <Grid item xs={12} sm={9} style={{ paddingLeft: 15 }}>
                    <span>
                        To what degree does this assessment align with GAI
                        <br />
                        1 = Perfectly - aligns to a high degree <br />
                        4 = Somewhat - aligns to a low degree <br />
                    </span>
                </Grid>
                <Grid style={{ marginTop: 10, fontSize: 14 }}>
                    *Programs are asked to classify the instructional level of content
                    relating to one or more graduate attribute in each learning activity
                    (usually a course)
                </Grid>
            </Grid>
        );
    }
}

export default QuestionEditDialog;
