import React from 'react';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import Avatar from '@material-ui/core/Avatar';
import { Redirect } from 'react-router'

import OneStepBack from "../OneStepBack"
import CredentialList from "../Components/CredentialList";
import API from "../Components/api";
import "./../MyProfile.css"

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            courseHistory: [],
            user: {
                fullName: '',
                email: '',
                picture: '',
                aboutMe: ''
            },
            goToCourse: false,
            courseId: ''
        };

        this._goToCourse = this._goToCourse.bind(this);
        this._getUserInfo = this._getUserInfo.bind(this);
    }

    componentDidMount() {
        this._getCourseHistoryData();
        this._getUserInfo();
    }

    render() {
        var instLogo = localStorage.getItem("InstLogo");

        if (this.state.goToCourse && this.state.courseId)
            return (<Redirect to={'/instructor/course-details/' + this.state.courseId} push />);

        return (
            <React.Fragment>
                <OneStepBack/>
                <Paper style={{ width: "80%", margin: "20px auto" }} className="personal-info">
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <div className="profile-pic-container">
                                        <Avatar src={this.state.user.picture} style={{ width: '150px', height: '150px', margin: '0 auto' }} className="profile-pic" />
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <p style={{ lineHeight: .8 }}>{this.state.user.fullName} <br /> <span style={{ fontSize: 10 }}>{this.state.user.email}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12" style={{ textAlign: 'center' }}>
                            <img src={instLogo} style={{ maxWidth: 150, marginBottom: 20, marginTop: 15 }} />
                        </div>
                    </div>
                </Paper>
                <Paper style={{ width: "80%", margin: '20px auto' }}>
                    <div className="row">
                        <div className="col-12 text-center">
                            <CredentialList canEdit={false} instructorId={this.props.match.params.personId}></CredentialList>
                        </div>
                    </div>
                </Paper>
                {this.state.courseHistory.length > 0 ? 
                    (
                        <Paper style={{ width: "80%", margin: '20px auto' }}>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4>Course History</h4>
                                    {this._renderCourseHistory()}
                                </div>
                            </div>
                        </Paper>
                    ): ""
                }
            </React.Fragment>
        )
    }

    _goToCourse(id) {
        this.setState({ goToCourse: true, courseId: id });
    }

    _getCourseHistoryData() {
        API.get(`Instructor/GetAllCoursesById()?instructorId=${this.props.match.params.personId}`)
            .then(res => {
                this.setState({ courseHistory: [...this.state.courseHistory, ...res.data] })
            })
    }

    _getUserInfo() {
        API.get(`Auth/GetProfileById()?personId=${this.props.match.params.personId}`)
            .then(res => {
                this.setState({ user: res.data });
            })
    }

    _renderCourseHistory() {
        if (!this.state.courseHistory || this.state.courseHistory.length > 0) {
            var that = this;
            return <Table >
                <TableHead>
                    <TableRow selected>
                        <TableCell>Course name</TableCell>
                        <TableCell padding="none">Previously Taught</TableCell>
                        <TableCell padding="none">Passing Grade</TableCell>
                        <TableCell padding="none">Students</TableCell>
                        <TableCell padding="none">Assessments Complete</TableCell>
                        <TableCell padding="none">GAIs Mapped</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.courseHistory.map(function (row, idx) {
                        var theKey = "courseHistory" + idx.toString();
                        return (
                            <React.Fragment key={theKey}>
                                <TableRow>
                                    <TableCell onClick={that._goToCourse.bind(that, row.id)} style={{ cursor: 'pointer' }}>{row.courseName}</TableCell>
                                    <TableCell padding="none">{row.previouslyTaught ? 'Yes' : 'No'}</TableCell>
                                    <TableCell padding="none">{row.passingGrade + '%'}</TableCell>
                                    <TableCell padding="none">{row.totalStudents}</TableCell>
                                    <TableCell padding="none">{row.assessmentsComplete + ' of ' + row.totalAssessment}</TableCell>
                                    <TableCell padding="none">{row.gaiMapped}</TableCell>
                                </TableRow>
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        }

        return <div style={{ width: "100%", textAlign: "center" }}>No course history available.</div>;
    }
}

export default UserProfile;