import React from "react";
import { withRouter } from "react-router-dom";
import { Redirect } from "react-router";
import Button from '@material-ui/core/Button';

class ToAdminMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toAdminView: false
        }

        this._toAdminView = this._toAdminView.bind(this);
        this._isUserAdmin = this._isUserAdmin.bind(this);
    }

    render() {
        if (this.state.toAdminView) {
            let newUrl = "/admin";
            return <Redirect from="/terms" to={newUrl} push></Redirect>
        }

        if (this._isUserAdmin())
            return (
                <Button
                    className="embers whiteText top-right-button"
                    onClick={this._toAdminView}
                    size="small">
                    Admin Portal
                </Button>
            );

        return <React.Fragment></React.Fragment>;
    }

    _isUserAdmin() {
        try {
            const roleData = this._getRolesFromToken();

            if (typeof roleData === "string")
                return roleData.indexOf("Admin") > -1;

            return roleData.includes("Admin");
        }
        catch (err) {
            return false;
        }
    }

    _getRolesFromToken() {
        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        if (!authToken) {
            localStorage.removeItem("RefreshToken");

            if (window.location.pathname !== "/")
                window.location = "/";
        }
        else {
            var base64Url = authToken.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            var tokenInfo = JSON.parse(window.atob(base64));
            return tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        }
    }

    _toAdminView = () => {
        this.setState({
            toAdminView: true
        });
    }
}

export default withRouter(ToAdminMenu) 