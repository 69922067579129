import React from "react";
import { v4 as uuidv4 } from "uuid";
import { HelpRounded as InfoIcon } from "@material-ui/icons";
import { SaveAlt as DownloadIcon } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import html2canvas from "html2canvas";

import InfoDialog from "../InfoDialog";

class GAIChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartConfig = [["Group", "Percent", { role: "annotation" }]];

    this.state = {
      isErrorGetData: false,
      chartData: null,
      containerId: uuidv4(),
      showPerformanceInfo: false,
    };

    this._drawChart = this._drawChart.bind(this);
  }

  componentDidMount() {
    this.setState({
      chartData: [...this.chartConfig, ...this.props.data.chartData],
    });

    if (this.props.data.chartData.length === 0) return;

    window.google.charts.load("current", { packages: ["corechart"] });
    // Set a callback to run when the Google Visualization API is loaded.
    window.addEventListener("resize", this._drawChart);
    window.google.charts.setOnLoadCallback(this._drawChart);
  }

  UNSAFE_componentWillReceiveProps({ data }) {
    if (data) {
      var chartData = [...this.chartConfig, ...data.chartData];
      this.setState({ chartData: chartData }, () => {
        this._drawChart();
      });
    }
  }

  _renderPerformanceInfo = () => {
    return (
      <Grid container style={{ width: "85%", margin: "0 auto" }}>
        <Grid item xs={12} sm={9}>
          <span>Below: 0 up to 55%</span>
        </Grid>
        <Grid item xs={12} sm={9}>
          <span>Marginal: 55% up to 65%</span>
        </Grid>
        <Grid item xs={12} sm={9}>
          <span>Meets: 65% up to 80%</span>
        </Grid>
        <Grid item xs={12} sm={9}>
          <span>Exceeds: 80% up to 100%</span>
        </Grid>
      </Grid>
    );
  };

  render() {
    let performanceInfo = <div id="asd" style={{ visibility: "hidden" }}></div>;
    if (this.state.showPerformanceInfo)
      performanceInfo = (
        <InfoDialog
          show={true}
          content={this._renderPerformanceInfo()}
          title="Performance Descriptors"
          onDialogClose={() => {
            this.setState({ showPerformanceInfo: false });
          }}
        ></InfoDialog>
      );

    if (this.props.data.chartData.length === 0)
      return (
        <div style={{ textAlign: "center", paddingTop: "5px" }}>
          <h4>No data to display.</h4>
        </div>
      );

    return (
      <React.Fragment>
        <div className="full-width center spacing-top">
          {this.props.data.title}
          <span
            style={{
              position: "relative",
              right: "10px",
              zIndex: "1",
            }}
          >
            <InfoIcon
              className="help-button"
              onClick={() => {
                this.setState({ showPerformanceInfo: true });
              }}
            />
            <DownloadIcon
              className="download-button"
              onClick={() => {
                this._printChart();
              }}
            />
            {performanceInfo}
          </span>
        </div>

        {this.state.isErrorGetData ? (
          <div>Error: Unable to retrieve data.</div>
        ) : (
          <div
            style={{ width: "100%" }}
            id={"GAI_chart_" + this.state.containerId}
          ></div>
        )}
      </React.Fragment>
    );
  }

  _printChart = () => {
    html2canvas(
      document.getElementById("GAI_chart_" + this.state.containerId)
    ).then((canvas) => {
      var link = document.createElement("a");
      link.download = "gaiChart.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  _drawChart = () => {
    var data = window.google.visualization.arrayToDataTable(
      this.state.chartData
    );

    // Set chart options
    var options = {
      width: "100%",
      height: 270,
      legend: { position: "none" },
      colors: ["#062F4F"],
      titlePosition: "none",
      vAxis: {
        format: "#.#'%'",
        minValue: 0,
        maxValue: 1,
      },
    };

    // Instantiate and draw our chart, passing in some options.
    var chart = new window.google.visualization.ColumnChart(
      document.getElementById("GAI_chart_" + this.state.containerId)
    );
    chart.draw(data, options);
  };
}

export default GAIChart;
