import React from "react";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

import { CLOParser } from "./../../Components/CLOParser";
import API from "../../Components/api";
import "./../../Libellum.css";
import { cancelHttpRequests } from "./../../Helpers/HelperFunctions";

const styles = (theme) => ({
    colorSwitchBase: {
        color: "#B82601",
        "&$colorChecked": {
            color: "#B82601",
            "& + $colorBar": {
                backgroundColor: "#062F4F",
            },
        },
    },
    colorBar: {},
    colorChecked: {},
});

class CourseMasterCLOWeeks extends React.Component {
    cancelToken = API.getCancelactionToken();
    source = this.cancelToken.source();

    constructor(props) {
        super(props);

        this.updateQueue = [];

        this.state = {
            clo: this.props.clo,
            showAsPercent: false,
        };
    }

    componentWillUnmount() {
        cancelHttpRequests(this.source);
    }

    _onShowAsPercentChanged = (event) => {
        let newClos = this.state.clo;

        if (!this.state.showAsPercent) {
            //Convert to percent
            newClos.forEach((obj) => {
                obj.WeeksTaught = (
                    (obj.WeeksTaught / this.props.courseWeeks) *
                    100
                ).toFixed(2);
            });
        } else {
            //Convert to number of weeks taught
            newClos.forEach((obj) => {
                obj.WeeksTaught = (
                    (obj.WeeksTaught * this.props.courseWeeks) /
                    100
                ).toFixed(2);
            });
        }

        this.setState({
            showAsPercent: !this.state.showAsPercent,
            clo: [...newClos],
        });
    };

    render() {
        if (!this.state.clo || this.state.clo.length === 0) {
            return (
                <div style={{ marginTop: "10px", marginLeft: "0", marginRight: "0" }}>
                    Cannot assign weeks taught to Course Learning Outcomes because no
                    Course Learning Outcomes have been assigned to this Course Plan.
                </div>
            );
        }

        let dialogToRender = null;

        if (this.state.showModal) {
            dialogToRender = (
                <Dialog
                    open={true}
                    maxWidth={false}
                    aria-labelledby="confirmation-dialog-title"
                >
                    <DialogTitle id="confirmation-dialog-title">
                        {this.state.modalTitle}
                    </DialogTitle>
                    <DialogContent>
                        {this.state.modalText}
                        <div style={{ textAlign: "right" }}>
                            <Button
                                className="cancel ink"
                                style={{ marginTop: 10 }}
                                onClick={this._closeCLODialog}
                                color="primary"
                            >
                                Close
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }

        return (
            <React.Fragment>
                <div className="col-12 no-padding">
                    <div className="row no-margin">{this._renderCloDistribution()}</div>
                </div>
                {dialogToRender}
            </React.Fragment>
        );
    }

    _closeCLODialog = (evt) => {
        this.setState({
            showModal: false,
            modalTitle: null,
            modalText: null,
        });
    };

    _cloInfo = (obj) => {
        API.get(`CourseLearningOutcome(${obj.CourseLearningOutcomeId})`, {
            cancelToken: this.source.token,
        })
            .then((res) => {
                if (res.status === 200) {
                    this.setState({
                        showModal: true,
                        modalTitle:
                            "Course Learning Outcome " +
                            obj.CourseLearningOutcomeNumber +
                            " Description",
                        modalText: CLOParser(res.data.CourseLearningOutcomeDescription),
                    });
                } else if (res.status === 204) {
                    this.setState({
                        showModal: true,
                        modalTitle: "Course Learning Outcome Description",
                        modalText:
                            "Unable to retrieve Course Learning Outcome description.",
                    });
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;

                this.setState({
                    showModal: true,
                    modalTitle: "Course Learning Outcome Description",
                    modalText:
                        "Error: Unable to retrieve Course Learning Outcome description.",
                });
            });
    };

    _renderCloDistribution = () => {
        let cloWeeksTaught = this._renderCloWeeksTaught();
        let leftColSpan,
            rightColSpan = ["", ""];
        let numCols = this.state.clo.length + 1;

        if (numCols < 13) {
            leftColSpan = "col-" + Math.ceil(numCols / 2);
            rightColSpan = "col-" + Math.floor(numCols / 2);
        } else {
            leftColSpan = "col-6";
            rightColSpan = "col-6";
        }

        let weeksTotal = this.state.clo
            .reduce((a, b) => {
                return parseFloat(a) + parseFloat(b.WeeksTaught);
            }, 0)
            .toFixed(2);
        let showWeeksError = false;
        if (
            (this.state.showAsPercent && weeksTotal > 100) ||
            !this.state.showAsPercent && weeksTotal > this.props.courseWeeks
        )
            showWeeksError = true;

        let weekSelectedClass,
            percentSelectedClass = ["", ""];

        if (this.state.showAsPercent) {
            percentSelectedClass = "oxBloodText";
            weekSelectedClass = "riverBed";
        } else {
            percentSelectedClass = "riverBed";
            weekSelectedClass = "oxBloodText";
        }

        const { classes } = this.props;
        return (
            <div className="col-12">
                <div className="row">
                    <div className={leftColSpan} style={{ minWidth: 350 }}>
                        <div className=" row no-margin no-padding">
                            <div className="col-12 bold font23 riverBed poppinFont inlineBlock row no-margin no-padding">
                                <span className="inlineBlock">
                                    CLO Distribution
                                    <hr
                                        className="hrTitle"
                                        style={{ width: "75%", marginTop: 15 }}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="font14 poppinFont">
                            {this.state.showAsPercent
                                ? "Enter % of course taught per CLO."
                                : "Enter weeks taught per CLO."}
                        </div>
                    </div>
                    <div
                        className={rightColSpan}
                        style={{ float: "right", minWidth: 350 }}
                    >
                        <span style={{ float: "right" }}>
                            <div
                                className="font12 poppinFont"
                                style={{ textAlign: "center" }}
                            >
                                Distribute Using:
                            </div>
                            <span
                                className={"font14 poppinFont " + weekSelectedClass}
                                style={{
                                    width: "45px",
                                    display: "inline-block",
                                    textAlign: "left",
                                }}
                            >
                                Weeks
                            </span>
                            <Switch
                                ref="showAsPercentRef"
                                onChange={this._onShowAsPercentChanged}
                                checked={this.state.showAsPercent}
                                value={this.state.showAsPercent}
                                classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                    bar: classes.colorBar,
                                }}
                            />
                            <span
                                className={"font14 poppinFont " + percentSelectedClass}
                                style={{ width: "80px", display: "inline-block" }}
                            >
                                Percentage
                            </span>
                        </span>
                    </div>
                </div>
                <div className="row no-margin no-padding">{cloWeeksTaught}</div>
                <div>
                    {showWeeksError ? (
                        <div className="ehs-error">
                            {this.state.showAsPercent
                                ? "Weeks taught should be less than or equal to 100%"
                                : "Weeks taught should be less than or equal to " +
                                this.props.courseWeeks}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    };

    _renderCloWeeksTaught = () => {
        var toReturn = [];

        this.state.clo.map((obj, idx) => {
            toReturn.push(
                <React.Fragment key={"cloSum" + idx.toString()}>
                    <div className="col-xs-2 col-sm-2 col-lg-1 no-margin no-padding">
                        <div
                            key={"clo" + idx}
                            className="col no-padding no-margin center thin-border smallVerticalSpacing poppinFont font14 semibold"
                            onClick={() => {
                                this._cloInfo(obj);
                            }}
                        >
                            <span style={{ cursor: "pointer" }}>
                                CLO {obj.CourseLearningOutcomeNumber}
                            </span>
                        </div>
                        <div
                            key={"clov" + idx}
                            className="col no-padding no-margin center thin-border smallVerticalSpacing span-to-center"
                        >
                            <TextField
                                className="no-padding-input"
                                style={{ width: "65px", margin: "0" }}
                                value={obj.WeeksTaught}
                                margin="normal"
                                onBlur={(e) => {
                                    this._addToChangeQueue(e, obj, "WeeksTaught");
                                }}
                                onChange={(e) => {
                                    this._updateWeeksTaught(e, obj, "WeeksTaught");
                                }}
                                type="number"
                                inputProps={{ min: "0", max: "100", step: "0.25" }}
                            />
                            {this.state.showAsPercent ? "%" : ""}
                        </div>
                    </div>
                </React.Fragment>
            );
        });

        let totalValue = this.state.clo
            .reduce((a, b) => {
                return parseFloat(a) + parseFloat(b.WeeksTaught);
            }, 0)
            .toFixed(2);
        toReturn.push(
            <React.Fragment key={"cloSum" + toReturn.length.toString()}>
                <div
                    className="col-xs-2 col-sm-2 col-lg-1 no-margin no-padding"
                    style={{ minWidth: 120 }}
                >
                    <div
                        key={"clo" + toReturn.length.toString()}
                        className="col no-padding no-margin center thin-border smallVerticalSpacing font20 bold"
                    >
                        <span style={{ cursor: "pointer" }}>Total</span>
                    </div>
                    <div
                        key={"clov" + toReturn.length.toString()}
                        className="col no-padding no-margin center thin-border smallVerticalSpacing span-to-center"
                    >
                        <span className="poppinFont riverBed font20 semibold">
                            {totalValue}
                        </span>
                        <span className="poppinFont riverBed font10">
                            {this.state.showAsPercent ? " %" : " /Weeks"}
                        </span>
                    </div>
                </div>
            </React.Fragment>
        );

        return toReturn;
    };

    _addToChangeQueue = (e, fullObj, paramName) => {
        // get values
        var newVal = parseFloat(e.target.value);
        var oldVal = parseFloat(fullObj.oldWeeksTaught);

        // if they haven't changed, don't do anything
        if (newVal === oldVal) return;

        this.updateQueue.push({
            courseLearningOutcomeId: fullObj.CourseLearningOutcomeId,
            newValue: newVal,
            oldValue: oldVal,
            sender: e.target,
            paramName: paramName,
        });

        this._processChangeQueue();
    };

    _processChangeQueue = () => {
        var that = this;
        if (this.updateQueue.length === 0) return;

        var objToProcess = this.updateQueue[0];

        var weeksTaught = objToProcess.newValue;

        //Convert back to number of weeks to send to server if displayed as percent
        if (this.state.showAsPercent) {
            weeksTaught = (weeksTaught * this.props.courseWeeks) / 100;
        }

        API.patch(`CoursePlanCourseLearningOutcomes(CourseLearningOutcomeId=${objToProcess.courseLearningOutcomeId},CoursePlanId=${that.props.coursePlanId})`,
            {
                [objToProcess.paramName]: weeksTaught,
            },
            {
                cancelToken: that.source.token,
            }
        )
            .then((res) => {
                if (res.status === 200) {
                    that._updateState(objToProcess.courseLearningOutcomeId, weeksTaught);
                } else {
                    alert("Unable to update AU Distribution.");
                    that.updateQueue[0].sender.value = that.updateQueue[0].oldValue;
                }
            })
            .catch((err) => {
                if (API.isCancel(err)) return;
                // TODO: Something
                alert("Error: Unable to update AU Distribution.");
                that.updateQueue[0].sender.value = that.updateQueue[0].oldValue;
            })
            .finally(() => {
                that.updateQueue.shift();
                that._processChangeQueue();
            });
    };

    _updateState = (courseLearningOutcomeId, newValue) => {
        for (let idx in this.state.clo) {
            // find the id that we are working with
            if (
                this.state.clo[idx].CourseLearningOutcomeId === courseLearningOutcomeId
            ) {
                // make a new object
                let newSourceData = Object.assign({}, this.state.clo);
                // update
                if (!this.state.showAsPercent)
                    newSourceData[idx].WeeksTaught = newValue;
                else
                    newSourceData[idx].WeeksTaught = (
                        (newValue / this.props.courseWeeks) *
                        100
                    ).toFixed(2);

                newSourceData[idx].oldWeeksTaught = null;
                // update state
                this.setState((prevState) => ({
                    ...prevState.clo,
                    [this.state.clo]: newSourceData,
                }));

                break;
            }
        }
    };

    _updateWeeksTaught(e, fullObj) {
        e.target.value = e.target.value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*)\./g, "$1");

        if (!e.target.value) e.target.value = 0;

        let clos = this.state.clo;
        let cloToUpdate = clos.filter(
            (x) => x.CourseLearningOutcomeId === fullObj.CourseLearningOutcomeId
        )[0];

        var oldVal = parseFloat(cloToUpdate.WeeksTaught);
        var newVal = parseFloat(e.target.value);
        cloToUpdate.WeeksTaught = newVal;

        cloToUpdate.oldWeeksTaught = oldVal;

        this.setState({
            clo: [...clos],
        });
    }
}

export default withStyles(styles)(CourseMasterCLOWeeks);
