import React from "react";
import TextField from "@material-ui/core/TextField";

class SearchByCourse extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <form onSubmit={this._searchCourse}>
        <TextField
          name="courseToSearch"
          label="Search by course"
          className="medium-font reports-search-bar"
        />
      </form>
    );
  }

  //TODO: Validate search tearm and show user messages when needed.
  _searchCourse = (e) => {
    e.preventDefault();
    let searchValue = e.target[0].value;
    let newReportTable = [...this.props.reportData];
    let newHighlightedRow;

    newReportTable = this._clearAllHighlightedCellsFromTable(
      [...newReportTable],
      this.props.highlightedRow,
      searchValue
    );

    if (searchValue === "") {
      this.props.onSearchExecuted(newReportTable);
      return;
    }

    [newReportTable, newHighlightedRow] = this._searchOnCourseList(newReportTable, searchValue);

    //If the report has top courses search there too.
    if (this.props.hasTopCourses)
      newReportTable = this._searchOnTopCourse(
        [...newReportTable],
        searchValue
      );

    this.props.onSearchExecuted(newReportTable, newHighlightedRow);
  }

  _searchOnTopCourse(reportTable, value) {
    for (
      var i = reportTable.length - this.props.numberOfTopCourses,
        max = reportTable.length;
      i < max;
      i++
    ) {
      for (let x of reportTable[i]) {
          if (x.title !== null && x.title.toLowerCase() === value.toLowerCase()) x.cssClass += " highlight-search top-course";
      }
    }

    return reportTable;
  }

  _clearTopCoursesSearch(reportTable) {
    for (
      var i = reportTable.length - this.props.numberOfTopCourses,
        max = reportTable.length;
      i < max;
      i++
    ) {
      for (let x of reportTable[i]) {
        if (x.hasOwnProperty("cssClass") && x.cssClass !== null)
          x.cssClass = x.cssClass.replace("highlight-search", "");
      }
    }

    return reportTable;
  }

  _clearAllHighlightedCellsFromTable(reportTable, selectedRow) {
    //if there's a row highlighted clear just that one
    if (selectedRow !== null && selectedRow !== undefined) {
      reportTable[selectedRow] = reportTable[selectedRow].map(x => {
        if (x.hasOwnProperty("cssClass") && x.cssClass !== null)
          x.cssClass = x.cssClass.replace("highlight-search", "");

        return x;
      });
    }

    //clear the top courses section and return
    return this._clearTopCoursesSearch([...reportTable]);
  }

  _searchOnCourseList(newReportTable, value) {
    let highlightRow = false;
    let rowClass = " highlight-search row";
    let newHighlightedRow;

    for (var i = 0, max = newReportTable.length; i < max; i++) {
      for (let x of newReportTable[i]) {
        //ignore year and term columns.
        if (x.hasOwnProperty("RowSpan")) continue;

        if (highlightRow) {
          x.cssClass += rowClass;
        } else if (x.title !== null && x.title.toLowerCase() === value.toLowerCase() && !highlightRow) {
          newHighlightedRow = i;
          x.cssClass += rowClass;
          highlightRow = true;
        }
      }

      if (highlightRow) break;
    }

    return [newReportTable, newHighlightedRow];
  }
}

export default SearchByCourse;