import React from "react";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import AssessmentGrid from "./AssessmentGrid"

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
});

class AssessmentInfo extends React.Component {
    render() {
        return (
            <div className={styles.root}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Paper className={styles.paper}>
                            <AssessmentGrid
                                courseDetailId={this.props.courseDetailId}
                                assessments={this.props.assessments}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>)
    }
}

export default AssessmentInfo;