import React from 'react';
import { Paper} from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import API from "./Components/api";
import { withRouter, Link } from "react-router-dom";
import queryString from 'query-string';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200
      },
      dense: {
        marginTop: 19
      }
  });
  
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search)
        this.state = {
            email: params['email'],
            resetToken: params['resetToken'],
            newPassword: '',
            confirmPassword: '',
            passwordResetSuccess: false,
            passwordResetError: '',
            formSubmitted: false
        }

        this._handleSubmit = this._handleSubmit.bind(this);
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.newPassword)
                return false;
            return true;
        });
        ValidatorForm.addValidationRule('complexity', (value) => {
            if (value.trim() === '')
                return true;
            return value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,}$/);
        });
    }
    
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper style={{ width: "80%", margin: "20px auto", textAlign: "center"}}>
                    <div style={{ paddingTop: 15 }}>
                        <h4>Reset Password</h4>
                    </div>
                    <ValidatorForm onSubmit={this._handleSubmit}>
                        <div>
                            <TextValidator
                                name="newPassword"
                                label="New password"
                                className={classes.textField}
                                type="password"
                                validators={['required', 'complexity']}
                                errorMessages={['this field is required', 'The password minimum length is 6 characters. It also needs a number, uppercase and lowercase character.']}
                                value={this.state.newPassword}
                                onChange={this._handleChange('newPassword')}
                            />

                            <TextValidator
                                name="confirmPassword"
                                label="Confirm new password"
                                className={classes.textField}
                                type="password"
                                validators={['required', 'isPasswordMatch']}
                                errorMessages={['this field is required', 'Passwords do not match']}
                                value={this.state.confirmPassword}
                                onChange={this._handleChange('confirmPassword')}
                            />  
                        </div>
                        <div>
                            <Button className="ehs-add-button" style={{ marginTop: 10, marginBottom: 20 }} type="Submit">
                                <span className="nowrap white-text">Submit</span>
                            </Button>
                            {this.state.passwordResetSuccess && <div><h5 >Your password has been reset! Click <Link to="/">Here</Link>  to log in.</h5></div>}
                            {this.state.passwordResetError && <h5>{this.state.passwordResetError}</h5>}
                        </div>
                    </ValidatorForm>
                </Paper>
            </React.Fragment>
        )
    }

    _handleChange = name => event => {
        this.setState({
            [name]: event.target.value
          });
    }

    _handleSubmit() {
        if (this.state.formSubmitted)
            return;
        
        this.setState({ formSubmitted: true });

        var that = this;
        var postObject = {
            "Email": this.state.email,
            "ResetToken": encodeURIComponent(this.state.resetToken),
            "NewPassword": this.state.newPassword
        };

        API.post(`Token/ConfirmResetPassword`, { ResetPassword: postObject })
            .then(function (res) {
                that.setState({
                    passwordResetSuccess: true,
                    passwordResetError: ''
                });
            }).catch(function (err) {
                that.setState({
                    passwordResetSuccess: false,
                    passwordResetError: err.response.data.value
                });
            });
    };
}

export default withRouter(withStyles(styles)(ResetPassword));